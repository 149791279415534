import { Navigate } from "react-router-dom";

export const CustomerPrivateRoute = ({ children }) => {
  if (localStorage.getItem("customerToken") !== null) {
    return children;
  } else {
    return <Navigate to={"/customer/login"} />;
  }
};

export const EditorPrivateRoute = ({ children }) => {
  if (localStorage.getItem("editorToken") !== null) {
    return children;
  } else {
    return <Navigate to={"/editor/login"} />;
  }
};

export const AdminPrivateRoute = ({ children }) => {
  if (localStorage.getItem("adminToken") !== null) {
    return children;
  } else {
    return <Navigate to={"/admin/login"} />;
  }
};
