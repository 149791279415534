import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import { FaTrash } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { setAdminSidebar } from "../../../redux/reducer/sidebarSlice";

const AdminList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAdminSidebar("admin-list"));
  }, []);
  const [data, setData] = useState([
    {
      firstName: "daniel",
      lastName: "barker",
    },
  ]);
  const [modal, setModal] = useState(false);
  const handleModal = (index) => {
    setModal(index === modal ? null : index);
  };
  const [deletedId, setDeletedId] = useState(null);

  return (
    <Layout>
      {" "}
      <div className="w-full flex flex-col items-center gap-[20px] p-[20px]">
        <h1 className="font-extrabold text-[36px] leading-[1.3em] text-center">
          Admin List*
        </h1>
        <div className="w-full overflow-x-auto hide-scrollbar  bg-white   h-[400px]   ">
          <table className="w-full">
            <thead>
              <tr className="border text-[12px] border-gray-200 rounded-t-[20px] bg-[#fcfdfd]">
                <th className="sm:p-[12px] px-[30px] text-center py-[12px]  sm:text-left">
                  Email
                </th>

                <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody className="w-full border border-gray-200  ">
              {data.map((item, index) => (
                <tr
                  key={index}
                  className={`
    ${
      index % 2 === 1 ? "bg-[#fcfdfd]  text-[12px]" : "bg-white text-[12px]"
    } relative
     `}
                >
                  <td className="p-[12px] text-left">
                    {item?.firstName} {item?.lastName}
                  </td>
                  <td className="p-[12px] text-left flex  gap-[7px] items-center">
                    <FaTrash
                      onClick={() => {
                        handleModal("deleteItem");
                        setDeletedId(item?.id);
                      }}
                      className="cursor-pointer"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {modal === "deleteItem" ? (
        <div className="w-full absolute top-0 z-[99] left-0 bg-black bg-opacity-[.5] h-full p-[20px] sm:p-[30px] flex flex-col justify-center items-center">
          <div className="w-full sm:w-[80%] xl:w-[50%] h-[300px] p-[20px] sm:p-[25px] rounded-[12px] bg-white flex flex-col gap-[15px] justify-center items-center">
            <h3 className="text-[20px] text-center font-semibold ">
              Are you sure, You want to delete this User?
            </h3>
            <div className="w-full flex gap-[12px] justify-center items-center">
              <button
                onClick={() => {
                  setModal(false);
                }}
                className="px-[20px] py-[10px] rounded-[7px] font-semibold bg-[#e8e8e8]"
              >
                No
              </button>
              <button
                onClick={() => {
                  //   handleDelete();
                }}
                className="px-[20px] py-[10px] text-white rounded-[7px] font-semibold bg-red-500 "
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </Layout>
  );
};

export default AdminList;
