import React, { useState } from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import { ToastContainer } from "react-toastify";

const Layout = ({ children }) => {
  return (
    <div className="w-full flex  relative">
      <ToastContainer />
      <div className="w-[17%] hidden xl:block border-r border-gray-200">
        <Sidebar />
      </div>
      <div className="w-full xl:w-[83%]   px-[10px] h-screen overflow-y-scroll hide-scrollbar  flex flex-col  ">
        <Header />

        <div className="bg-[#b2b7bb] py-[20px] px-[15px] w-full h-full rounded-[20px]">
          <div className="w-full  h-full overflow-y-scroll bg-white rounded-[20px] hide-scrollbar">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
