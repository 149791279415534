import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaTrash, FaX } from "react-icons/fa6";
import Img from "../../../assets/customer/upload.png";
import Layout from "../layout/layout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  pushUploadAudio,
  setUploadAudio,
  updateAudioComment,
} from "../../../redux/reducer/newOrderAdminSlice";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../utils/spinner";
import moment from "moment";
const MusicUpload = ({
  audios,
  setAudios,
  tabs,
  setTabs,
  orderId,
  changesOccur,
  setChangesOccur,
}) => {
  const dispatch = useDispatch();
  const [showItemIndex, setShowItemIndex] = useState(false);
  const handleShowItemIndex = (index) => {
    setShowItemIndex(index === showItemIndex ? null : index);
  };
  const [fileDragging, setFileDragging] = useState(null);
  const [fileDropping, setFileDropping] = useState(null);
  const uploadAudio = useSelector(
    (state) => state.newOrderAdminSlice.uploadAudio
  );
  const [isFileUploading, setIsFileUploading] = useState(false);
  const humanFileSize = (size) => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  };

  const removeFile = (index) => {
    const newFiles = [...uploadAudio];
    newFiles.splice(index, 1);
    dispatch(setUploadAudio(newFiles));
    setChangesOccur(true);
  };

  const handleDragEnter = (e, index) => {
    setFileDropping(index);
    setChangesOccur(true);
  };

  const handleDragStart = (e, index) => {
    setFileDragging(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (fileDragging !== null && fileDropping !== null) {
      const newFiles = [...uploadAudio];
      const draggedFile = newFiles[fileDragging];
      newFiles.splice(fileDragging, 1);
      newFiles.splice(fileDropping, 0, draggedFile);
      dispatch(setUploadAudio(newFiles));
      setFileDragging(null);
      setFileDropping(null);
    } else {
      const newFiles = Array.from(e.dataTransfer.files);
      const allowedFiles = newFiles.filter(
        (file) =>
          file.type === "audio/mp3" ||
          file.type === "audio/wav" ||
          file.type === "audio/flac"
      );

      const combinedFiles = [
        ...uploadAudio,
        ...allowedFiles.map((file) => ({
          url: loadFile(file),
          originalFile: file,
          comment: "",
        })),
      ];
      combinedFiles.forEach((fileObj) => {
        uploadFormData.append(`files`, fileObj.originalFile);
      });
      handleSubmit();

      setChangesOccur(true);
    }
  };

  const loadFile = (file) => {
    const blobUrl = URL.createObjectURL(file);
    return blobUrl;
  };

  const orderCreatedDate = useSelector(
    (state) => state.newOrderAdminSlice.orderDetails.orderCreationDate
  );
  const orderCreatedyear = moment(orderCreatedDate).year();
  const uploadFormData = new FormData();
  uploadFormData.append(
    "folderPath",
    `${moment(orderCreatedDate).year()}/${moment(orderCreatedDate).format(
      "MMMM"
    )}/${orderId}/assets`
  );
  const addFiles = (e) => {
    console.log(e.target.files);
    const newFiles = Array.from(e.target.files);
    const allowedFiles = newFiles.filter(
      (file) =>
        file.type === "audio/mp3" ||
        file.type === "audio/wav" ||
        file.type === "audio/flac" ||
        file.type === "audio/mpeg"
    );

    const combinedFiles = [
      ...uploadAudio,
      ...allowedFiles.map((file) => ({
        url: loadFile(file),
        originalFile: file,
        comment: "",
      })),
    ];
    console.log(combinedFiles);
    combinedFiles.forEach((fileObj) => {
      console.log(fileObj);
      uploadFormData.append(`files`, fileObj.originalFile);
    });
    handleSubmit();
  };

  const handleCommentChange = (index, newComment) => {
    dispatch(updateAudioComment({ index, comment: newComment }));
    setChangesOccur(true);
  };

  const formData = new FormData();
  uploadAudio.forEach((fileObj) => {
    formData.append(`files`, fileObj.originalFile);
  });
  const token = localStorage.getItem("adminToken");
  const [urls, setUrls] = useState([]);
  const handleSubmit = () => {
    setIsFileUploading(true);
    axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/spaces/upload",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: uploadFormData,
    })
      .then((res) => {
        const formattedArray = res?.data?.urls?.map((item, index) => {
          const cleanUrl = item.replace(/\s+/g, "/");
          return {
            url: cleanUrl,
            comment: "",
          };
        });

        dispatch(pushUploadAudio(formattedArray));
        setIsFileUploading(false);
        setChangesOccur(true);
      })
      .catch((err) => {
        setIsFileUploading(false);
        setChangesOccur(false);
      });
  };

  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col items-center gap-[20px]">
      <div className="w-full flex flex-col items-center gap-[20px]">
        <div className="w-full flex justify-between items-center">
          <button
            onClick={() => setTabs(false)}
            className="px-[20px] py-[12px] rounded-[7px] bg-[#22d87c] text-white"
          >
            Done
          </button>
          <button onClick={() => setTabs(false)}>
            <FaX />
          </button>
        </div>
        <div className="flex flex-col gap-[7px]">
          <div className="flex justify-center items-center gap-[20px]">
            <h1 className="font-extrabold text-[25px] text-center">
              Audio Upload*
            </h1>
          </div>
          {/* <p className="text-center">
            Would you like to be contacted when the order is complete?
          </p> */}
        </div>
        <div className="w-full rounded mx-auto">
          <div className="relative flex flex-col  text-gray-400 rounded">
            <div className="relative flex flex-col text-gray-400 rounded">
              <div
                className="relative h-[400px] border-dashed flex flex-col justify-center text-gray-400 border border-[#22d87c] rounded cursor-pointer"
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  accept="audio/mp3, audio/wav, audio/flac"
                  multiple
                  className="absolute inset-0 z-50 w-full h-full p-0 m-0 outline-none opacity-0 cursor-pointer"
                  onChange={addFiles}
                />
                <div className="flex flex-col items-center justify-center py-10 text-center">
                  <img
                    loading="lazy"
                    src={Img}
                    alt="img"
                    className="w-[300px]"
                  />
                  <h3 className="text-center text-black font-bold text-[20px]">
                    Drop Files Here
                    <br />
                    Or
                  </h3>
                  <button className="px-[20px] py-[7px] bg-[#414143] text-white rounded-[7px]">
                    Upload here
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center items-center flex-wrap gap-[20px]">
              {uploadAudio.map((file, index) => (
                <div
                  key={index}
                  className="w-full sm:w-[45%] relative flex flex-col items-center overflow-hidden text-center bg-gray-100 border rounded cursor-move bg-musicBg bg-no-repeat bg-cover bg-center select-none h-[400px] justify-end"
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragEnter={(e) => handleDragEnter(e, index)}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={handleDrop}
                  onMouseEnter={() => handleShowItemIndex(index)}
                  onMouseLeave={() => handleShowItemIndex(index)}
                  data-index={index}
                >
                  {showItemIndex === index ? (
                    <div
                      className="w-full h-full absolute z-[3] top-0 left-0 p-[30px] bg-black bg-opacity-[.5]
                      flex justify-start  items-start"
                    >
                      <div className="w-[50px] h-[50px] bg-white rounded-[50%] flex flex-col items-center justify-center">
                        <p className="text-black font-semibold">{index + 1}</p>
                      </div>
                    </div>
                  ) : null}
                  <button
                    className="absolute top-0 right-0 z-50 p-1 bg-white rounded-bl focus:outline-none"
                    type="button"
                    onClick={() => removeFile(index)}
                  >
                    <FaTrash />
                  </button>

                  <audio
                    className="z-[48] w-full h-full border-4 border-white "
                    src={file.url}
                    alt={`Preview ${index}`}
                    controls
                  />
                  <div className="flex flex-col w-full gap-[7px] text-[14px] bg-[#f5f5f5] z-[50] p-[7px] relative">
                    <p>
                      {file?.url?.split("/")[file?.url?.split("/").length - 1]}
                    </p>
                    <textarea
                      value={file?.comment}
                      onChange={(e) =>
                        handleCommentChange(index, e.target.value)
                      }
                      placeholder="Add your comment here"
                      className="w-full p-2 border rounded"
                    />
                  </div>

                  <div
                    className="absolute inset-0 z-40 transition-colors duration-300"
                    style={{
                      backgroundColor:
                        fileDropping == index && fileDragging != index
                          ? "rgba(173, 216, 230, 0.5)"
                          : null,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center mt-[30px] items-center gap-[20px]"></div>
      {isFileUploading === true ? <Spinner /> : null}
    </div>
  );
};

export default MusicUpload;
