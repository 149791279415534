import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    files: [],
};

const musicUploadSlice = createSlice({
    name: "musicUploadSlice",
    initialState,
    reducers: {
        setFiles: (state, action) => {
            state.files = action.payload;
        },
        updateFileComment: (state, action) => {
            const { index, comment } = action.payload;
            if (state.files[index]) {
                state.files[index].comment = comment;
            }
        },
    },
});

export const { setFiles, updateFileComment } = musicUploadSlice.actions;
export default musicUploadSlice.reducer;
