import React, { useEffect, useRef, useState } from "react";
import Img from "../../../assets/customer/new-order-page.png";
import { FaArrowLeft, FaX } from "react-icons/fa6";

import video1 from "../../../assets/customer/video1.png";
import video2 from "../../../assets/customer/video2.png";
import video3 from "../../../assets/customer/video3.png";
import musicImg from "../../../assets/music.png";
import { useNavigate } from "react-router-dom";
import Step3 from "./imageUpload";
import VideoUpload from "../dashboard/videoUpload";
import { useDispatch, useSelector } from "react-redux";
import { setPricing } from "../../../redux/reducer/pricingSlice";
import {
  setImg,
  setPlayingMusic,
  setQuotes,
  setSelectedImg,
  setSelectedMusic,
  setSelectedVideo,
  setVideo,
  updateImgNote,
  updateMusicNote,
  updateVideoNote,
} from "../../../redux/reducer/newOrderAdminSlice";
import axios from "axios";
import { showToastError } from "../../../utils/toastify";
import { FaSearch } from "react-icons/fa";
import MusicUpload from "../dashboard/MusicUpload";
const Step2 = ({
  musics,
  handleSelectedMusic,
  selectedMusic,
  img,
  handleSelectedImg,
  orderId,
  changesOccur,
  setChangesOccur,
  getOrderData,
  specialNotes,
  handleSpecialNotesChange,
}) => {
  const audioRefs = useRef([]);
  const playingMusic = useSelector(
    (state) => state.newOrderAdminSlice.playingMusic
  );
  useEffect(() => {
    audioRefs.current.forEach((ref, index) => {
      if (ref && index === playingMusic) {
        ref.play();
      } else if (ref) {
        ref.pause();
      }
    });
  }, [playingMusic]);

  const handleImgNotes = (e, index) => {
    // const selectedImgData = selectedImg.find((imgData) => imgData.id === index);
    const { value } = e.target;

    // if (!selectedImgData) {
    //   return showToastError("First select the item you want to give the note");
    // }
    dispatch(updateImgNote({ id: index, notes: value }));
    setChangesOccur(true);
  };

  const handleVideoNotes = (e, index) => {
    const { value } = e.target;
    const selectedVideoData = selectedVideo.find(
      (video) => video?.id === index
    );
    if (!selectedVideoData) {
      return showToastError("First select the item you want to give the note");
    }
    dispatch(updateVideoNote({ id: index, notes: value }));
    setChangesOccur(true);
  };
  const handleMusicNotes = (e, index) => {
    const { value } = e.target;
    dispatch(
      updateMusicNote({
        id: index,
        notes: value,
      })
    );
    setChangesOccur(true);
  };

  const navigate = useNavigate();
  const [tabs, setTabs] = useState(false);
  const handleTabs = (index) => {
    setTabs(index === tabs ? null : index);
  };

  // const [videos, setVideos] = useState(data?.scenery);
  const videos = useSelector((state) => state.newOrderAdminSlice.videos);

  const selectedVideo = useSelector(
    (state) => state.newOrderAdminSlice.selectedVideo
  );

  const selectedImg = useSelector(
    (state) => state.newOrderAdminSlice.selectedImg
  );
  // const stringData = JSON.parse(JSON.stringify(selectedImg));
  // const mappedData = stringData?.map((item) => {
  //   return JSON.parse(item);
  // });
  const token = localStorage.getItem("customerToken");

  const dispatch = useDispatch();
  const handleSelectedVideo = (e) => {
    const value = Number(e.target.value);
    dispatch((dispatch, getState) => {
      const prevSelectedVideo = getState().newOrderAdminSlice.selectedVideo;
      const isSelected = prevSelectedVideo.some(
        (videoData) => videoData?.id === value
      );

      if (isSelected) {
        dispatch(
          setSelectedVideo(
            prevSelectedVideo.filter((video) => video.id !== value)
          )
        );
      } else {
        dispatch(
          setSelectedVideo([...prevSelectedVideo, { id: value, notes: "" }])
        );
      }
      setChangesOccur(true);
    });
  };

  const [dropBoxLink, setDropBoxLink] = useState([]);
  const [isDataSent, setIsDataSent] = useState(false);

  const handlePutImg = () => {
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/admin/update-order-photos",
      // headers: {
      //   Authorization: `Bearer sl.B4uWyNU-bXSj_BSoNqbu49hZdLpzZZ4e_aan4zqts9OYL6tCyeHEbOGokjMPv82e3_zCe4Ibc1pVUmPiw4bgJzft1jtiTm9vbjeEEraoNnKZYbOtXiM8LPc6VOYrsoUYNUYXP7Bn0UozzJM`,
      // },
      data: {
        photoObj: selectedImg,
        orderId: orderId,
      },
    })
      .then((res) => {})
      .catch((err) => {});
  };

  const handlePutVideo = () => {
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/admin/update-order-videos",
      // headers: {
      //   Authorization: `Bearer sl.B4uWyNU-bXSj_BSoNqbu49hZdLpzZZ4e_aan4zqts9OYL6tCyeHEbOGokjMPv82e3_zCe4Ibc1pVUmPiw4bgJzft1jtiTm9vbjeEEraoNnKZYbOtXiM8LPc6VOYrsoUYNUYXP7Bn0UozzJM`,
      // },
      data: {
        music: selectedVideo,
      },
    })
      .then((res) => {})
      .catch((err) => {});
  };
  const handlePutMusic = () => {
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/update-order",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        music: selectedMusic,
        id: orderId,
      },
    })
      .then((res) => {})
      .catch((err) => {});
  };
  const handleOrderPhotos = () => {
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/update-order-photos",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        photoObj: dropBoxLink,
        orderId: orderId,
      },
    })
      .then((res) => {})
      .catch((err) => {});
  };

  useEffect(() => {
    if (isDataSent === true) {
      return handleOrderPhotos();
    }
  }, [isDataSent]);
  const [hoveredVideo, setHoveredVideo] = useState(null);
  const videoRefs = useRef([]);

  const handleMouseEnter = (index) => {
    setHoveredVideo(index);
    videoRefs.current[index].play();
  };

  const handleMouseLeave = (index) => {
    setHoveredVideo(null);
    videoRefs.current[index].pause();
  };
  const selectedQuote = useSelector((state) => state.newOrderAdminSlice.quotes);

  const handleQuoteChange = (e) => {
    const selectedValue = e.target.value;
    const parsedValue = JSON.parse(selectedValue);
    dispatch(setQuotes(parsedValue));
    setChangesOccur(true);
  };
  useEffect(() => {
    axios({
      method: "GET",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/get-quotes",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setQuoteList(res?.data?.Data || []);
      })
      .catch((err) => {});
  }, []);

  const [quoteList, setQuoteList] = useState([]);

  // for filter img
  const [searchCoverImg, setSearchCoverImg] = useState("");
  const [filterCoverImg, setFilterCoverImg] = useState([]);
  useEffect(() => {
    setFilterCoverImg(img);
  }, [img]);
  const handleSearchCoverImgChange = (e) => {
    const inputValue = e.target.value;
    setSearchCoverImg(inputValue);

    const filterData = img?.filter((item) =>
      item.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilterCoverImg(filterData);
  };

  // for filter video
  const [searchScenery, setSearchScenery] = useState("");
  const [filterScenery, setFilterScenery] = useState([]);
  useEffect(() => {
    setFilterScenery(videos);
  }, [videos]);
  const handleSearchSceneryChange = (e) => {
    const inputValue = e.target.value;
    setSearchScenery(inputValue);

    const filterData = videos?.filter((item) =>
      item.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilterScenery(filterData);
  };

  // for filter music
  const [searchMusic, setSearchMusic] = useState("");
  const [filterMusic, setFilterMusic] = useState([]);
  useEffect(() => {
    setFilterMusic(musics);
  }, [musics]);
  const handleSearchMusicChange = (e) => {
    const inputValue = e.target.value;
    setSearchMusic(inputValue);

    const filterData = musics?.filter((item) =>
      item.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilterMusic(filterData);
  };
  const uploadVideo = useSelector(
    (state) => state.newOrderAdminSlice.uploadVideo
  );

  const uploadPhoto = useSelector(
    (state) => state.newOrderAdminSlice.uploadPhotos
  );

  const uploadAudio = useSelector(
    (state) => state.newOrderAdminSlice.uploadAudio
  );
  const coverImage = useSelector(
    (state) => state.newOrderAdminSlice.coverImage
  );

  const music = useSelector((state) => state.newOrderAdminSlice.music);
  const scenery = useSelector((state) => state.newOrderAdminSlice.scenery);
  const coverPhotosId = selectedImg?.map((item) => item?.id);
  const sceneryId = selectedVideo?.map((item) => item?.id);
  const musicId = selectedMusic?.map((item) => item?.id);
  return (
    <div className="w-full flex flex-col items-center gap-[20px]">
      <h3 className="font-bold w-full">Video Preferences*</h3>

      <div className="py-[50px] w-full flex justify-center">
        <div className="w-full flex-wrap xl:w-[90%] flex items-center justify-between gap-[20px]">
          <button
            onClick={() => handleTabs(1)}
            className=" relative p-[10px] w-full sm:w-[45%] xl:w-[25%] bg-[#22d87c] text-white rounded-[7px]"
          >
            Select Cover Image
            <div className="w-[20px] h-[20px] text-[10px] flex justify-center items-center font-semibold absolute top-[-5px] left-[-5px] bg-green-800 text-white rounded-[50px]">
              {selectedImg?.length || 0}
            </div>
          </button>
          <button
            onClick={() => handleTabs(2)}
            className="relative p-[10px] w-full sm:w-[45%] xl:w-[25%] bg-[#22d87c] text-white rounded-[7px]"
          >
            Select Scenery
            <div className="w-[20px] h-[20px] text-[10px] flex justify-center items-center font-semibold absolute top-[-5px] left-[-5px] bg-green-800 text-white rounded-[50px]">
              {selectedVideo?.length || 0}
            </div>
          </button>
          <button
            onClick={() => handleTabs(3)}
            className="relative p-[10px] w-full sm:w-[45%] xl:w-[25%] bg-[#22d87c] text-white rounded-[7px]"
          >
            Select Music
            <div className="w-[20px] h-[20px] text-[10px] flex justify-center items-center font-semibold absolute top-[-5px] left-[-5px] bg-green-800 text-white rounded-[50px]">
              {selectedMusic?.length || 0}
            </div>
          </button>
          {!orderId ? null : (
            <button
              onClick={() => handleTabs(4)}
              className="relative p-[10px] w-full sm:w-[45%] xl:w-[25%] bg-[#22d87c] text-white rounded-[7px]"
            >
              Upload Video
              <div className="w-[20px] h-[20px] text-[10px] flex justify-center items-center font-semibold absolute top-[-5px] left-[-5px] bg-green-800 text-white rounded-[50px]">
                {uploadVideo?.length || 0}
              </div>
            </button>
          )}
          {!orderId ? null : (
            <button
              onClick={() => handleTabs(5)}
              className="relative p-[10px] w-full sm:w-[45%] xl:w-[25%] bg-[#22d87c] text-white rounded-[7px]"
            >
              Upload Image
              <div className="w-[20px] h-[20px] text-[10px] flex justify-center items-center font-semibold absolute top-[-5px] left-[-5px] bg-green-800 text-white rounded-[50px]">
                {uploadPhoto?.length || 0}
              </div>
            </button>
          )}

          {!orderId ? null : (
            <button
              onClick={() => handleTabs(6)}
              className="relative p-[10px] w-full sm:w-[45%] xl:w-[25%] bg-[#22d87c] text-white rounded-[7px]"
            >
              Upload Music
              <div className="w-[20px] h-[20px] text-[10px] flex justify-center items-center font-semibold absolute top-[-5px] left-[-5px] bg-green-800 text-white rounded-[50px]">
                {uploadAudio?.length || 0}
              </div>
            </button>
          )}
        </div>
      </div>
      <div className="w-full flex flex-col gap-[7px]">
        <label htmlFor="specialNotes" className="font-semibold">
          Special Notes*
        </label>
        <textarea
          name="specialNotes"
          id="specialNotes"
          onChange={handleSpecialNotesChange}
          value={specialNotes}
          autoComplete="false"
          className="p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none h-[150px]"
          placeholder="Special Notes here..."
        />
      </div>
      <img loading="lazy" src={Img} alt="img" className="" />
      <div className="w-full flex flex-col gap-[12px]">
        <label htmlFor="quotes">Quotes*</label>
        <select
          id="quotes"
          name="quotes"
          onChange={handleQuoteChange}
          value={selectedQuote?.name ? JSON.stringify(selectedQuote) : ""}
          className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
        >
          <option value={JSON.stringify(selectedQuote)}>
            {`${selectedQuote?.id}. ${selectedQuote?.text}`}
          </option>
          {quoteList.map((item, index) => (
            <option key={index} value={JSON.stringify(item)}>
              {index + 1}. {item?.text}
            </option>
          ))}
        </select>
      </div>

      {tabs === 1 ? (
        <div className="absolute w-full h-full backdrop-filter backdrop-blur-sm bg-white/30 top-0 left-0 flex justify-center items-center">
          <div className="w-[80%] h-[90%] bg-white overflow-y-scroll flex flex-col gap-[20px] p-[30px] sm:p-[50px] hide-scrollbar shadow-xl shadow-gray-500  rounded-[20px]">
            <div className="w-full flex justify-between items-center">
              <button
                onClick={() => {
                  // handlePutImg();
                  setTabs(2);
                }}
                className="px-[20px] py-[12px] rounded-[7px] bg-[#22d87c] text-white"
              >
                Next
              </button>
              <button onClick={() => setTabs(false)}>
                <FaX />
              </button>
            </div>
            <h2 className="text-[25px] font-extrabold text-center">
              Select Cover Image
            </h2>

            <div className="sticky bg-gray-200 items-center p-[12px] rounded-[7px] top-0 right-0 z-[85] w-full flex flex-col sm:flex-row justify-between gap-[20px]">
              <div className="w-full flex justify-start">
                {selectedImg?.length === 0 ? (
                  <button
                    onClick={() => {
                      dispatch(setSelectedImg([]));
                      setTabs(2);
                    }}
                    className="px-[20px] py-[12px] rounded-[7px] bg-[#f5f5f5] text-gray-400 font-semibold"
                  >
                    No Selection
                  </button>
                ) : (
                  <div className="w-full flex items-center gap-[7px] flex-wrap">
                    <h3 className="font-semibold">Selected Image :</h3>
                    {coverImage
                      ?.filter((item, index) => coverPhotosId?.includes(index))
                      ?.map((item, index) => {
                        return (
                          <p key={index} className="text-black">
                            {item
                              ?.split("/")
                              [item?.split("/").length - 1]?.split(".")[0]
                              .concat(",")}
                          </p>
                        );
                      })}
                  </div>
                )}
              </div>
              <div className="flex items-center bg-gray-200 shadow-lg rounded-[7px] p-[10px] gap-[7px]">
                <input
                  type="text"
                  name="searchCoverImg"
                  id="searchCoverImg"
                  value={searchCoverImg}
                  onChange={handleSearchCoverImgChange}
                  className="outline-none bg-transparent w-full h-full"
                  autoComplete="false"
                  placeholder={`Search here`}
                />
                <FaSearch />
              </div>
            </div>
            <div className="w-full flex justify-center flex-wrap gap-[20px]">
              {filterCoverImg.map((item, index) => {
                const selectedImgData = selectedImg.find(
                  (imgData) => imgData.id === index
                );
                const imgNote = selectedImgData ? selectedImgData.notes : "";
                return (
                  <div
                    key={index}
                    className="w-full transition-all ease-in bg-white p-[15px] sm:w-[45%] rounded-[7px] xl:w-[22%] h-auto flex flex-col gap-[7px]"
                  >
                    <label
                      htmlFor={`checkbox-${index}`}
                      className="w-full  relative "
                    >
                      <p className="mb-[10px] font-bold tracking-[2px]">
                        {
                          item
                            ?.split("/")
                            [item?.split("/").length - 1]?.split(".")[0]
                        }
                      </p>
                      <img
                        loading="lazy"
                        src={item}
                        alt="img"
                        className="w-[200px] h-[200px]  object-cover rounded-[7px]"
                      />
                      <input
                        type="checkbox"
                        id={`checkbox-${index}`}
                        value={index}
                        onChange={handleSelectedImg}
                        className="absolute top-[7px] right-[7px]"
                        checked={selectedImg.some(
                          (imgData) => imgData.id === index
                        )}
                      />
                    </label>
                    {selectedImg.find((img) => img.id === index) ? (
                      <input
                        type="text"
                        name="imgNote"
                        id={`imgNote-${index}`}
                        value={imgNote}
                        className="w-full p-[7px] bg-gray-200 outline-none rounded-b-[7px] text-gray-500 font-medium"
                        onChange={(e) => handleImgNotes(e, index)}
                        placeholder="Type in your notes"
                      />
                    ) : null}
                  </div>
                );
              })}
            </div>
            <div className="w-full sticky bottom-0 z-[85] flex justify-center mt-[30px] items-center gap-[20px]"></div>
          </div>
        </div>
      ) : null}

      {tabs === 2 ? (
        <div className="absolute w-full h-full backdrop-filter backdrop-blur-sm bg-white/30 top-0 left-0 flex justify-center items-center">
          <div className="w-[80%] h-[90%] bg-white overflow-y-scroll flex flex-col gap-[20px] p-[30px] sm:p-[50px] hide-scrollbar shadow-xl shadow-gray-500 rounded-[20px]">
            <div className="w-full flex justify-between items-center">
              <button
                onClick={() => setTabs(3)}
                className="px-[20px] py-[12px] rounded-[7px] bg-[#22d87c] text-white"
              >
                Next
              </button>
              <button onClick={() => setTabs(false)}>
                <FaX />
              </button>
            </div>
            <h2 className="text-[25px] font-extrabold text-center">
              Select Scenery
            </h2>

            <div className="sticky bg-gray-200 items-center p-[12px] rounded-[7px] top-0 right-0 z-[85] w-full flex flex-col sm:flex-row justify-between gap-[20px]">
              <div className="w-full flex justify-start">
                {selectedVideo?.length === 0 ? (
                  <button
                    onClick={() => {
                      dispatch(setSelectedVideo([]));
                      setTabs(3);
                    }}
                    className="px-[20px] py-[12px] rounded-[7px] bg-[#f5f5f5] text-gray-400 font-semibold"
                  >
                    No Selection
                  </button>
                ) : (
                  <div className="w-full flex items-center gap-[7px] flex-wrap">
                    <h3 className="font-semibold">Selected Scenery :</h3>
                    {scenery
                      ?.filter((item, index) => sceneryId?.includes(index))
                      ?.map((item, index) => {
                        return (
                          <p key={index} className="">
                            {item
                              ?.split("/")
                              [item?.split("/").length - 1]?.split(".")[0]
                              .concat(",")}
                          </p>
                        );
                      })}
                  </div>
                )}
              </div>
              <div className="flex items-center bg-gray-200 shadow-lg rounded-[7px] p-[10px] gap-[7px]">
                <input
                  type="text"
                  name="searchScenery"
                  id="searchScenery"
                  value={searchScenery}
                  onChange={handleSearchSceneryChange}
                  className="outline-none bg-transparent w-full h-full"
                  autoComplete="false"
                  placeholder={`Search here`}
                />
                <FaSearch />
              </div>
            </div>

            <div className="w-full flex justify-center flex-wrap gap-[20px]">
              {filterScenery.map((item, index) => {
                const selectedVideoData = selectedVideo.find(
                  (video) => video.id === index
                );
                const videoNote = selectedVideoData
                  ? selectedVideoData.notes
                  : "";

                return (
                  <div
                    key={index}
                    className="w-full bg-white p-[15px] sm:w-[45%] rounded-[7px] xl:w-[22%] h-auto flex flex-col gap-[7px]"
                  >
                    <label
                      htmlFor={`checkbox-${index}`}
                      className="w-full  relative"
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                    >
                      <p className="mb-[10px] font-bold tracking-[2px]">
                        {
                          item
                            ?.split("/")
                            [item?.split("/").length - 1]?.split(".")[0]
                        }
                      </p>
                      <video
                        ref={(el) => (videoRefs.current[index] = el)}
                        src={item}
                        alt="video"
                        className="w-[200px] h-[200px] object-cover rounded-[7px]"
                        loop
                        muted
                      />
                      <input
                        type="checkbox"
                        id={`checkbox-${index}`}
                        value={index}
                        onChange={handleSelectedVideo}
                        className="absolute top-[7px] right-[7px]"
                        checked={selectedVideo.some(
                          (video) => video.id === index
                        )}
                      />
                    </label>
                    {selectedVideo.find((video) => video.id === index) ? (
                      <input
                        type="text"
                        name="videoNote"
                        id={`videoNote-${index}`}
                        value={videoNote}
                        className="w-full p-[7px] bg-gray-200 outline-none rounded-b-[7px] text-gray-500 font-medium"
                        onChange={(e) => handleVideoNotes(e, index)}
                        placeholder="Type in your Notes"
                      />
                    ) : null}
                  </div>
                );
              })}
            </div>
            <div className="w-full sticky bottom-0 z-[85] flex justify-center mt-[30px] items-center gap-[20px]"></div>
          </div>
        </div>
      ) : null}

      {tabs === 3 ? (
        <div className="absolute w-full h-full backdrop-filter backdrop-blur-sm bg-white/30 top-0 left-0 flex justify-center items-center">
          <div className="w-[80%] h-[90%] bg-white overflow-y-scroll flex flex-col gap-[20px] p-[30px] sm:p-[50px] hide-scrollbar shadow-xl shadow-gray-500  rounded-[20px]">
            <div className="w-full flex justify-between items-center">
              <button
                onClick={() => setTabs(false)}
                className="px-[20px] py-[12px] rounded-[7px] bg-[#22d87c] text-white"
              >
                Save
              </button>
              <button onClick={() => setTabs(false)}>
                <FaX />
              </button>
            </div>
            <h2 className="text-[25px] font-extrabold text-center">
              Select Music
            </h2>
            <div className="sticky bg-gray-200 items-center p-[12px] rounded-[7px] top-0 right-0 z-[85] w-full flex flex-col sm:flex-row justify-between gap-[20px]">
              <div className="w-full flex justify-start">
                {selectedMusic?.length === 0 ? (
                  <button
                    onClick={() => {
                      dispatch(setSelectedMusic([]));
                      setTabs(false);
                    }}
                    className="px-[20px] py-[12px] rounded-[7px] bg-[#f5f5f5] text-gray-400 font-semibold"
                  >
                    No Selection
                  </button>
                ) : (
                  <div className="w-full flex items-center gap-[7px] flex-wrap">
                    <h3 className="font-semibold">Selected Music :</h3>
                    {music
                      ?.filter((item, index) => musicId?.includes(index))
                      ?.map((item, index) => {
                        return (
                          <p key={index} className="">
                            {item
                              ?.split("/")
                              [item?.split("/").length - 1]?.split(".")[0]
                              .concat(",")}
                          </p>
                        );
                      })}
                  </div>
                )}
              </div>
              <div className="flex items-center shadow-lg bg-gray-200 rounded-[7px] p-[10px] gap-[7px]">
                <input
                  type="text"
                  name="searchMusic"
                  id="searchMusic"
                  value={searchMusic}
                  onChange={handleSearchMusicChange}
                  className="outline-none bg-transparent w-full h-full"
                  autoComplete="false"
                  placeholder={`Search here`}
                />
                <FaSearch />
              </div>
            </div>

            <div className="w-full flex justify-center flex-wrap gap-[20px]">
              {filterMusic.map((item, index) => {
                const selectedMusicData = selectedMusic.find(
                  (music) => music.id === index
                );
                const musicNote = selectedMusicData
                  ? selectedMusicData.notes
                  : "";

                return (
                  <div
                    key={index}
                    className="w-full bg-white p-[15px] sm:w-[45%] rounded-[7px] xl:w-[22%] h-auto flex flex-col gap-[7px]"
                  >
                    <p className="mb-[10px] font-bold tracking-[2px]">
                      {
                        item
                          ?.split("/")
                          [item?.split("/").length - 1]?.split(".")[0]
                      }
                    </p>
                    <label
                      htmlFor={`checkbox-${index}`}
                      className="w-full relative "
                    >
                      <img
                        loading="lazy"
                        src={musicImg}
                        alt="music"
                        className="w-full h-[250px] object-contain absolute top-0 left-0 z-[1]"
                      />
                      <audio
                        ref={(el) => (audioRefs.current[index] = el)}
                        src={item}
                        controls
                        className="w-full h-[250px] object-contain rounded-[7px] relative z-[2]"
                      />
                      <input
                        type="checkbox"
                        id={`checkbox-${index}`}
                        name={`checkbox-${index}`}
                        value={index}
                        onChange={handleSelectedMusic}
                        className="absolute z-[5] top-[7px] right-[7px]"
                        checked={selectedMusic.some(
                          (music) => music.id === index
                        )}
                      />
                    </label>
                    {selectedMusic.find((music) => music.id === index) ? (
                      <input
                        type="text"
                        name="musicNote"
                        id={`musicNote-${index}`}
                        value={musicNote}
                        className="w-full p-[7px] bg-gray-200 outline-none rounded-b-[7px] text-gray-500 font-medium"
                        onChange={(e) => handleMusicNotes(e, index)}
                        placeholder="Type in your Notes"
                      />
                    ) : null}
                  </div>
                );
              })}
            </div>
            <div className="w-full sticky bottom-0 z-[85] flex justify-center mt-[30px] items-center gap-[20px]"></div>
          </div>
        </div>
      ) : null}
      {tabs === 4 ? (
        <div className="absolute w-full h-full backdrop-filter backdrop-blur-sm bg-white/30 top-0 left-0 flex justify-center items-center">
          <div className="w-[90%] h-[90%] bg-white overflow-y-scroll flex flex-col gap-[20px] p-[30px] sm:p-[50px] hide-scrollbar shadow-xl shadow-gray-500  rounded-[20px]">
            <VideoUpload
              changesOccur={changesOccur}
              setChangesOccur={setChangesOccur}
              orderId={orderId}
              tabs={tabs}
              setTabs={setTabs}
            />
          </div>
        </div>
      ) : null}
      {tabs === 5 ? (
        <div className="absolute w-full h-full backdrop-filter backdrop-blur-sm bg-white/30 top-0 left-0 flex justify-center items-center">
          <div className="w-[90%] h-[90%] bg-white overflow-y-scroll flex flex-col gap-[20px] p-[30px] sm:p-[50px] hide-scrollbar shadow-xl shadow-gray-500  rounded-[20px]">
            <Step3
              changesOccur={changesOccur}
              setChangesOccur={setChangesOccur}
              orderId={orderId}
              tabs={tabs}
              setTabs={setTabs}
              getOrderData={getOrderData}
            />
          </div>
        </div>
      ) : null}
      {tabs === 6 ? (
        <div className="absolute w-full h-full backdrop-filter backdrop-blur-sm bg-white/30 top-0 left-0 flex justify-center items-center">
          <div className="w-[90%] h-[90%] bg-white overflow-y-scroll flex flex-col gap-[20px] p-[30px] sm:p-[50px] hide-scrollbar shadow-xl shadow-gray-500  rounded-[20px]">
            <Step3
              changesOccur={changesOccur}
              setChangesOccur={setChangesOccur}
              orderId={orderId}
              tabs={tabs}
              setTabs={setTabs}
              getOrderData={getOrderData}
            />
          </div>
        </div>
      ) : null}

      {tabs === 6 ? (
        <div className="absolute w-full h-full backdrop-filter backdrop-blur-sm bg-white/30 top-0 left-0 flex justify-center items-center">
          <div className="w-[90%] h-[90%] bg-white overflow-y-scroll flex flex-col gap-[20px] p-[30px] sm:p-[50px] hide-scrollbar shadow-xl shadow-gray-500  rounded-[20px]">
            <MusicUpload
              changesOccur={changesOccur}
              setChangesOccur={setChangesOccur}
              orderId={orderId}
              tabs={tabs}
              setTabs={setTabs}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Step2;
