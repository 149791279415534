import React from "react";

const SkeletonLoader = () => {
  return (
    <div className="w-full flex text-[14px] flex-col gap-[20px] animate-pulse">
      <div className="w-full flex flex-col items-center gap-[20px]">
        <div className="h-6 bg-gray-300 rounded w-full"></div>
        <div className="w-full flex justify-start flex-wrap items-center gap-[20px]">
          {Array.from({ length: 8 }).map((_, index) => (
            <div
              key={index}
              className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]"
            >
              <div className="h-4 bg-gray-300 rounded w-1/2"></div>
              <div className="h-10 bg-gray-300 rounded w-full"></div>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full flex flex-col items-center gap-[20px]">
        <div className="h-6 bg-gray-300 rounded w-full"></div>
        <div className="w-full text-[14px] flex justify-start flex-wrap items-center gap-[20px]">
          {Array.from({ length: 6 }).map((_, index) => (
            <div
              key={index}
              className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]"
            >
              <div className="h-4 bg-gray-300 rounded w-1/2"></div>
              <div className="h-10 bg-gray-300 rounded w-full"></div>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full flex flex-col items-center gap-[20px]">
        <div className="w-full flex flex-col gap-[4px]">
          <div className="h-6 bg-gray-300 rounded w-full"></div>
          <div className="h-4 bg-gray-300 rounded w-3/4"></div>
        </div>
        <div className="w-full text-[14px] flex justify-start flex-wrap items-center gap-[20px]">
          {Array.from({ length: 6 }).map((_, index) => (
            <div
              key={index}
              className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]"
            >
              <div className="h-4 bg-gray-300 rounded w-1/2"></div>
              <div className="h-10 bg-gray-300 rounded w-full"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
