import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import Step1 from "../new-order-components/step1";
import Step2 from "../new-order-components/step2";
import { useDispatch, useSelector } from "react-redux";
import {
  setAdminSidebar,
  setCustomerSidebar,
} from "../../../redux/reducer/sidebarSlice";
import { FaArrowLeft, FaDollarSign } from "react-icons/fa6";
import Step3 from "../new-order-components/imageUpload";
import {
  setCoverImgPricing,
  setMusicPricing,
  setOverallAmount,
  setSceneryPricing,
  setTotalPricing,
  setUploadMusicPricing,
  setUploadPricing,
  setUploadVideoPricing,
} from "../../../redux/reducer/pricingSlice";
import axios from "axios";
import {
  setClientInformation,
  setFuneralDetails,
  setImg,
  setOrderDetails,
  setQuotes,
  setSelectedImg,
  setSelectedMusic,
  setSelectedVideo,
  setType,
  setUploadAudio,
  setUploadPhoto,
  setUploadVideo,
  setVideos,
} from "../../../redux/reducer/newOrderAdminSlice";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { setFiles } from "../../../redux/reducer/imageUploadSlice";
import { showToastError, showToastSuccess } from "../../../utils/toastify";
import { setAdminInfo } from "../../../redux/reducer/adminInfoSlice";
import SkeletonLoader from "../layout/newOrderSkeleton";

const AdminNewOrder = () => {
  const { orderId } = useParams();
  const [changesOccur, setChangesOccur] = useState(false);

  const [data, setData] = useState(null);
  const [steps, setSteps] = useState(1);
  const dispatch = useDispatch();
  const [daysToCompleted, setDaysToCompleted] = useState(null);

  const files = useSelector((state) => state.imageUploadSlice.files);
  const videoFiles = useSelector((state) => state.videoUploadSlice.files);
  const clientInformation = useSelector(
    (state) => state.newOrderAdminSlice.clientInformation
  );
  const orderDetails = useSelector(
    (state) => state.newOrderAdminSlice.orderDetails
  );
  const funeralDetails = useSelector(
    (state) => state.newOrderAdminSlice.funeralDetails
  );
  const selectedVideo = useSelector(
    (state) => state.newOrderAdminSlice.selectedVideo
  );
  const uploadPhotos = useSelector(
    (state) => state.newOrderAdminSlice.uploadPhotos
  );
  const uploadVideo = useSelector(
    (state) => state.newOrderAdminSlice.uploadVideo
  );
  const uploadAudio = useSelector(
    (state) => state.newOrderAdminSlice.uploadAudio
  );
  const coverImgPricing = useSelector(
    (state) => state.pricingSlice.coverImgPricing
  );
  const sceneryPricing = useSelector(
    (state) => state.pricingSlice.sceneryPricing
  );
  const uploadVideoPricing = useSelector(
    (state) => state.pricingSlice.uploadVideoPricing
  );
  const uploadMusicPricing = useSelector(
    (state) => state.pricingSlice.uploadMusicPricing
  );
  const totalPricing = useSelector((state) => state.pricingSlice.totalPricing);
  const musicPricing = useSelector((state) => state.pricingSlice.musicPricing);
  const uploadPricing = useSelector(
    (state) => state.pricingSlice.uploadPricing
  );
  const selectedImg = useSelector(
    (state) => state.newOrderAdminSlice.selectedImg
  );

  const quotes = useSelector((state) => state.newOrderAdminSlice.quotes);
  const admin = useSelector((state) => state.adminInfoSlice.admin);
  const type = useSelector((state) => state.newOrderAdminSlice.type);

  const [specialNotes, setSpecialNotes] = useState("");

  const handleSpecialNotesChange = (e) => {
    setSpecialNotes(e.target.value);
    setChangesOccur(true);
  };
  useEffect(() => {
    dispatch(setAdminSidebar("create-order"));
  }, [dispatch]);

  // Musics
  const [musics, setMusics] = useState([]);

  const selectedMusic = useSelector(
    (state) => state.newOrderAdminSlice.selectedMusic
  );

  const updateTotalPricing = () => {
    dispatch(setMusicPricing(selectedMusic?.length * admin?.pricePerPhoto));
    dispatch(setUploadPricing(uploadPhotos?.length * admin?.pricePerPhoto));
    dispatch(setCoverImgPricing(selectedImg?.length * admin?.pricePerPhoto));
    dispatch(setSceneryPricing(selectedVideo?.length * admin?.pricePerPhoto));
    dispatch(setUploadVideoPricing(uploadVideo?.length * admin?.pricePerPhoto));
    dispatch(setUploadMusicPricing(uploadAudio?.length * admin?.pricePerPhoto));
  };

  useEffect(() => {
    updateTotalPricing();
  }, [
    selectedMusic,
    uploadPhotos,
    selectedImg,
    selectedVideo,
    uploadVideo,
    uploadAudio,
  ]);
  const handleSelectedMusic = (e) => {
    const value = Number(e.target.value);
    dispatch((dispatch, getState) => {
      const prevSelectedMusic = getState().newOrderAdminSlice.selectedMusic;
      const isSelected = prevSelectedMusic.some((music) => music?.id === value);

      if (isSelected) {
        dispatch(
          setSelectedMusic(
            prevSelectedMusic.filter((music) => music?.id !== value)
          )
        );
      } else {
        dispatch(
          setSelectedMusic([...prevSelectedMusic, { id: value, notes: "" }])
        );
      }
      setChangesOccur(true);
    });
  };
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = (index) => {
    setShowModal(index === showModal ? null : index);
  };

  const [emptyFields, setEmptyFields] = useState([]);
  const handleFieldsFilled = () => {
    // Trim and prepare the fields for validation
    const trimmedContactName = clientInformation?.contactName
      ?.toString()
      .trim();
    const trimmedEmail = clientInformation?.email?.toString().trim();
    const trimmedPhone = clientInformation?.phone?.toString().trim();
    const trimmedAddress = clientInformation?.address?.toString().trim();
    const trimmedCity = clientInformation?.city?.toString().trim();
    const trimmedState = clientInformation?.state?.toString().trim();
    const trimmedPostalCode = clientInformation?.postalCode?.toString().trim();
    const trimmedRequestedDate = orderDetails?.requestedDate?.toString().trim();
    const trimmedRequestedTime = orderDetails?.requestedTime?.toString().trim();
    const trimmedStyleOptions = orderDetails?.styleOption?.toString().trim();
    const trimmedVideoType = orderDetails?.videoType?.toString().trim();
    const trimmedProductionType = orderDetails?.productionType
      ?.toString()
      .trim();
    const trimmedSubjectName = funeralDetails?.subjectName?.toString().trim();
    const trimmedDateOfBirth = funeralDetails?.dateOfBirth?.toString().trim();
    const trimmedDateOfDeath = funeralDetails?.dateOfDeath?.toString().trim();
    const trimmedFuneralDate = funeralDetails?.funeralDate?.toString().trim();
    const trimmedFuneralTime = funeralDetails?.funeralTime?.toString().trim();

    // Define the fields to check and validate
    const fieldsToCheck = {
      contactName: trimmedContactName,
      email: trimmedEmail,
      phone: trimmedPhone,
      address: trimmedAddress,
      city: trimmedCity,
      state: trimmedState,
      postalCode: trimmedPostalCode,
      requestedDate: trimmedRequestedDate,
      requestedTime: trimmedRequestedTime,
      styleOption: trimmedStyleOptions,
      videoType: trimmedVideoType,
      productionType: trimmedProductionType,
      subjectName: trimmedSubjectName,
      dateOfBirth: trimmedDateOfBirth,
      dateOfDeath: trimmedDateOfDeath,
      funeralDate: trimmedFuneralDate,
      funeralTime: trimmedFuneralTime,
      // selectedImg: selectedImg?.length !== 0,
      // selectedMusic: selectedMusic?.length !== 0,
      // selectedVideo: selectedVideo?.length !== 0,
    };

    // Determine which fields are empty
    const emptyFields = Object.keys(fieldsToCheck).filter(
      (key) => !fieldsToCheck[key]
    );
    setEmptyFields(emptyFields);

    // Define the required fields for basic validation
    const requiredFields = [
      "contactName",
      "email",
      "phone",
      "address",
      "city",
      "state",
      "postalCode",
      "requestedDate",
      "requestedTime",
      "productionType",
      "subjectName",
      "dateOfBirth",
      "dateOfDeath",
    ];

    // Check if any required field is missing
    const isAnyRequiredFieldMissing = requiredFields.some((field) =>
      emptyFields.includes(field)
    );

    if (isAnyRequiredFieldMissing) {
      setSteps(1); // Navigate to step 1 if required fields are missing
    } else if (emptyFields.includes("selectedImg")) {
      setSteps(2);
      showToastError("Please Select at least One Cover Image");
    } else if (emptyFields.includes("selectedVideo")) {
      showToastError("Please Select at least One Scenery");
    } else if (emptyFields.includes("selectedMusic")) {
      showToastError("Please Select at least One Music");
    } else {
      // All fields are filled correctly
      handleShowModal("publishOrder"); // Proceed to show the modal
    }
  };

  const img = useSelector((state) => state.newOrderAdminSlice.img);

  const handleSubmitDropBox = () => {
    axios({
      method: "GET",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/get-admin-data",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch(setImg(res?.data?.Data?.coverImage));
        setMusics(res?.data?.Data?.music);
        setData(res?.data?.Data);
        dispatch(setVideos(res?.data?.Data?.scenery));
        dispatch(setAdminInfo(res?.data?.Data));
        setDaysToCompleted(res?.data?.Data?.daysNeededToCompleted);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    handleSubmitDropBox();
  }, []);

  const handleSelectedImg = (e) => {
    const value = Number(e.target.value);
    dispatch((dispatch, getState) => {
      const prevSelectedImg = getState().newOrderAdminSlice.selectedImg;
      const isSelected = prevSelectedImg.some(
        (imgData) => imgData.id === value
      );

      if (isSelected) {
        dispatch(
          setSelectedImg(
            prevSelectedImg.filter((imgData) => imgData.id !== value)
          )
        );
      } else {
        dispatch(
          setSelectedImg([...prevSelectedImg, { id: value, notes: "" }])
        );
      }
      setChangesOccur(true);
    });
  };

  const token = localStorage.getItem("adminToken");
  const updateReqBody = {
    id: orderId,
    businessName: clientInformation?.businessName,
    contactName: clientInformation?.contactName,
    email: clientInformation?.email,
    phone: clientInformation?.phone,
    address: clientInformation?.address,
    city: clientInformation?.city,
    state: clientInformation?.state,
    postalCode: clientInformation?.postalCode,
    requestedDate: orderDetails?.requestedDate,
    requestedTime: orderDetails?.requestedTime,
    styleOption: orderDetails?.styleOption,
    videoType: orderDetails?.videoType,
    productionType: orderDetails?.productionType,
    subjectName: funeralDetails?.subjectName,
    orderCreationDate: orderDetails?.orderCreationDate || null,
    dateOfBirth: funeralDetails?.dateOfBirth || null,
    dateOfDeath: funeralDetails?.dateOfDeath || null,
    funeralDate: funeralDetails?.funeralDate || null,
    funeralTime: funeralDetails?.funeralTime || null,
    coverPhotos: selectedImg,
    scenery: selectedVideo,
    music: selectedMusic,
    customer_id: Number(localStorage.getItem("customerId")),
    photo: uploadPhotos || null,
    video: uploadVideo || null,
    type: type,
    specialNotes: specialNotes,
    quote: quotes,
    uploadMusic: uploadAudio,
    pricingObj: {
      totalPrice: totalPricing,
      musicPrice: musicPricing,
      imagePrice: uploadPricing,
      videoPrice: uploadVideoPricing,
      coverImagePrice: coverImgPricing,
      sceneryPrice: sceneryPricing,
      uploadMusicPricing: uploadMusicPricing,
    },
  };

  const handlePutReq = () => {
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/update-order",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: updateReqBody,
    })
      .then((res) => {
        setChangesOccur(false);
        // getOrderData();
        showToastSuccess(res?.data?.message);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (changesOccur === true) {
        handlePutReq();
      }
    }, [2000]);
    return () => clearTimeout(timer);
  }, [updateReqBody]);

  const [getTypeData, setGetTypeData] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);
  const getOrderData = () => {
    axios({
      method: "GET",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/admin/get-order/${orderId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setIsDataFetched(true);
        let data = res?.data?.Data;
        setGetTypeData(data?.type);
        dispatch(setType(data?.type));
        dispatch(
          setClientInformation({
            businessName: data?.businessName,
            contactName: data?.contactName,
            email: data?.email,
            phone: data?.phone,
            address: data?.address,
            city: data?.city,
            state: data?.state,
            postalCode: data?.postalCode,
          })
        );
        dispatch(
          setOrderDetails({
            orderCreationDate: moment(data?.createdAt).format("YYYY-MM-DD"),
            requestedDate: data?.requestedDate,
            requestedTime: data?.requestedTime,
            orderNumber: data?.id,
            styleOption: data?.styleOption,
            videoType: data?.videoType,
            productionType: data?.productionType,
          })
        );
        dispatch(
          setFuneralDetails({
            subjectName: data?.subjectName,
            dateOfBirth: data?.dateOfBirth,
            dateOfDeath: data?.dateOfDeath,
            funeralDate: data?.funeralDate,
            funeralTime: data?.funeralTime,
          })
        );

        dispatch(setQuotes(data?.quote));

        dispatch(
          setSelectedImg(
            data?.coverPhotos?.map((item) => {
              return item;
            })
          )
        );
        setSpecialNotes(data?.specialNotes);

        if (data?.type !== "Draft") {
          dispatch(setTotalPricing(data?.pricingObj?.totalPrice || 0));
          dispatch(setMusicPricing(data?.pricingObj?.musicPrice || 0));
          dispatch(setUploadPricing(data?.pricingObj?.imagePrice || 0));
          dispatch(
            setUploadMusicPricing(data?.pricingObj?.uploadMusicPricing || 0)
          );
          dispatch(setCoverImgPricing(data?.pricingObj?.coverImagePrice || 0));
          dispatch(setSceneryPricing(data?.pricingObj?.sceneryPrice || 0));
          dispatch(setUploadVideoPricing(data?.pricingObj?.videoPrice || 0));
        }

        dispatch(
          setSelectedVideo(
            data?.scenery?.map((item) => {
              return item;
            })
          )
        );
        dispatch(
          setSelectedMusic(
            data?.music?.map((item) => {
              return item;
            })
          )
        );
        dispatch(setUploadPhoto(data?.photo || []));
        dispatch(setUploadVideo(data?.video || []));
        dispatch(setUploadAudio(data?.uploadMusic || []));
      })
      .catch((err) => {
        setIsDataFetched(false);
      });
  };
  useEffect(() => {
    getOrderData();
    return () => {
      dispatch(
        setClientInformation({
          businessName: "",
          contactName: "",
          email: "",
          phone: "",
          address: "",
          city: "",
          state: "",
          postalCode: "",
        })
      );
      dispatch(
        setOrderDetails({
          orderCreationDate: "",
          requestedDate: "",
          requestedTime: "",
          orderNumber: "",
          styleOption: "",
          videoType: "",
          productionType: "",
        })
      );
      dispatch(
        setFuneralDetails({
          subjectName: "",
          dateOfBirth: "",
          dateOfDeath: "",
          funeralDate: "",
          funeralTime: "",
        })
      );
      dispatch(
        setOverallAmount({
          totalPricing: 0,
          musicPricing: 0,
          uploadPricing: 0,
          coverImgPricing: 0,
          sceneryPricing: 0,
          uploadVideoPricing: 0,
          uploadMusicPricing: 0,
        })
      );
      dispatch(setSelectedVideo([]));
      dispatch(setSelectedMusic([]));
    };
  }, []);

  const navigate = useNavigate();
  const handlePublishOrder = () => {
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/update-order",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        type: "Published",
        id: orderId,
        businessName: clientInformation?.businessName,
        contactName: clientInformation?.contactName,
        email: clientInformation?.email,
        phone: clientInformation?.phone,
        address: clientInformation?.address,
        city: clientInformation?.city,
        state: clientInformation?.state,
        postalCode: clientInformation?.postalCode,
        requestedDate: orderDetails?.requestedDate,
        styleOption: orderDetails?.styleOption,
        videoType: orderDetails?.videoType,
        productionType: orderDetails?.productionType,
        subjectName: funeralDetails?.subjectName,
        orderCreationDate: orderDetails?.orderCreationDate || null,
        dateOfBirth: funeralDetails?.dateOfBirth || null,
        dateOfDeath: funeralDetails?.dateOfDeath || null,
        funeralDate: funeralDetails?.funeralDate || null,
        funeralTime: funeralDetails?.funeralTime || null,
        coverPhotos: selectedImg,
        scenery: selectedVideo,
        music: selectedMusic,
        customer_id: Number(localStorage.getItem("customerId")),
        photo: uploadPhotos || null,
        video: uploadVideo || null,
        specialNotes: specialNotes,
        quote: quotes,
      },
    })
      .then((res) => {
        showToastSuccess(res?.data?.message);
        // setShowModal("orderPayment");
        navigate(`/admin/delivery/${res?.data?.data?.id}`);
        getOrderData();
      })
      .catch((err) => {
        showToastError(err?.data?.response?.data);
      });
  };

  const handlePayment = () => {
    axios({
      method: "POST",
      // url: "https://funeral-api.hitoritech.co.uk/api/v1/payment/order-checkout-session",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        orderId: orderId,
        // totalPrice: totalPricing,
        // musicPrice: musicPricing,
        // imagePrice: uploadPricing,
        // videoPrice: uploadVideoPricing,
        // coverImagePrice: coverImgPricing,
        // sceneryPrice: sceneryPricing,
        // cost: 10,
      },
    })
      .then((res) => {
        showToastSuccess(res?.data?.message);
        window.location.href = res?.data?.Data?.url;
        setShowModal(false);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
        setShowModal(false);
      });
  };

  return (
    <Layout>
      <div className="w-full p-[20px] flex flex-col items-center gap-[20px]">
        <div className="w-full justify-center flex-col sm:flex-row flex gap-[20px] items-center">
          {steps > 1 && (
            <FaArrowLeft
              className="cursor-pointer"
              onClick={() => {
                if (steps > 1) {
                  setSteps(steps - 1);
                }
              }}
            />
          )}
          <h1 className="font-extrabold text-[25px] text-center">
            Order Placement Form
          </h1>
          {data?.hidePricing === true ? (
            <div className="font-medium px-[18px] py-[7px] bg-[#f5f5f5] rounded-[7px] flex items-center gap-[7px]">
              <FaDollarSign />
              {totalPricing}
            </div>
          ) : null}
        </div>
        {isDataFetched === true ? (
          <div>
            {steps === 1 ? (
              <Step1
                steps={steps}
                setSteps={setSteps}
                changesOccur={changesOccur}
                daysToCompleted={daysToCompleted}
                setChangesOccur={setChangesOccur}
                emptyFields={emptyFields}
              />
            ) : null}
          </div>
        ) : (
          <SkeletonLoader />
        )}
        {steps === 2 ? (
          <Step2
            orderId={orderId}
            steps={steps}
            setSteps={setSteps}
            musics={musics}
            handleSelectedMusic={handleSelectedMusic}
            selectedMusic={selectedMusic}
            img={img}
            handleSelectedImg={handleSelectedImg}
            selectedImg={selectedImg}
            data={data}
            changesOccur={changesOccur}
            setChangesOccur={setChangesOccur}
            getOrderData={getOrderData}
            specialNotes={specialNotes}
            handleSpecialNotesChange={handleSpecialNotesChange}
          />
        ) : null}
        {steps === 3 ? <Step3 steps={steps} setSteps={setSteps} /> : null}
        <div className="w-full flex mt-[30px] pt-[30px] border-t border-black flex-wrap items-center xl:flex-nowrap justify-center gap-[20px]">
          <button
            onClick={() => {
              navigate(`/admin/order-detail/${orderId}`);
            }}
            className="p-[10px] w-full sm:w-[20%] bg-[#22d87c] text-white rounded-[7px]"
          >
            Preview
          </button>
          {steps < 2 ? (
            <button
              onClick={() => {
                if (steps < 2) {
                  setSteps(steps + 1);
                } else if (steps === 2 && getTypeData === "Draft") {
                  handlePublishOrder();
                }
              }}
              className="p-[10px] w-full sm:w-[20%] bg-[#22d87c] text-white rounded-[7px]"
            >
              Next
            </button>
          ) : null}
          {steps === 2 && getTypeData === "Draft" ? (
            <button
              onClick={() => {
                handleFieldsFilled();
              }}
              className="p-[10px] w-full sm:w-[20%] bg-[#22d87c] text-white rounded-[7px]"
            >
              Publish Order
            </button>
          ) : null}
        </div>
      </div>
      {showModal === "publishOrder" ? (
        <div className="w-full h-full absolute bg-black bg-opacity-[.5] top-0 left-0 flex flex-col gap-[20px] items-center justify-center p-[30px]">
          <div className="w-full sm:w-[80%] xl:w-[50%] p-[30px] flex flex-col gap-[15px] items-center justify-center bg-white rounded-[15px] h-[300px]">
            <h3 className="font-semibold text-center text-[20px]">
              Do you really want to Publish this order ?
            </h3>
            <div className="flex justify-center items-center gap-[15px]">
              <button
                onClick={() => {
                  setShowModal(false);
                }}
                className="px-[20px] py-[12px] bg-[#e8e8e8] rounded-[7px] text-gray-500"
              >
                No
              </button>
              <button
                onClick={() => {
                  handlePublishOrder();
                }}
                className="px-[20px] py-[12px] bg-green-500 rounded-[7px] text-white"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {showModal === "orderPayment" ? (
        <div className="w-full h-full absolute bg-black bg-opacity-[.5] top-0 left-0 flex flex-col gap-[20px] items-center justify-center p-[30px]">
          <div className="w-full sm:w-[80%] xl:w-[50%] p-[30px] flex flex-col gap-[15px] items-center justify-center bg-white rounded-[15px] h-[300px]">
            <h3 className="font-semibold text-center text-[20px]">
              Here you can choose the payment Type?
            </h3>
            <div className="flex justify-center items-center gap-[15px]">
              <button
                onClick={() => {
                  setShowModal(false);
                  navigate("/admin/order-list");
                }}
                className="px-[20px] py-[12px] bg-[#e8e8e8] rounded-[7px] text-gray-500"
              >
                Pay Later
              </button>
              <button
                onClick={() => {
                  if (totalPricing > 0) {
                    handlePayment();
                  }
                }}
                className="px-[20px] py-[12px] bg-green-500 rounded-[7px] text-white"
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </Layout>
  );
};

export default AdminNewOrder;
