import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  folderUrl: null,
  currentPath: [],
};
const folderSystemSlice = createSlice({
  name: "folderSystemSlice",
  initialState,
  reducers: {
    setFolderUrl: (state, action) => {
      state.folderUrl = action.payload;
    },
    setCurrentPath: (state, action) => {
      state.currentPath = action.payload;
      localStorage.setItem("folder-path", JSON.stringify(action.payload))
    },
  },
});

export const { setFolderUrl, setCurrentPath } = folderSystemSlice.actions;

export default folderSystemSlice.reducer;
