import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  customerSidebar: "",
  adminSidebar: "",
  editorSidebar: "",
};

const sidebarSlice = createSlice({
  name: "sidebarSlice",
  initialState,
  reducers: {
    setCustomerSidebar: (state, action) => {
      state.customerSidebar = action.payload;
    },
    setAdminSidebar: (state, action) => {
      state.adminSidebar = action.payload;
    },
    setEditorSidebar: (state, action) => {
      state.editorSidebar = action.payload;
    },
  },
});

export const { setCustomerSidebar, setAdminSidebar, setEditorSidebar } =
  sidebarSlice.actions;
export default sidebarSlice.reducer;
