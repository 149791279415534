import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/customer/logo.png";
import {
  FaArrowRightFromBracket,
  FaCirclePlus,
  FaRegCircle,
  FaRegFile,
  FaRegUser,
  FaUserGroup,
  FaWrench,
} from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { setEditorSidebar } from "../../../redux/reducer/sidebarSlice";
import { FaChartBar } from "react-icons/fa";
const Sidebar = () => {
  const editorSidebar = useSelector(
    (state) => state.sidebarSlice.editorSidebar
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();

    navigate("/editor/login");
  };
  return (
    <div className="w-full h-full text-[12px] flex flex-col p-[20px] items-center gap-[20px] justify-between bg-white">
      <div className="w-full   flex flex-col gap-[25px] items-center">
        <div className="w-full">
          <Link to={"/editor"}>
            <img loading="lazy" src={Logo} alt="logo" className="w-full cursor-pointer " />
          </Link>
        </div>
        <div className="flex flex-col w-full gap-[10px]">
          {/* <Link
            onClick={() => dispatch(setEditorSidebar("create-order"))}
            to={"#"}
            className={`w-full flex ${
              editorSidebar === "create-order"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaCirclePlus />
            View Order
          </Link> */}
          <Link
            onClick={() => dispatch(setEditorSidebar("order-list"))}
            to={"/editor/order-list"}
            className={`w-full flex ${
              editorSidebar === "order-list"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaRegCircle />
            Order List
          </Link>

          <Link
            onClick={() => dispatch(setEditorSidebar("customer-info"))}
            to={"/editor/user-list"}
            className={`w-full flex ${
              editorSidebar === "customer-info"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaUserGroup />
            Customer Info
          </Link>
          {/* <Link
            onClick={() => dispatch(setEditorSidebar("order-detail"))}
            to={"/order-detail"}
            className={`w-full flex ${
              editorSidebar === "order-detail"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaRegFile />
            Order Detail
          </Link> */}

          <Link
            onClick={() => dispatch(setEditorSidebar("settings"))}
            to={"/editor/settings"}
            className={`w-full flex ${
              editorSidebar === "settings"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaWrench />
            Account
          </Link>
        </div>
      </div>
      <div className="w-full flex items-center justify-center">
        <button
          onClick={handleLogout}
          className=" flex  font-semibold bg-[#22d87c] text-white items-center gap-[7px] px-[20px] py-[10px] rounded-[7px]"
        >
          <FaArrowRightFromBracket />
          <h3>Logout</h3>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
