import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  folderUrl: null,
  currentPath: [],
};
const editorFolderSystemSlice = createSlice({
  name: "editorFolderSystemSlice",
  initialState,
  reducers: {
    setFolderUrl: (state, action) => {
      state.folderUrl = action.payload;
      localStorage.setItem("editor-folder-url", JSON.stringify(action.payload));
    },
    setCurrentPath: (state, action) => {
      state.currentPath = action.payload;
      localStorage.setItem(
        "editor-folder-path",
        JSON.stringify(action.payload)
      );
    },
  },
});

export const { setFolderUrl, setCurrentPath } = editorFolderSystemSlice.actions;

export default editorFolderSystemSlice.reducer;
