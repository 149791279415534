import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import { useDispatch } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { FaFileExport, FaFilter, FaUpload, FaX } from "react-icons/fa6";
import { setAdminSidebar } from "../../../redux/reducer/sidebarSlice";

const AdminOrderStatus = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAdminSidebar("order-status"));
  }, [dispatch]);

  const [data, setData] = useState([
    {
      orderId: "00001",
      name: "Christine Brooks",
      address: "089 Kutch Green park",
      date: "04 Sep 2019",
      type: "Traditional",
      status: "Completed",
    },
    {
      orderId: "00002",
      name: "Christine Brooks",
      address: "089 Kutch Green park",
      date: "04 Sep 2019",
      type: "Traditional",
      status: "Processing",
    },
    {
      orderId: "00003",
      name: "Christine Brooks",
      address: "089 Kutch Green park",
      date: "04 Sep 2019",
      type: "Traditional",
      status: "Pending",
    },
    {
      orderId: "00004",
      name: "Christine Brooks",
      address: "089 Kutch Green park",
      date: "04 Sep 2019",
      type: "Traditional",
      status: "InTransit",
    },
    {
      orderId: "00005",
      name: "Christine Brooks",
      address: "089 Kutch Green park",
      date: "04 Sep 2019",
      type: "Traditional",
      status: "Onhold",
    },
  ]);

  const [filteredOrders, setFilteredOrders] = useState("all-orders");
  const [filteredData, setFilteredData] = useState(data);
  const [search, setSearch] = useState("");

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    let filter = data;

    if (filteredOrders !== "all-orders") {
      filter = data.filter(
        (item) => item.status.toLowerCase() === filteredOrders.toLowerCase()
      );
    }

    if (search) {
      filter = filter.filter(
        (item) =>
          item.orderId.toLowerCase().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.address.toLowerCase().includes(search.toLowerCase()) ||
          item.date.toLowerCase().includes(search.toLowerCase()) ||
          item.type.toLowerCase().includes(search.toLowerCase()) ||
          item.status.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFilteredData(filter);
  }, [filteredOrders, search, data]);

  return (
    <Layout>
      <div className="w-full flex text-[14px] flex-col gap-[12px]">
        <div className="w-full px-[20px] flex flex-col gap-[20px]">
          <div className="w-full text-gray-500  sm:border-b border-gray-200 pt-[20px] flex flex-wrap gap-[20px]  items-center sm:flex-nowrap sm:gap-0">
            <button
              onClick={() => setFilteredOrders("all-orders")}
              className={`px-[20px] border-b font-semibold ${
                filteredOrders === "all-orders"
                  ? "border-[#22d87c] text-[#22d87c]"
                  : "border-gray-200"
              }`}
            >
              All Orders
            </button>
            <button
              onClick={() => setFilteredOrders("pending")}
              className={`px-[20px] border-b font-semibold ${
                filteredOrders === "pending"
                  ? "border-[#22d87c] text-[#22d87c]"
                  : "border-gray-200"
              }`}
            >
              Pending
            </button>
            <button
              onClick={() => setFilteredOrders("delivered")}
              className={`px-[20px] border-b font-semibold ${
                filteredOrders === "delivered"
                  ? "border-[#22d87c] text-[#22d87c]"
                  : "border-gray-200"
              }`}
            >
              Delivered
            </button>
            <button
              onClick={() => setFilteredOrders("completed")}
              className={`px-[20px] border-b font-semibold ${
                filteredOrders === "completed"
                  ? "border-[#22d87c] text-[#22d87c]"
                  : "border-gray-200"
              }`}
            >
              Completed
            </button>
          </div>
          <div className="w-full bg-[#f6f6f6] p-[12px] rounded-[4px] flex flex-wrap sm:flex-nowrap gap-[12px] justify-between items-center">
            <div className="w-full sm:w-[30%] p-[7px] flex items-center bg-white gap-[7px]">
              <input
                type="text"
                name="search"
                id="search"
                onChange={handleChange}
                value={search}
                className="w-full text-[14px] bg-transparent outline-none "
                placeholder="Search here"
              />
              {search ? (
                <FaX
                  onClick={() => {
                    setSearch("");
                  }}
                  className="cursor-pointer"
                />
              ) : (
                <FaSearch className="cursor-pointer" />
              )}
            </div>
            <div className="flex items-center gap-[12px]">
              <div className="flex bg-white cursor-pointer p-[10px] rounded-[7px] text-[#A1AEBE] font-semibold gap-[7px] items-center">
                <FaFilter /> Filter
              </div>
              <div className="flex bg-[#22d87c] cursor-pointer p-[10px] rounded-[7px] text-white font-semibold gap-[7px] items-center">
                <FaUpload /> Export
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto hide-scrollbar  bg-white  rounded-[20px] shadow-sm p-[25px]">
          <table className="w-full">
            <thead>
              <tr className="border text-[12px] border-gray-200 rounded-t-[20px] bg-[#fcfdfd]">
                <th className="sm:p-[12px] px-[30px] text-center py-[12px]  sm:text-left">
                  ORDER ID
                </th>
                <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                  NAME
                </th>
                <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                  ADDRESS
                </th>
                <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                  DATE
                </th>
                <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                  TYPE
                </th>

                <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                  STATUS
                </th>
              </tr>
            </thead>
            <tbody className="w-full border border-gray-200  ">
              {filteredData.map((item, index) => (
                <tr
                  key={index}
                  className={`
                    ${
                      index % 2 === 1
                        ? "bg-[#fcfdfd]  text-[12px]"
                        : "bg-white text-[12px]"
                    } relative
                     `}
                >
                  <td className="p-[12px] text-left">{item?.orderId}</td>
                  <td className="p-[12px] text-left">{item?.name}</td>
                  <td className="p-[12px] text-left">{item?.address}</td>
                  <td className="p-[12px] text-left">{item?.date}</td>
                  <td className="p-[12px] text-left">{item?.type}</td>
                  <td className="p-[12px] text-left">
                    <button
                      className={`${
                        item?.status === "Completed"
                          ? "bg-[#ccf0eb] text-[#00B69B]"
                          : item?.status === "Pending"
                          ? "bg-[#fcd7d4] text-[#EF3826]"
                          : item?.status === "Processing"
                          ? "bg-[#e0d4fc] text-[#6226EF]"
                          : item?.status === "Onhold"
                          ? "bg-[#ffeddd] text-[#FFA756]"
                          : item?.status === "InTransit"
                          ? "bg-[#f1d4ff] text-[#BA29FF]"
                          : null
                      } font-medium px-[18px] py-[7px] rounded-[7px]`}
                    >
                      {item?.status}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default AdminOrderStatus;
