import React, { useEffect, useRef, useState } from "react";
import Layout from "../layout/layout";
import {
  FaAngleDown,
  FaCheck,
  FaCircleCheck,
  FaClock,
  FaListCheck,
  FaPencil,
  FaPlus,
  FaRegCircle,
  FaRegFile,
  FaRightLong,
  FaRocket,
  FaTelegram,
  FaUpload,
} from "react-icons/fa6";
import ProfilePic from "../../../assets/admin/profile1.jpg";
import Img from "../../../assets/admin/img.webp";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerSidebar } from "../../../redux/reducer/sidebarSlice";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  pushPrivateNoteData,
  setClientInformation,
  setFuneralDetails,
  setNotes,
  setOrderDetails,
  setPrivateNotesData,
  setPrivateNotesValue,
  setPushedNotes,
  setSelectedImg,
  setSelectedMusic,
  setSelectedVideo,
  setTimeLine,
  setType,
  setUploadPhoto,
} from "../../../redux/reducer/newOrderEditorSlice";
import moment from "moment";
import { FaRegEdit, FaUserEdit } from "react-icons/fa";

import { pdfjs, Document, Page } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const EditorDeliveryPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = localStorage.getItem("editorToken");
  const timeline = useSelector((state) => state.newOrderEditorSlice.timeLine);
  const privateNotesData = useSelector(
    (state) => state.newOrderEditorSlice.privateNotesData
  );
  const privateNotesValue = useSelector(
    (state) => state.newOrderEditorSlice.privateNotesValue
  );

  const handlePrivateNoteChange = (e) => {
    dispatch(setPrivateNotesValue(e.target.value));
  };
  const editorId = useSelector((state) => state.editorInfoSlice.editor?.userid);
  const order = useSelector((state) => state.newOrderEditorSlice);
  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(order?.orderDetails.requestedDate)
  );
  const [note, setNote] = useState("");
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(order?.orderDetails.requestedDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [order?.orderDetails.requestedDate]);

  function calculateTimeLeft(target) {
    if (!target) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const now = moment();
    const endOfTargetDate = moment(target).endOf("day");
    const duration = moment.duration(endOfTargetDate.diff(now));

    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  }
  const sumbitNote = async () => {
    await axios({
      url: `https://funeral-api.hitoritech.co.uk/api/v1/admin/add-notes`,
      method: "PUT",
      data: {
        id: id,
        note: note,
        type: "editor",
        senderid: editorId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch(
          setPushedNotes({
            id: id,
            note: note,
            type: "editor",
            senderid: editorId,
          })
        );
        setNote("");
      })
      .catch((err) => {});
  };

  const getNotes = async () => {
    await axios({
      url: `https://funeral-api.hitoritech.co.uk/api/v1/editor/get-notes/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch(setNotes(res.data.Data));
      })
      .catch((err) => {});
  };

  const submitPrivateNote = async () => {
    await axios({
      url: `https://funeral-api.hitoritech.co.uk/api/v1/editor/add-private-notes`,
      method: "PUT",
      data: {
        id: id,
        note: privateNotesValue,
        type: "editor",
        senderid: editorId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch(
          pushPrivateNoteData({
            id: id,
            note: privateNotesValue,
            type: "editor",
            senderid: editorId,
          })
        );
        dispatch(setPrivateNotesValue(""));
      })
      .catch((err) => {});
  };

  const getPrivateNotes = async () => {
    await axios({
      url: `https://funeral-api.hitoritech.co.uk/api/v1/editor/get-private-notes/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch(setPrivateNotesData(res.data.Data));
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getNotes();
    getPrivateNotes();
    axios({
      method: "GET",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/editor/get-order/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        let data = res?.data?.Data;
        // dispatch(setVideos(data?.totalscenery || []));
        // dispatch(setImg(data?.totalcoverphotos || []));
        dispatch(
          setClientInformation({
            businessName: data?.businessName,
            contactName: data?.contactName,
            email: data?.email,
            phone: data?.phone,
            address: data?.address,
            city: data?.city,
            state: data?.state,
            postalCode: data?.postalCode,
          })
        );
        dispatch(
          setOrderDetails({
            orderCreationDate: data?.orderCreationDate,
            requestedDate: data?.requestedDate,
            orderNumber: data?.orderNumber,
            styleOption: data?.styleOption,
            videoType: data?.videoType,
            productionType: data?.productionType,
          })
        );
        dispatch(
          setFuneralDetails({
            subjectName: data?.subjectName,
            dateOfBirth: data?.dateOfBirth,
            dateOfDeath: data?.dateOfDeath,
            funeralDate: data?.funeralDate,
            funeralTime: data?.funeralTime,
          })
        );
        dispatch(setUploadPhoto(data?.photo));
        dispatch(setType(data?.type));
        dispatch(setTimeLine(data?.timeline));
        dispatch(
          setSelectedImg(
            data?.coverPhotos?.map((item) => {
              return JSON.parse(item);
            })
          )
        );
        dispatch(
          setSelectedVideo(
            data?.scenery?.map((item) => {
              return JSON.parse(item);
            })
          )
        );
        dispatch(
          setSelectedMusic(
            data?.music?.map((item) => {
              return JSON.parse(item);
            })
          )
        );
      })
      .catch((err) => {});

    return () => {
      dispatch(
        setClientInformation({
          businessName: "",
          contactName: "",
          email: "",
          phone: "",
          address: "",
          city: "",
          state: "",
          postalCode: "",
        })
      );
      dispatch(
        setOrderDetails({
          orderCreationDate: "",
          requestedDate: "",
          orderNumber: "",
          styleOption: "",
          videoType: "",
          productionType: "",
        })
      );
      dispatch(
        setFuneralDetails({
          subjectName: "",
          dateOfBirth: "",
          dateOfDeath: "",
          funeralDate: "",
          funeralTime: "",
        })
      );
      dispatch(setSelectedVideo([]));
      dispatch(setSelectedMusic([]));
    };
  }, []);

  useEffect(() => {
    dispatch(setCustomerSidebar("delivery"));
  }, []);

  const [addPrivateNote, setAddPrivateNote] = useState(false);
  const handleAddPrivateNote = () => {
    setAddPrivateNote(!addPrivateNote);
  };
  const scroll = useRef();
  useEffect(() => {
    scroll.current?.scrollIntoView({ behaviour: "smooth" });
  }, [privateNotesData, order.notes]);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <Layout>
      <div className="w-full flex flex-col p-[20px] text-[14px] gap-[20px] h-full overflow-y-scroll hide-scrollbar bg-[#f5f5f5]">
        <div className="w-full flex flex-col xl:flex-row gap-[40px]">
          <div className="w-full xl:w-[70%] flex flex-col gap-[20px]">
            <div className="w-full p-[20px] xl:p-[35px] border-l-[5px] rounded-[7px] border-[#22d87c] flex flex-col gap-[4px] bg-white shadow-lg">
              <h1 className="text-[22px] font-bold text-[#535353]">
                Order Started
              </h1>
              <p className="text-[16px] text-[#696969] font-semibold">
                sent all the information you need so you can start working on
                this order. You got this!
              </p>
            </div>
            <div className="w-full  py-[30px] rounded-[7px] flex flex-col items-start  bg-white shadow-lg">
              <div className="pl-[50px] pr-[20px] rounded-r-[30px] py-[7px] text-[12px] bg-[#f2f2f2] text-[#838383] font-semibold">
                {moment(timeline[0]?.time).fromNow()}
              </div>

              {timeline?.map((item) => {
                return (
                  <div className="w-full flex flex-col gap-[12px] items-center border-b border-gray-200 px-[20px] py-[10px]">
                    <div className="w-full flex text-[11px] sm:text-[12px] flex-wrap  items-end gap-[7px] sm:gap-[12px]">
                      {item.status === "Requested-Date" ? (
                        <div className="w-[40px] text-[17px] h-[40px] bg-[#daf6e9] text-[#65a68b] rounded-[50px] flex items-center justify-center">
                          <FaClock />
                        </div>
                      ) : null}
                      {item.status === "Published" ? (
                        <div className="w-[40px] text-[17px] h-[40px] bg-[#daf6e9] text-[#65a68b] rounded-[50px] flex items-center justify-center">
                          <FaUpload />
                        </div>
                      ) : null}
                      {item.status === "Draft" ? (
                        <div className="w-[40px] text-[17px] h-[40px] bg-[#ddf2fe] text-[#86a2be] rounded-[50px] flex items-center justify-center">
                          {" "}
                          <FaRocket />
                        </div>
                      ) : null}
                      {item.status === "Editing" ? (
                        <div className="w-[40px] text-[17px] h-[40px] bg-[#ddf2fe] text-[#86a2be] rounded-[50px] flex items-center justify-center">
                          <FaPencil />
                        </div>
                      ) : null}
                      {item.status === "Completed" ? (
                        <div className="w-[40px] text-[17px] h-[40px] bg-[#ddf2fe] text-[#86a2be] rounded-[50px] flex items-center justify-center">
                          <FaCheck />
                        </div>
                      ) : null}

                      {item.status === "Re-edit-1" ? (
                        <div className="w-[40px] text-[17px] h-[40px] bg-[#ddf2fe] text-[#86a2be] rounded-[50px] flex items-center justify-center">
                          <FaRegEdit />
                        </div>
                      ) : null}

                      {item.status === "Re-edit-2" ? (
                        <div className="w-[40px] text-[17px] h-[40px] bg-[#ddf2fe] text-[#86a2be] rounded-[50px] flex items-center justify-center">
                          <FaUserEdit />
                        </div>
                      ) : null}
                      <p className="text-[#696a6c] font-semibold">
                        {item.message}
                      </p>
                      <p className="italic text-[#9f9f9f] text-[11px] font-semibold">
                        {moment(item.time).fromNow()}
                      </p>
                      {item?.status === "Completed" ? (
                        <div className="w-full">
                          {item?.proof?.path?.length !== 0 ? (
                            <div className="w-full flex items-center justify-start flex-wrap gap-[20px]">
                              {item?.proof?.path?.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="w-[25%] h-[200px]"
                                  >
                                    {item.includes(
                                      ".png" || ".jpeg" || ".svg" || ".jpg"
                                    ) ? (
                                      <img
                                        loading="lazy"
                                        src={item}
                                        alt="item"
                                        className="w-full h-full object-contain"
                                      />
                                    ) : item.includes(".mp4") ? (
                                      <video
                                        className="w-full h-full object-contain"
                                        src={item}
                                        autoPlay
                                        muted
                                        loop
                                      />
                                    ) : item?.includes(".mp3") ? (
                                      <audio src={item}></audio>
                                    ) : item?.includes(".pdf") ? (
                                      <div className="w-[423px] h-[423px]">
                                        <Document
                                          file={item}
                                          onLoadSuccess={onDocumentLoadSuccess}
                                          onLoadError={console.error}
                                          className="pdf-document"
                                        >
                                          <Page
                                            pageNumber={pageNumber}
                                            renderTextLayer={false}
                                            height={423}
                                          />
                                        </Document>
                                      </div>
                                    ) : null}
                                  </div>
                                );
                              })}
                              <p className="w-full">{item?.proof?.text}</p>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
              <div className="w-full flex flex-col gap-[12px] h-[500px] overflow-y-scroll hide-scrollbar">
                {order.notes.map((item, index) => {
                  return (
                    <div
                      key={index}
                      ref={index === order.notes.length - 1 ? scroll : null}
                      className="w-full  flex gap-[12px] items-center border-b border-gray-200 px-[20px] py-[10px]"
                    >
                      <div className="w-[40px] text-[17px] h-[40px] bg-[#daf6e9] text-[#65a68b] rounded-[50px] flex items-center justify-center">
                        <img
                          loading="lazy"
                          src={ProfilePic}
                          alt="img"
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <div className="w-full flex flex-col gap-[4px]">
                        <div className="flex text-[11px] sm:text-[12px] flex-wrap sm:flex-nowrap items-end gap-[7px] sm:gap-[12px]">
                          <p className="text-[#696a6c] font-semibold">
                            {item?.type === "customer"
                              ? "Customer Sent a Note"
                              : "You Sent a Note"}
                          </p>
                          <p className="italic text-[#9f9f9f] text-[11px] font-semibold">
                            {moment().fromNow(item.time)}
                          </p>
                        </div>
                        <p className="text-[#696a6c] text-[11px] sm:text-[12px] font-semibold">
                          {item.note}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="w-full xl:w-[30%] flex flex-col gap-[20px]">
            <div className="w-full p-[20px]  rounded-[7px]  flex flex-col gap-[12px] bg-white shadow-lg">
              <h2 className=" font-bold text-[#535353]">
                Time left to deliver
              </h2>
              <div className="w-full flex justify-center xl:justify-between gap-[15px] text-center ">
                <div className="flex flex-col items-center  border-r border-gray-300 pr-4">
                  <p className="font-bold text-[16px]">{timeLeft?.days}</p>
                  <p className="text-sm text-[#a9a9a9] font-semibold ">Days</p>
                </div>
                <div className="flex flex-col items-center  border-r border-gray-300 pr-4">
                  <p className="font-bold text-[16px]">{timeLeft?.hours}</p>
                  <p className="text-sm text-[#a9a9a9] font-semibold ">Hours</p>
                </div>
                <div className="flex flex-col items-center  border-r border-gray-300 pr-4">
                  <p className="font-bold text-[16px]">{timeLeft?.minutes}</p>
                  <p className="text-sm text-[#a9a9a9] font-semibold ">
                    Minutes
                  </p>
                </div>
                <div className="flex flex-col items-center ">
                  <p className="font-bold text-[16px]">{timeLeft?.seconds}</p>
                  <p className="text-sm text-[#a9a9a9] font-semibold ">
                    Seconds
                  </p>
                </div>
              </div>
              {/* <button className="bg-[#1dbf73] p-[10px] rounded-[4px] text-white font-semibold">
                Deliver Now
              </button> */}
            </div>
            <div className="w-full   rounded-[7px]  flex flex-col  bg-white shadow-lg">
              <div className="w-full border-b border-gray-200 px-[20px] py-[15px] flex flex-col gap-[12px]">
                <div className="w-full flex justify-between items-center">
                  <h2 className=" font-bold text-[#535353]">Order Detail</h2>
                  <FaAngleDown />
                </div>

                <div className="w-full flex items-center gap-[7px]">
                  <div className="w-[150px] h-[100px]">
                    <img
                      loading="lazy"
                      src={Img}
                      alt="img"
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <div className="flex flex-col items-start gap-[7px]">
                    {order?.funeralDetails.subjectName === "" ? (
                      <p>No Subject Given</p>
                    ) : (
                      <p>{order?.funeralDetails.subjectName} </p>
                    )}
                    {order?.type !== null ? (
                      <div className="px-[10px] py-[5px] rounded-[4px] text-white text-[9px] font-semibold bg-[#105d77]">
                        {order?.type}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {!localStorage.getItem("editorToken") ? null : (
              <div className="w-full p-[20px]  rounded-[7px]  flex flex-col gap-[12px] bg-white shadow-lg">
                <div className="w-full flex justify-between items-center">
                  <h2 className=" font-bold text-[#535353]">Private Note</h2>
                  <p
                    onClick={() => handleAddPrivateNote()}
                    className="font-semibold cursor-pointer text-[#1dbf73] flex items-center gap-[7px]"
                  >
                    <FaPlus /> Add Note
                  </p>
                </div>

                {privateNotesData !== null ? (
                  <div
                    ref={scroll}
                    className="w-full flex h-[350px] overflow-y-scroll hide-scrollbar flex-col gap-[12px]"
                  >
                    {privateNotesData?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          ref={
                            index === privateNotesData.length - 1
                              ? scroll
                              : null
                          }
                          className="w-full  flex gap-[12px] items-center border-b border-gray-200 px-[0px] py-[10px]"
                        >
                          <div className="w-[40px] text-[17px] h-[40px] bg-[#daf6e9] text-[#65a68b] rounded-[50px] flex items-center justify-center">
                            <img
                              loading="lazy"
                              src={ProfilePic}
                              alt="img"
                              className="w-full h-full object-contain"
                            />
                          </div>
                          <div className="w-full flex flex-col gap-[4px]">
                            <div className="flex text-[11px] sm:text-[12px] flex-wrap sm:flex-nowrap items-end gap-[7px] sm:gap-[12px]">
                              <p className="text-[#696a6c] font-semibold">
                                {item?.type === "editor"
                                  ? "You Sent a Note"
                                  : item?.type === "customer"
                                  ? "Customer Sent a Note"
                                  : item?.type === "admin"
                                  ? "Admin Sent a Note"
                                  : null}
                              </p>
                              <p className="italic text-[#9f9f9f] text-[11px] font-semibold">
                                {moment().fromNow(item.time)}
                              </p>
                            </div>
                            <p className="text-[#696a6c] text-[11px] sm:text-[12px] font-semibold">
                              {item.note}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p className="text-[#c8c8c8] font-semibold">
                    Only visible to you
                  </p>
                )}
                <div className="w-full flex items-center justify-center gap-[7px]">
                  <input
                    type="text"
                    name="privateNotesValue"
                    id="privateNotesValue"
                    onChange={handlePrivateNoteChange}
                    value={privateNotesValue}
                    className="w-full border border-black rounded-[7px] p-[7px] outline-none"
                  />
                  <button
                    onClick={submitPrivateNote}
                    className="p-[7px] text-[17px] bg-[#f7f7f7]"
                  >
                    <FaTelegram />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditorDeliveryPage;
