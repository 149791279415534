import React from "react";

const ProfilePageSkeleton = () => {
  return (
    <div className="w-full flex flex-col items-center gap-[20px]">
      <div className="w-full text-[14px] flex justify-start flex-wrap items-center gap-[20px]">
        <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
          <div className="w-full h-[20px] bg-gray-300 rounded animate-pulse"></div>
          <div className="w-full h-[40px] bg-gray-300 rounded animate-pulse"></div>
        </div>
        <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
          <div className="w-full h-[20px] bg-gray-300 rounded animate-pulse"></div>
          <div className="w-full h-[40px] bg-gray-300 rounded animate-pulse"></div>
        </div>
        <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
          <div className="w-full h-[20px] bg-gray-300 rounded animate-pulse"></div>
          <div className="w-full h-[40px] bg-gray-300 rounded animate-pulse"></div>
        </div>
        <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
          <div className="w-full h-[20px] bg-gray-300 rounded animate-pulse"></div>
          <div className="w-full h-[40px] bg-gray-300 rounded animate-pulse"></div>
        </div>
        <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
          <div className="w-full h-[20px] bg-gray-300 rounded animate-pulse"></div>
          <div className="w-full h-[40px] bg-gray-300 rounded animate-pulse"></div>
        </div>
        <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
          <div className="w-full h-[20px] bg-gray-300 rounded animate-pulse"></div>
          <div className="w-full h-[40px] bg-gray-300 rounded animate-pulse"></div>
        </div>
        <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
          <div className="w-full h-[20px] bg-gray-300 rounded animate-pulse"></div>
          <div className="w-full h-[40px] bg-gray-300 rounded animate-pulse"></div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-[7px]">
        <div className="w-full h-[20px] bg-gray-300 rounded animate-pulse"></div>
        <div className="w-full flex flex-wrap items-center justify-center gap-[15px]">
          {Array(3)
            .fill("")
            .map((_, index) => (
              <div
                key={index}
                className="p-[10px] bg-gray-300 rounded-[7px] animate-pulse h-[40px] w-[80px]"
              ></div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProfilePageSkeleton;
