import React from "react";
import Layout from "./layout";

const TableSkeleton = () => {
  return (
    <Layout>
      <div className="w-full flex h-screen flex-col gap-4 p-12">
        <div className="h-8 bg-gray-300 animate-pulse mb-4"></div>
        <div className="w-full overflow-x-auto bg-white h-[600px]">
          <table className="w-full">
            <thead>
              <tr className="border text-xs border-gray-200 rounded-t-md bg-gray-100">
                <th className="sm:p-3 px-8 text-center py-3 sm:text-left">
                  <div className="h-4 bg-gray-300 animate-pulse"></div>
                </th>
                <th className="sm:p-3 px-8 text-center py-3 sm:text-left">
                  <div className="h-4 bg-gray-300 animate-pulse"></div>
                </th>
                <th className="sm:p-3 px-8 text-center py-3 sm:text-left">
                  <div className="h-4 bg-gray-300 animate-pulse"></div>
                </th>
                <th className="sm:p-3 px-8 text-center py-3 sm:text-left">
                  <div className="h-4 bg-gray-300 animate-pulse"></div>
                </th>
                <th className="sm:p-3 px-8 text-center py-3 sm:text-left">
                  <div className="h-4 bg-gray-300 animate-pulse"></div>
                </th>
                <th className="sm:p-3 px-8 text-center py-3 sm:text-left">
                  <div className="h-4 bg-gray-300 animate-pulse"></div>
                </th>
              </tr>
            </thead>
            <tbody className="w-full border relative border-gray-200">
              {Array.from({ length: 10 }).map((_, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 1 ? "bg-gray-100" : "bg-white"
                  } relative`}
                >
                  <td className="p-3">
                    <div className="h-4 bg-gray-300 animate-pulse"></div>
                  </td>
                  <td className="p-3">
                    <div className="h-4 bg-gray-300 animate-pulse"></div>
                  </td>
                  <td className="p-3">
                    <div className="h-4 bg-gray-300 animate-pulse"></div>
                  </td>
                  <td className="p-3">
                    <div className="h-4 bg-gray-300 animate-pulse"></div>
                  </td>
                  <td className="p-3">
                    <div className="h-4 bg-gray-300 animate-pulse"></div>
                  </td>
                  <td className="p-3">
                    <div className="h-4 bg-gray-300 animate-pulse"></div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default TableSkeleton;
