import React from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { toast, ToastContainer } from "react-toastify";

const VideoDownloader = ({ videos }) => {
  const downloadVideosAsZip = async () => {
    if (!videos || videos.length === 0) {
      console.error("No video URLs provided.");
      return;
    }
    const toastId = toast.loading("Hold On A Second!");

    const zip = new JSZip();
    const fetchPromises = videos.map(async (video, index) => {
      const { url } = video;

      try {
        // Fetch the video data
        const response = await fetch(url);
        if (!response.ok) throw new Error("Network response was not ok");

        // Convert the response to a Blob
        const blob = await response.blob();

        // Generate a filename with index
        const filename = `video-${index + 1}-${url.split("/").pop()}`;

        // Add the video Blob to the ZIP
        zip.file(filename, blob);
      } catch (error) {
        console.error(`Failed to download video at index ${index}:`, error);
      }
    });

    // Wait for all fetch operations to complete
    await Promise.all(fetchPromises);

    // Generate the ZIP file and trigger download
    zip
      .generateAsync({ type: "blob" })
      .then((content) => {
        saveAs(content, "videos.zip");
        toast.update(toastId, {
          render: "Videos downloaded successfully",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        console.error("Failed to generate ZIP file:", error);
        toast.update(toastId, {
          render: "Failed to generate ZIP file",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  return (
    <div>
      <ToastContainer />

      <button
        className="px-[20px] py-[12px] rounded-[7px] bg-green-400 text-white"
        onClick={downloadVideosAsZip}
      >
        Download Videos as ZIP
      </button>
    </div>
  );
};

export default VideoDownloader;
