import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalPricing: 0, // "totalPrice": 50, /
  musicPricing: 0, // "musicPrice": 0, /
  uploadPricing: 0, // "imagePrice": 50,
  coverImgPricing: 0, // "coverImagePrice": 0 /
  sceneryPricing: 0, // "sceneryPrice": 0, /
  uploadVideoPricing: 0, // "videoPrice": 0, /
  uploadMusicPricing: 0,
};

const pricingSlice = createSlice({
  name: "pricingSlice",
  initialState,
  reducers: {
    setTotalPricing: (state) => {
      state.totalPricing =
        state.musicPricing +
        state.uploadPricing +
        state.coverImgPricing +
        state.sceneryPricing +
        state.uploadVideoPricing +
        state.uploadMusicPricing;
    },
    setMusicPricing: (state, action) => {
      state.musicPricing = action.payload;
      state.totalPricing =
        state.musicPricing +
        state.uploadPricing +
        state.coverImgPricing +
        state.sceneryPricing +
        state.uploadVideoPricing +
        state.uploadMusicPricing;
    },
    setUploadPricing: (state, action) => {
      state.uploadPricing = action.payload;
      state.totalPricing =
        state.musicPricing +
        state.uploadPricing +
        state.coverImgPricing +
        state.sceneryPricing +
        state.uploadVideoPricing +
        state.uploadMusicPricing;
    },
    setCoverImgPricing: (state, action) => {
      state.coverImgPricing = action.payload;
      state.totalPricing =
        state.musicPricing +
        state.uploadPricing +
        state.coverImgPricing +
        state.sceneryPricing +
        state.uploadVideoPricing +
        state.uploadMusicPricing;
    },
    setSceneryPricing: (state, action) => {
      state.sceneryPricing = action.payload;
      state.totalPricing =
        state.musicPricing +
        state.uploadPricing +
        state.coverImgPricing +
        state.sceneryPricing +
        state.uploadVideoPricing +
        state.uploadMusicPricing;
    },
    setUploadVideoPricing: (state, action) => {
      state.uploadVideoPricing = action.payload;
      state.totalPricing =
        state.musicPricing +
        state.uploadPricing +
        state.coverImgPricing +
        state.sceneryPricing +
        state.uploadVideoPricing +
        state.uploadMusicPricing;
    },
    setUploadMusicPricing: (state, action) => {
      state.uploadMusicPricing = action.payload;
      state.totalPricing =
        state.musicPricing +
        state.uploadPricing +
        state.coverImgPricing +
        state.sceneryPricing +
        state.uploadVideoPricing +
        state.uploadMusicPricing;
    },
    setOverallAmount: (state, action) => {
      const {
        totalPricing,
        musicPricing,
        uploadPricing,
        coverImgPricing,
        sceneryPricing,
        uploadVideoPricing,
        uploadMusicPricing,
      } = action.payload;
      state.totalPricing = totalPricing;
      state.musicPricing = musicPricing;
      state.uploadPricing = uploadPricing;
      state.coverImgPricing = coverImgPricing;
      state.sceneryPricing = sceneryPricing;
      state.uploadVideoPricing = uploadVideoPricing;
      state.uploadMusicPricing = uploadMusicPricing;
    },
  },
});

export const {
  setTotalPricing,
  setMusicPricing,
  setUploadPricing,
  setCoverImgPricing,
  setSceneryPricing,
  setUploadVideoPricing,
  setUploadMusicPricing,
  setOverallAmount,
} = pricingSlice.actions;
export default pricingSlice.reducer;
