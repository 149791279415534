import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import { FaEye, FaPlus, FaTrash, FaX } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import Img from "../../../assets/customer/new-order-page.png";
import { useDispatch } from "react-redux";
import { setAdminSidebar } from "../../../redux/reducer/sidebarSlice";
import axios from "axios";
import { showToastError, showToastSuccess } from "../../../utils/toastify";
import TableSkeleton from "../layout/tableSkeleton";
const AdminEditorList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAdminSidebar("editor-info"));
  }, []);
  const token = localStorage.getItem("adminToken");
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const handleModal = (index) => {
    setModal(index === modal ? null : index);
  };
  const [userId, setUserId] = useState("");
  const fetchAllEditors = () => {
    axios({
      method: "GET",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/get-all-editors",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setData(res?.data?.Data?.rows);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    fetchAllEditors();
  }, []);

  const [isAddButtonPressed, setIsAddButtonPressed] = useState(false);
  const [isChangePasswordChecked, setIsChangePasswordChecked] = useState(false);
  const handleIsChangePasswordChecked = () => {
    setIsChangePasswordChecked(!isChangePasswordChecked);
  };

  const [clientInformation, setClientInformation] = useState({
    businessName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    country: "",
    password: "",
  });

  const [languageValues, setLanguageValues] = useState("");
  const handleLanguageValues = (e) => {
    setLanguageValues(e.target.value);
  };
  const [languageData, setLanguageData] = useState([]);
  const handleAddLanguage = () => {
    const trimmedLanguage = languageValues?.trim();
    if (trimmedLanguage) {
      const data = [...languageData, trimmedLanguage];
      setLanguageData(data);
      setLanguageValues("");
    }
  };
  const handleDeleteLanguage = (index) => {
    const deletedItem = languageData?.filter((_, item) => item !== index);
    setLanguageData(deletedItem);
  };
  const handleClientInformation = (e) => {
    const { name, value } = e.target;
    setClientInformation({ ...clientInformation, [name]: value });
  };

  const [editIndex, setEditIndex] = useState(-1);
  const [showEdit, setShowEdit] = useState(false);

  const getUserDetailsById = (id) => {
    axios({
      method: "GET",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/admin/get-editor-by-id/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        let data = res?.data?.Data;
        setClientInformation({
          firstName: data?.name?.split(" ")[0],
          lastName: data?.name?.split(" ")[1],
          email: data?.email,
          country: data?.country,
          address: data?.address,
          phone: data?.phone,
          businessName: data?.bussinessName,
        });
        setLanguageData(data?.language || []);
      })
      .catch((err) => {});
  };
  const handleEdit = (index) => {
    setIsAddButtonPressed(true);

    setEditIndex(index);
  };

  const handleUpdate = () => {
    if (editIndex === -1) {
      return;
    }
    const updateItem = [...data];
    updateItem[editIndex] = {
      id: userId,
      bussinessName: clientInformation.businessName,
      firstName: clientInformation.firstName,
      lastName: clientInformation.lastName,
      email: clientInformation.email,
      phone: clientInformation.phone,
      address: clientInformation.address,
      country: clientInformation.country,
      language: languageData,

      // role: clientInformation.role,
    };
    setData(updateItem);
    handleUpdateUser(updateItem);
    setClientInformation({
      businessName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      country: "",
    });
    setLanguageValues("");
    setLanguageData([]);
  };

  const handleAddEditor = () => {
    axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/editor-register",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        name: `${clientInformation.firstName} ${clientInformation.lastName}`,
        email: clientInformation.email,
        phone: clientInformation.phone,
        address: clientInformation.address,
        country: clientInformation.country,
        language: languageData,
        password: clientInformation.password,
      },
    })
      .then((res) => {
        showToastSuccess(res?.data?.message);
        fetchAllEditors();
        // setIsAddButtonPressed(false);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };

  const [isViewBtnPressed, setIsViewBtnPressed] = useState(false);

  const reqBody = {
    id: userId,
    name: `${clientInformation.firstName} ${clientInformation.lastName}`,
    email: clientInformation.email,
    country: clientInformation.country,
    address: clientInformation.address,
    language: languageData,
    phone: clientInformation.phone,
  };
  const handleUpdateUser = () => {
    axios({
      method: "PUT",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/admin/update-editor-by-id`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: reqBody,
    })
      .then((res) => {
        setEditIndex(-1);
        setIsAddButtonPressed(false);
        fetchAllEditors();
      })
      .catch((err) => {});
  };

  const handleChangePassword = () => {
    axios({
      method: "PUT",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/admin/update-editor-password-by-id`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: userId,
        password: clientInformation?.password,
      },
    })
      .then((res) => {})
      .catch((err) => {});
  };

  const [deletedId, setDeletedId] = useState(null);
  const handleDelete = async () => {
    await axios({
      method: "DELETE",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/delete-editor",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: deletedId,
      },
    })
      .then((res) => {
        console.log(res);
        showToastSuccess(res?.data?.message);

        // Remove the deleted order from the current data
        setData((prevData) =>
          prevData.filter((order) => order.id !== deletedId)
        );

        setModal(false);
      })
      .catch((err) => {
        console.log(err);
        setModal(false);
        showToastError(err?.response?.data?.message);
      });
  };
  return (
    <div>
      {data?.length !== 0 ? (
        <Layout>
          {isAddButtonPressed === true ? (
            <div className="w-full flex flex-col items-center gap-[20px] p-[20px] ">
              <div className="w-full justify-center flex flex-col gap-[7px]   items-center">
                <h1 className="font-extrabold text-[36px] leading-[1.3em] text-center">
                  {editIndex === -1 ? "Add Editor*" : "Edit Editor*"}
                </h1>
                {/* <p className="text-[18px] text-[#555555]">
                  Would you like to be contacted when the order is complete
                </p> */}
              </div>

              <div className="w-full flex flex-col items-center gap-[20px]">
                <div className="w-full text-[14px] flex justify-start flex-wrap items-center gap-[20px]">
                  {/* <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                    <label htmlFor="businessName" className="font-semibold">
                      Business Name*
                    </label>
                    <input
                      type="text"
                      name="businessName"
                      id="businessName"
                      onChange={handleClientInformation}
                      value={clientInformation.businessName}
                      autoComplete="false"
                      className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                      onKeyPress={(e) => {
                        if (!/[a-z\s]/i.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div> */}
                  <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                    <label htmlFor="firstName" className="font-semibold">
                      First Name*
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      onChange={handleClientInformation}
                      value={clientInformation.firstName}
                      autoComplete="false"
                      className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                      onKeyPress={(e) => {
                        if (!/[a-z\s]/i.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                    <label htmlFor="lastName" className="font-semibold">
                      Last Name*
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      onChange={handleClientInformation}
                      value={clientInformation.lastName}
                      autoComplete="false"
                      className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                      onKeyPress={(e) => {
                        if (!/[a-z\s]/i.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                    <label htmlFor="email" className="font-semibold">
                      Email*
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={handleClientInformation}
                      value={clientInformation.email}
                      autoComplete="false"
                      className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                    />
                  </div>

                  <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                    <label htmlFor="phone" className="font-semibold">
                      Phone Number*
                    </label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      onChange={handleClientInformation}
                      value={clientInformation.phone}
                      autoComplete="false"
                      className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                      maxLength="10"
                      onKeyPress={(e) => {
                        if (!/^\d$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>

                  <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                    <label htmlFor="address" className="font-semibold">
                      Address*
                    </label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      onChange={handleClientInformation}
                      value={clientInformation.address}
                      autoComplete="false"
                      className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                      onKeyPress={(e) => {
                        if (!/[a-z\s]/i.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                    <label htmlFor="country" className="font-semibold">
                      Country*
                    </label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      onChange={handleClientInformation}
                      value={clientInformation.country}
                      autoComplete="false"
                      className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                      onKeyPress={(e) => {
                        if (!/[a-z\s]/i.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {editIndex === -1 ? (
                    <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                      <label htmlFor="password" className="font-semibold">
                        Password*
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        onChange={handleClientInformation}
                        value={clientInformation.password}
                        autoComplete="false"
                        className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                      />
                    </div>
                  ) : null}
                  <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                    <label htmlFor="languageValues" className="font-semibold">
                      Language*
                    </label>
                    <div className="w-full flex items-center gap-[12px]">
                      <input
                        type="text"
                        name="languageValues"
                        id="languageValues"
                        onChange={handleLanguageValues}
                        value={languageValues}
                        autoComplete="false"
                        className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                        onKeyPress={(e) => {
                          if (!/[a-z\s]/i.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <FaPlus
                        onClick={handleAddLanguage}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-wrap items-center justify-center gap-[15px]">
                  {languageData?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="p-[10px] bg-[#f5f5f4] text-black border border-black rounded-[7px] flex items-center gap-[7px]"
                      >
                        <p>{item}</p>
                        <FaX
                          onClick={() => handleDeleteLanguage(index)}
                          className="cursor-pointer"
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="w-full flex justify-center gap-[20px] items-center">
                  <button
                    onClick={() => {
                      setIsAddButtonPressed(false);
                      setClientInformation({
                        businessName: "",
                        firstName: "",
                        lastName: "",
                        email: "",
                        phone: "",
                        address: "",
                        country: "",
                      });
                      setLanguageValues("");
                      setLanguageData([]);
                      setEditIndex(-1);
                    }}
                    className="bg-red-500 text-white px-[20px] py-[10px] rounded-[7px]"
                  >
                    Cancel
                  </button>
                  {editIndex === -1 ? (
                    <button
                      onClick={handleAddEditor}
                      className="bg-[#22d87c] text-white px-[20px] py-[10px] rounded-[7px]"
                    >
                      SAVE
                    </button>
                  ) : (
                    <button
                      onClick={handleUpdate}
                      className="bg-[#22d87c] text-white px-[20px] py-[10px] rounded-[7px]"
                    >
                      Update
                    </button>
                  )}
                </div>
                {editIndex === -1 ? null : (
                  <div className="w-full flex items-center justify-start">
                    <button
                      onClick={handleIsChangePasswordChecked}
                      className="flex items-center text-[16px] p-[7px] bg-[#e8e8e8] rounded-[7px] gap-[7px]"
                    >
                      <p>Change Password</p>
                      {isChangePasswordChecked === true ? (
                        <FaX
                          className="cursor-pointer text-[14px]"
                          onClick={() => setIsChangePasswordChecked(false)}
                        />
                      ) : (
                        <FaPlus
                          onClick={() => handleIsChangePasswordChecked(true)}
                          className="cursor-pointer"
                        />
                      )}
                    </button>
                  </div>
                )}
              </div>
              {isChangePasswordChecked === true ? (
                <div className="w-full flex flex-col items-center gap-[20px]">
                  <div className="w-full flex flex-col gap-[4px]">
                    <h3 className="font-bold w-full">Change Password*</h3>
                    {/* <p>
                      Would You like to be contacted when the order is complete:
                    </p> */}
                  </div>
                  <div className="w-full text-[14px] flex justify-start flex-wrap items-center gap-[20px]">
                    <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                      <label htmlFor="password" className="font-semibold">
                        Password*
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        onChange={handleClientInformation}
                        value={clientInformation?.password}
                        autoComplete="false"
                        className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                      />
                    </div>
                  </div>
                  <div className="w-full flex justify-center gap-[20px] items-center">
                    <button
                      onClick={() => {
                        setIsChangePasswordChecked(false);
                        setClientInformation({
                          businessName: "",
                          firstName: "",
                          lastName: "",
                          email: "",
                          phone: "",
                          address: "",
                          country: "",
                        });
                        setLanguageValues("");
                        setLanguageData([]);

                        setEditIndex(-1);
                      }}
                      className="bg-red-500 text-white px-[20px] py-[10px] rounded-[7px]"
                    >
                      Cancel
                    </button>

                    <button
                      onClick={() => handleChangePassword()}
                      className="bg-[#22d87c] text-white px-[20px] py-[10px] rounded-[7px]"
                    >
                      Update
                    </button>
                  </div>
                </div>
              ) : null}

              <img loading="lazy" src={Img} alt="img" className="" />
            </div>
          ) : (
            <div className="w-full flex flex-col text-[14px] items-center gap-[20px] p-[20px] ">
              <div className="w-full justify-center flex flex-col gap-[7px]   items-center">
                <h1 className="font-extrabold text-[36px] leading-[1.3em] text-center">
                  Editor List*
                </h1>
                {/* <p className="text-[18px] text-[#555555]">
                  Would you like to be contacted when the order is complete
                </p> */}
              </div>
              <div className="w-full flex justify-end">
                <button
                  onClick={() => setIsAddButtonPressed(true)}
                  className="flex items-center gap-[7px] bg-[#22d87c] text-white font-semibold py-[7px] px-[12px] rounded-[4px]"
                >
                  <FaPlus /> Add
                </button>
              </div>
              <div className="w-full overflow-x-auto hide-scrollbar  bg-white   h-[400px]   ">
                <table className="w-full">
                  <thead>
                    <tr className="border text-[12px] border-gray-200 rounded-t-[20px] bg-[#fcfdfd]">
                      <th className="sm:p-[12px] px-[30px] text-center py-[12px]  sm:text-left">
                        USER NAME
                      </th>
                      {/* <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                        BUSINESS NAME
                      </th> */}

                      <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                        EMAIL
                      </th>
                      <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                        PHONE
                      </th>
                      <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  <tbody className="w-full border border-gray-200  ">
                    {data.map((item, index) => (
                      <tr
                        key={index}
                        className={`
                  ${
                    index % 2 === 1
                      ? "bg-[#fcfdfd]  text-[12px]"
                      : "bg-white text-[12px]"
                  } relative
                   `}
                      >
                        <td className="p-[12px] text-left">{item?.name}</td>
                        {/* <td className="p-[12px] text-left">
                          {item?.bussinessName}
                        </td> */}
                        <td className="p-[12px] text-left">{item?.email}</td>
                        <td className="p-[12px] text-left">{item?.phone}</td>
                        <td className="p-[12px] text-left flex  gap-[7px] items-center">
                          <FaEdit
                            onClick={() => {
                              setUserId(item?.id);
                              handleEdit(index);
                              getUserDetailsById(item?.id);
                            }}
                            className="cursor-pointer"
                          />
                          <FaEye
                            onClick={() => {
                              getUserDetailsById(item?.id);
                              setIsViewBtnPressed(true);
                              handleEdit(index);
                            }}
                            className="cursor-pointer"
                          />
                          <FaTrash
                            onClick={() => {
                              handleModal("deleteItem");
                              setDeletedId(item?.id);
                            }}
                            className="cursor-pointer"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <img loading="lazy" src={Img} alt="img" className="" />
            </div>
          )}
          {isViewBtnPressed === true ? (
            <div className="w-full h-full  backdrop-filter backdrop-blur-sm bg-white/30 justify-center  absolute left-0 top-0 flex flex-col items-center gap-[20px] p-[20px] ">
              <div className="w-full sm:w-[80%] h-[90%] overflow-y-scroll rounded-[20px] hide-scrollbar bg-white shadow-xl p-[30px] flex flex-col gap-[20px]">
                <div className="w-full flex justify-end gap-[20px] items-center">
                  <button
                    onClick={() => {
                      setIsViewBtnPressed(false);
                      setIsAddButtonPressed(false);
                      setEditIndex(-1);
                      setClientInformation({
                        businessName: "",
                        firstName: "",
                        lastName: "",
                        email: "",
                        phone: "",
                        address: "",
                        country: "",
                      });
                      setLanguageValues("");
                      setLanguageData([]);
                    }}
                  >
                    <FaX />
                  </button>
                </div>
                <div className="w-full justify-center flex flex-col gap-[7px]   items-center">
                  <h1 className="font-extrabold text-[36px] leading-[1.3em] text-center">
                    View User
                  </h1>
                  {/* <p className="text-[18px] text-[#555555]">
                    Would you like to be contacted when the order is complete
                  </p> */}
                </div>

                <div className="w-full flex flex-col items-center gap-[20px]">
                  <div className="w-full text-[14px] flex justify-start flex-wrap items-center gap-[20px]">
                    {/* <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                      <label htmlFor="businessName" className="font-semibold">
                        Business Name*
                      </label>
                      <input
                        type="text"
                        name="businessName"
                        id="businessName"
                        onChange={handleClientInformation}
                        value={clientInformation.businessName}
                        autoComplete="false"
                        className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                        onKeyPress={(e) => {
                          if (!/[a-z\s]/i.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        readOnly
                      />
                    </div> */}
                    <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                      <label htmlFor="firstName" className="font-semibold">
                        First Name*
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        onChange={handleClientInformation}
                        value={clientInformation.firstName}
                        autoComplete="false"
                        className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                        onKeyPress={(e) => {
                          if (!/[a-z\s]/i.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        readOnly
                      />
                    </div>
                    <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                      <label htmlFor="lastName" className="font-semibold">
                        Last Name*
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        onChange={handleClientInformation}
                        value={clientInformation.lastName}
                        autoComplete="false"
                        className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                        onKeyPress={(e) => {
                          if (!/[a-z\s]/i.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        readOnly
                      />
                    </div>
                    <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                      <label htmlFor="email" className="font-semibold">
                        Email*
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        onChange={handleClientInformation}
                        value={clientInformation.email}
                        autoComplete="false"
                        className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                        readOnly
                      />
                    </div>

                    <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                      <label htmlFor="phone" className="font-semibold">
                        Phone Number*
                      </label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        onChange={handleClientInformation}
                        value={clientInformation.phone}
                        autoComplete="false"
                        className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                        maxLength="10"
                        onKeyPress={(e) => {
                          if (!/^\d$/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        readOnly
                      />
                    </div>

                    <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                      <label htmlFor="address" className="font-semibold">
                        Address*
                      </label>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        onChange={handleClientInformation}
                        value={clientInformation.address}
                        autoComplete="false"
                        className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                        onKeyPress={(e) => {
                          if (!/[a-z\s]/i.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        readOnly
                      />
                    </div>

                    <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                      <label htmlFor="country" className="font-semibold">
                        Country*
                      </label>
                      <input
                        type="text"
                        name="country"
                        id="country"
                        onChange={handleClientInformation}
                        value={clientInformation.country}
                        autoComplete="false"
                        className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                        onKeyPress={(e) => {
                          if (!/[a-z\s]/i.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-col gap-[7px]">
                    <h3 className="font-semibold">Languages*</h3>
                    <div className="w-full flex flex-wrap items-center justify-center gap-[15px]">
                      {languageData?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="p-[10px] bg-[#f5f5f4] text-black border border-black rounded-[7px] flex items-center gap-[7px]"
                          >
                            <p>{item}</p>
                            <FaX
                              onClick={() => handleDeleteLanguage(index)}
                              className="cursor-pointer"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {modal === "deleteItem" ? (
            <div className="w-full absolute top-0 z-[99] left-0 bg-black bg-opacity-[.5] h-full p-[20px] sm:p-[30px] flex flex-col justify-center items-center">
              <div className="w-full sm:w-[80%] xl:w-[50%] h-[300px] p-[20px] sm:p-[25px] rounded-[12px] bg-white flex flex-col gap-[15px] justify-center items-center">
                <h3 className="text-[20px] text-center font-semibold ">
                  Are you sure, You want to delete this Editor?
                </h3>
                <div className="w-full flex gap-[12px] justify-center items-center">
                  <button
                    onClick={() => {
                      setModal(false);
                    }}
                    className="px-[20px] py-[10px] rounded-[7px] font-semibold bg-[#e8e8e8]"
                  >
                    No
                  </button>
                  <button
                    onClick={() => {
                      handleDelete();
                    }}
                    className="px-[20px] py-[10px] text-white rounded-[7px] font-semibold bg-red-500 "
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </Layout>
      ) : (
        <TableSkeleton />
      )}
    </div>
  );
};

export default AdminEditorList;
