import React, { useState } from "react";
import loginImg from "../../../assets/customer/login-img.jpg";
import { Link } from "react-router-dom";
import axios from "axios";
import { showToastError, showToastSuccess } from "../../../utils/toastify";
import { ToastContainer } from "react-toastify";
const EditorRegister = () => {
  const [registerValues, setRegisterValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    businessName: "",
    address: "",
    password: "",
  });
  const [isChecked, setIsChecked] = useState(false);
  const handleIsChecked = () => {
    setIsChecked(!isChecked);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegisterValues({ ...registerValues, [name]: value });
  };

  const reqBody = {
    email: registerValues.email,
    password: registerValues.password,
    name: registerValues.firstName + " " + registerValues.lastName,
    phone: registerValues.phone,
    bussinessName: registerValues.businessName,
    address: registerValues.address,
  };

  const handleSubmit = () => {
    axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/editor-register",
      data: reqBody,
    })
      .then((res) => {
        showToastSuccess(res?.data?.message);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };

  return (
    <div className="w-full xl:h-screen overflow-y-scroll hide-scrollbar flex flex-col-reverse gap-[20px] xl:gap-0 xl:flex-row items-center">
      <ToastContainer />
      <div className="w-full xl:w-[50%] p-[30px] flex flex-col items-center">
        <div className="w-full sm:w-[90%] flex flex-col gap-[20px] items-center">
          <div className="flex w-full flex-col gap-[7px]">
            <h2 className="text-[#525252] font-bold text-[28px] sm:text-[32px] text-center leading-[1.3em]">
              Register your Account
            </h2>
            <p className="text-[16px] text-center text-[#525252]">
              Manage your projects and stay updated on all your projects.
            </p>
          </div>
          <div className="w-full text-[14px] text-[#828282] flex flex-col gap-[12px]">
            <div className="w-full flex items-center gap-[12px]">
              <div className="w-full sm:w-[50%] flex flex-col gap-[7px]">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  onChange={handleChange}
                  value={registerValues.firstName}
                  autoComplete="false"
                  className="p-[10px] w-full border border-[#DED2D9] rounded-[5px] outline-none"
                  placeholder="Example"
                  required
                />
              </div>
              <div className="w-full sm:w-[50%] flex flex-col gap-[7px]">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  onChange={handleChange}
                  value={registerValues.lastName}
                  autoComplete="false"
                  className="p-[10px] w-full border border-[#DED2D9] rounded-[5px] outline-none"
                  placeholder="Example"
                  required
                />
              </div>
            </div>
            <div className="w-full flex items-center gap-[12px]">
              <div className="w-full sm:w-[50%] flex flex-col gap-[7px]">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleChange}
                  value={registerValues.email}
                  autoComplete="false"
                  className="p-[10px] w-full border border-[#DED2D9] rounded-[5px] outline-none"
                  placeholder="mail@abc.com"
                  required
                />
              </div>
              <div className="w-full sm:w-[50%] flex flex-col gap-[7px]">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  onChange={handleChange}
                  value={registerValues.phone}
                  autoComplete="false"
                  className="p-[10px] w-full border border-[#DED2D9] rounded-[5px] outline-none"
                  placeholder="1234567890"
                  required
                  maxLength="10"
                  onKeyPress={(e) => {
                    if (!/^\d$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="w-full flex items-center gap-[12px]">
              <div className="w-full sm:w-[50%] flex flex-col gap-[7px]">
                <label htmlFor="businessName">Business Name</label>
                <input
                  type="text"
                  name="businessName"
                  id="businessName"
                  onChange={handleChange}
                  value={registerValues.businessName}
                  autoComplete="false"
                  className="p-[10px] w-full border border-[#DED2D9] rounded-[5px] outline-none"
                  placeholder="Example "
                  required
                />
              </div>
              <div className="w-full sm:w-[50%] flex flex-col gap-[7px]">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  onChange={handleChange}
                  value={registerValues.address}
                  autoComplete="false"
                  className="p-[10px] w-full border border-[#DED2D9] rounded-[5px] outline-none"
                  placeholder="Example "
                  required
                />
              </div>
            </div>

            <div className="flex flex-col gap-[7px]">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                onChange={handleChange}
                value={registerValues.password}
                autoComplete="false"
                className="p-[10px] w-full border border-[#DED2D9] rounded-[5px] outline-none"
              />
            </div>
            <div className="w-full flex justify-center">
              <button
                onClick={() => {
                  if (
                    registerValues?.firstName &&
                    registerValues?.lastName &&
                    registerValues?.email &&
                    registerValues?.phone &&
                    registerValues?.businessName &&
                    registerValues?.address &&
                    registerValues?.password
                  ) {
                    handleSubmit();
                  } else {
                    showToastError("Please fill the inputs!");
                  }
                }}
                className="w-[80%] bg-[#7F265B] text-white font-semibold text-[15px] p-[10px] rounded-[7px]"
              >
                Create Account
              </button>
            </div>
            <h3 className="text-[#828282] text-center text-[18px]">
              Try to be login?{" "}
              <Link
                to={"/editor/login"}
                className="text-[#73114B] font-semibold cursor-pointer"
              >
                Log in
              </Link>
            </h3>
          </div>
        </div>
      </div>
      <div className="w-full xl:w-[50%] p-[30px] xl:h-full justify-center items-center bg-[#FFE6C9] text-[#73114B] flex flex-col gap-[25px]">
        <img
          loading="lazy"
          src={loginImg}
          alt="loginImg"
          className="w-[70%] sm:w-[30%] xl:w-[60%]"
        />
        <div className="flex flex-col gap-[12px] items-center text-center ">
          <h1 className="font-extrabold text-[28px] sm:text-[35px] leading-[1.3em]">
            Create Meaningful Tributes Video.
          </h1>
          <p className="font-semibold text-[16px] sm:text-[20px]">
            Get Started with Personalized content tailored to your needs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EditorRegister;
