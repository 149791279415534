import React from "react";
import Layout from "./layout";

const DashboardSkeleton = () => {
  return (
    <Layout>
      <div className="w-full flex text-sm flex-col gap-10 p-5">
        <div className="h-6 bg-gray-300 animate-pulse w-1/3 mb-4"></div>
        <div className="w-full flex justify-center flex-wrap xl:flex-nowrap gap-5 items-center">
          {Array.from({ length: 4 }).map((_, index) => (
            <div
              key={index}
              className="p-5 w-full sm:w-2/5 xl:w-1/4 flex gap-5 items-center shadow-md rounded-md bg-white"
            >
              <div className="h-12 w-12 bg-gray-300 animate-pulse rounded-full"></div>
              <div className="flex flex-col gap-1 w-full">
                <div className="h-4 bg-gray-300 animate-pulse w-1/2"></div>
                <div className="h-3 bg-gray-300 animate-pulse w-1/3"></div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-8 flex-col xl:flex-row w-full flex gap-12">
          <div className="w-full xl:w-1/2 h-72 flex flex-col gap-3 bg-white p-5 shadow-md rounded-md">
            <div className="w-full flex items-center justify-between gap-5">
              <div className="h-4 bg-gray-300 animate-pulse w-1/4"></div>
              <div className="h-8 bg-gray-300 animate-pulse w-24"></div>
            </div>
            <div className="h-full bg-gray-100 animate-pulse rounded-md"></div>
          </div>
          <div className="w-full xl:w-1/2 h-72 flex flex-col gap-3 bg-white p-5 shadow-md rounded-md items-center">
            <div className="w-full flex items-center justify-between gap-5">
              <div className="h-4 bg-gray-300 animate-pulse w-1/4"></div>
              <div className="h-8 bg-gray-300 animate-pulse w-24"></div>
            </div>
            <div className="h-full bg-gray-100 animate-pulse rounded-md"></div>
          </div>
        </div>
        <div className="w-full mt-12 flex flex-col sm:flex-row items-center p-3 bg-gray-200 rounded-md gap-3 justify-center">
          {Array.from({ length: 4 }).map((_, index) => (
            <div
              key={index}
              className="w-full sm:w-auto px-5 py-3 rounded-md bg-gray-300 border border-gray-400 text-gray-600 font-semibold animate-pulse"
            ></div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default DashboardSkeleton;
