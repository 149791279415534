import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ types }) => {
  console.log(types);
  const defaultOrderByTime = {
    publish: 0, reEdit: 0, reEdit2: 0, draft: 0, editing: 0, completed: 0
  };

  const finalOrderByTime = { ...defaultOrderByTime, ...types };

  const labels = ["publish", "reEdit", "reEdit2", "draft", "editing", "completed"];
  const dataValues = labels.map((label) => finalOrderByTime[label]);

  const data = {
    labels,
    datasets: [
      {
        label: "Orders by Time",
        data: dataValues,
        backgroundColor: ["#22d87c", "#999fa6", "#ced1d4", "#a3a9ad", "#71b389"],
        borderColor: ["#22d87c", "#999fa6", "#ced1d4", "#a3a9ad", "#71b389"],
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut data={data} />;
};

export default DoughnutChart;
