import React, { useState } from "react";
import loginImg from "../../../assets/customer/login-img.jpg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { showToastError, showToastSuccess } from "../../../utils/toastify";
import { ToastContainer } from "react-toastify";
const CustomerForgotPassword = () => {
  const [email, setEmail] = useState("");
  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const reqBody = {
    email: email,
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/customer-reset-password",
      data: reqBody,
    })
      .then((res) => {
        showToastSuccess(res?.data?.message);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };

  return (
    <div className="w-full xl:h-screen overflow-y-scroll hide-scrollbar flex flex-col-reverse gap-[20px] xl:gap-0 xl:flex-row items-center">
      <ToastContainer />
      <div className="w-full xl:w-[50%] p-[30px] flex flex-col items-center">
        <div className="w-full sm:w-[70%] flex flex-col gap-[20px] items-center">
          <div className="flex w-full flex-col gap-[7px]">
            <h2 className="text-[#525252] font-bold text-[28px] sm:text-[32px] text-center leading-[1.3em]">
              Forgot Password
            </h2>
          </div>
          <div className="w-full text-[14px] text-[#828282] flex flex-col gap-[12px]">
            <div className="flex flex-col gap-[7px]">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={handleChange}
                value={email}
                autoComplete="false"
                className="p-[10px] border border-[#DED2D9] rounded-[5px] outline-none"
                placeholder="mail@abc.com"
              />
            </div>

            <button
              onClick={handleSubmit}
              className="bg-[#7F265B] text-white font-semibold text-[15px] p-[10px] rounded-[7px]"
            >
              Submit
            </button>
            <h3 className="text-[#828282] text-center text-[18px]">
              <Link
                to={"/customer/login"}
                className="text-[#73114B] font-semibold cursor-pointer"
              >
                Back to login
              </Link>
            </h3>
          </div>
        </div>
      </div>
      <div className="w-full xl:w-[50%] p-[30px] xl:h-full justify-center items-center bg-[#FFE6C9] text-[#73114B] flex flex-col gap-[25px]">
        <img
          loading="lazy"
          src={loginImg}
          alt="loginImg"
          className="w-[70%] sm:w-[30%] xl:w-[60%]"
        />
        <div className="flex flex-col gap-[12px] items-center text-center ">
          <h1 className="font-extrabold text-[28px] sm:text-[35px] leading-[1.3em]">
            Create Meaningful Tributes Video.
          </h1>
          <p className="font-semibold text-[16px] sm:text-[20px]">
            Get Started with Personalized content tailored to your needs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomerForgotPassword;
