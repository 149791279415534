import React from "react";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const PaymentSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-full bg-[#e8e8e8] p-[30px] flex flex-col justify-center items-center">
      <div className="w-full sm:w-[80%] xl:w-[50%] p-[30px] bg-white rounded-[12px] flex flex-col gap-[25px] items-center justify-center">
        <div className="w-[80px] h-[80px] text-[32px] sm:w-[100px] sm:text-[50px] font-medium text-green-500 sm:h-[100px] rounded-[50%] border-[5px] border-green-500 flex flex-col items-center justify-center">
          <FaCheck />
        </div>
        <p className="w-full xl:w-[80%] text-center font-medium text-[16px]">
          Your Transaction has been completed successfully. We have emailed you
          details of your order.
        </p>
        <button
          onClick={() => navigate("/customer")}
          className="px-[20px] py-[12px] rounded-[12px] bg-green-500 text-white font-semibold"
        >
          Back To Dashboard
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
