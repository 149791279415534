// import React from "react";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";
// import { toast, ToastContainer } from "react-toastify";
// import { showToastError, showToastSuccess } from "./toastify";

// // const MediaDownloader = ({ photos, videos, audios }) => {
// //   const downloadMediaAsZip = async () => {
// //     if (
// //       (!photos || photos.length === 0) &&
// //       (!videos || videos.length === 0) &&
// //       (!audios || audios.length === 0)
// //     ) {
// //       showToastError("No media URLs provided.");
// //       return;
// //     }

// //     const toastId = toast.loading("Hold On A Second!");
// //     const zip = new JSZip();

// //     // Fetch and add images to the ZIP
// //     const imagePromises = photos?.map(async (photo, index) => {
// //       const { url } = photo;
// //       try {
// //         const response = await fetch(url);
// //         if (!response.ok) throw new Error("Network response was not ok");
// //         const blob = await response.blob();
// //         const filename = `image-${index + 1}-${url.split("/").pop()}`;
// //         zip.file(filename, blob);
// //       } catch (error) {
// //         console.error(`Failed to download image at index ${index}:`, error);
// //       }
// //     });

// //     // Fetch and add videos to the ZIP
// //     const videoPromises = videos?.map(async (video, index) => {
// //       const { url } = video;
// //       try {
// //         const response = await fetch(url);
// //         if (!response.ok) throw new Error("Network response was not ok");
// //         const blob = await response.blob();
// //         const filename = `video-${index + 1}-${url.split("/").pop()}`;
// //         zip.file(filename, blob);
// //       } catch (error) {
// //         console.error(`Failed to download video at index ${index}:`, error);
// //       }
// //     });

// //     // Fetch and add audios to the ZIP
// //     const audioPromises = audios?.map(async (audio, index) => {
// //       const { url } = audio;
// //       try {
// //         const response = await fetch(url);
// //         if (!response.ok) throw new Error("Network response was not ok");
// //         const blob = await response.blob();
// //         const filename = `audio-${index + 1}-${url.split("/").pop()}`;
// //         zip.file(filename, blob);
// //       } catch (error) {
// //         console.error(`Failed to download audio at index ${index}:`, error);
// //       }
// //     });

// //     // Wait for all fetch operations to complete
// //     await Promise.all([...imagePromises, ...videoPromises, ...audioPromises]);

// //     // Generate the ZIP file and trigger download
// //     zip
// //       .generateAsync({ type: "blob" })
// //       .then((content) => {
// //         saveAs(content, "media.zip");
// //         toast.update(toastId, {
// //           render: "Media downloaded successfully",
// //           type: "success",
// //           isLoading: false,
// //           autoClose: 5000,
// //         });
// //       })
// //       .catch((error) => {
// //         console.error("Failed to generate ZIP file:", error);
// //         toast.update(toastId, {
// //           render: "Failed to generate ZIP file",
// //           type: "error",
// //           isLoading: false,
// //           autoClose: 5000,
// //         });
// //       });
// //   };

// const MediaDownloader = async ({
//   selectedImg = [],
//   selectedVideo = [],
//   musics = [],
//   uploadPhoto = [],
//   uploadVideo = [],
//   uploadAudio = [],
// }) => {
//   const downloadMediaAsZip = async () => {
//     if (
//       selectedImg.length === 0 &&
//       selectedVideo.length === 0 &&
//       musics.length === 0 &&
//       uploadPhoto.length === 0 &&
//       uploadVideo.length === 0 &&
//       uploadAudio.length === 0
//     ) {
//       showToastError("No media selected to download.");
//       return;
//     }

//     const zip = new JSZip();
//     const toastId = showToastSuccess("Preparing download...");

//     // Helper function to fetch and add files to ZIP
//     const fetchAndAddToZip = async (mediaArray, folderName, filePrefix) => {
//       const mediaPromises = mediaArray?.map(async (item, index) => {
//         const url = typeof item === "object" ? item.url : item; // For uploaded videos
//         try {
//           const response = await fetch(url);
//           if (!response.ok) throw new Error("Network response was not ok");
//           const blob = await response.blob();
//           const filename = `${filePrefix}-${index + 1}-${url.split("/").pop()}`;
//           zip.folder(folderName).file(filename, blob);
//         } catch (error) {
//           console.error(
//             `Failed to download ${filePrefix} at index ${index}:`,
//             error
//           );
//         }
//       });
//       await Promise.all(mediaPromises);
//     };

//     // Fetch and add images, videos, and audios to ZIP
//     await fetchAndAddToZip(selectedImg, "coverPhotos", "cover-photo");
//     await fetchAndAddToZip(selectedVideo, "sceneries", "scenery");
//     await fetchAndAddToZip(musics, "music", "audio");
//     await fetchAndAddToZip(uploadPhoto, "uploadedPhotos", "uploaded-photo");
//     await fetchAndAddToZip(uploadVideo, "uploadedVideos", "uploaded-video");
//     await fetchAndAddToZip(uploadAudio, "uploadedMusic", "uploaded-audio");

//     // Generate and download ZIP file
//     zip
//       .generateAsync({ type: "blob" })
//       .then((content) => {
//         saveAs(content, "media.zip");
//         showToastSuccess("Media downloaded successfully");
//       })
//       .catch((error) => {
//         showToastError("Failed to generate ZIP file.");
//         console.error("Failed to generate ZIP file:", error);
//       });
//   };
//   return (
//     <div>
//       <ToastContainer />
//       <button
//         className="px-[20px] py-[12px] rounded-[7px] bg-green-400 text-white"
//         onClick={downloadMediaAsZip}
//       >
//         Download Media as ZIP
//       </button>
//     </div>
//   );
// };

// export default MediaDownloader;

import React from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { toast, ToastContainer } from "react-toastify";
import { showToastError, showToastSuccess } from "./toastify";

const MediaDownloader = ({
  selectedImg = [],
  selectedVideo = [],
  musics = [],
  uploadPhoto = [],
  uploadVideo = [],
  uploadAudio = [],
}) => {
  const downloadMediaAsZip = async () => {
    if (
      selectedImg.length === 0 &&
      selectedVideo.length === 0 &&
      musics.length === 0 &&
      uploadPhoto.length === 0 &&
      uploadVideo.length === 0 &&
      uploadAudio.length === 0
    ) {
      showToastError("No media selected to download.");
      return;
    }

    const zip = new JSZip();
    const toastId = toast.loading("Preparing download...");

    // Helper function to fetch and add files to ZIP
    const fetchAndAddToZip = async (mediaArray, folderName, filePrefix) => {
      const mediaPromises = mediaArray?.map(async (item, index) => {
        const url = typeof item === "object" ? item.url : item; // For uploaded media
        try {
          const response = await fetch(url);
          if (!response.ok) throw new Error("Network response was not ok");
          const blob = await response.blob();
          const filename = `${filePrefix}-${index + 1}-${url.split("/").pop()}`;
          zip.folder(folderName).file(filename, blob);
        } catch (error) {
          console.error(
            `Failed to download ${filePrefix} at index ${index}:`,
            error
          );
        }
      });
      await Promise.all(mediaPromises);
    };

    // Fetch and add images, videos, and audios to ZIP
    await fetchAndAddToZip(selectedImg, "coverPhotos", "cover-photo");
    await fetchAndAddToZip(selectedVideo, "sceneries", "scenery");
    await fetchAndAddToZip(musics, "music", "audio");
    await fetchAndAddToZip(uploadPhoto, "uploadedPhotos", "uploaded-photo");
    await fetchAndAddToZip(uploadVideo, "uploadedVideos", "uploaded-video");
    await fetchAndAddToZip(uploadAudio, "uploadedMusic", "uploaded-audio");

    // Generate and download ZIP file
    zip
      .generateAsync({ type: "blob" })
      .then((content) => {
        saveAs(content, "media.zip");
        toast.update(toastId, {
          render: "Media downloaded successfully",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        console.error("Failed to generate ZIP file:", error);
        toast.update(toastId, {
          render: "Failed to generate ZIP file",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  return (
    <div>
      <ToastContainer />
      <button
        className="px-[20px] py-[12px] rounded-[7px] bg-green-400 text-white"
        onClick={downloadMediaAsZip}
      >
        Download Media as ZIP
      </button>
    </div>
  );
};

export default MediaDownloader;
