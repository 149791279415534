import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { setAdminSidebar } from "../../../redux/reducer/sidebarSlice";
import { IoIosWarning } from "react-icons/io";
import musicImg from "../../../assets/music.png";
import {
  FaAngleDown,
  FaCircleCheck,
  FaImage,
  FaLocationPin,
  FaMusic,
  FaPencil,
  FaPhone,
  FaRegImages,
  FaRegMessage,
  FaRegTrashCan,
  FaRegUser,
  FaTrash,
  FaVideo,
} from "react-icons/fa6";
import { RiExpandUpDownLine } from "react-icons/ri";
import { FaPaintBrush, FaShoppingBag } from "react-icons/fa";
import Header from "../components/header";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  setCoverImage,
  setImg,
  setMusic,
  setScenery,
  setVideos,
} from "../../../redux/reducer/newOrderAdminSlice";
import moment from "moment";
import {
  setCoverImgPricing,
  setMusicPricing,
  setOverallAmount,
  setSceneryPricing,
  setTotalPricing,
  setUploadPricing,
  setUploadVideoPricing,
} from "../../../redux/reducer/pricingSlice";
import ImageDownloader from "../../../utils/ImgDownload";
import VideoDownloader from "../../../utils/VideoDownload";
import MediaDownloader from "../../../utils/mediaDownloader";
const AdminOrderDetail = ({ deliveryPageOrderId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAdminSidebar("order-detail"));
  }, []);
  const scenery = useSelector((state) => state.newOrderAdminSlice.scenery);

  const coverImage = useSelector(
    (state) => state.newOrderAdminSlice.coverImage
  );

  const music = useSelector((state) => state.newOrderAdminSlice.music);
  const { orderId } = useParams();
  const token = localStorage.getItem("adminToken");
  const [orderDetails, setOrderDetails] = useState(null);
  const fetchOrderDetails = () => {
    axios({
      method: "GET",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/admin/get-order/${orderId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        let data = res?.data?.Data;
        setOrderDetails(data);
        dispatch(
          setOverallAmount({
            totalPricing: data?.pricingObj?.totalPrice,
            musicPricing: data?.pricingObj?.musicPrice,
            uploadPricing: data?.pricingObj?.imagePrice,
            coverImgPricing: data?.pricingObj?.coverImagePrice,
            sceneryPricing: data?.pricingObj?.sceneryPrice,
            uploadVideoPricing: data?.pricingObj?.videoPrice,
          })
        );
      })
      .catch((err) => {});
  };

  const fetchOrderDetailsDeliveryPage = () => {
    axios({
      method: "GET",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/admin/get-order-by-id/${deliveryPageOrderId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        let data = res?.data?.Data;
        dispatch(
          setOverallAmount({
            totalPricing: data?.pricingObj?.totalPrice,
            musicPricing: data?.pricingObj?.musicPrice,
            uploadPricing: data?.pricingObj?.imagePrice,
            coverImgPricing: data?.pricingObj?.coverImagePrice,
            sceneryPricing: data?.pricingObj?.sceneryPrice,
            uploadVideoPricing: data?.pricingObj?.videoPrice,
          })
        );

        // setOrderDetails(data);
        // dispatch(setTotalPricing(data?.pricingObj?.totalPrice || 0));
        // dispatch(setMusicPricing(data?.pricingObj?.musicPrice || 0));
        // dispatch(setUploadPricing(data?.pricingObj?.uploadPrice || 0));
        // dispatch(setCoverImgPricing(data?.pricingObj?.coverImagePrice || 0));
        // dispatch(setSceneryPricing(data?.pricingObj?.sceneryPrice || 0));
        // dispatch(
        //   setUploadVideoPricing(data?.pricingObj?.uploadVideoPrice || 0)
        // );
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (deliveryPageOrderId !== null && deliveryPageOrderId !== undefined) {
      fetchOrderDetailsDeliveryPage();
    } else {
      fetchOrderDetails();
    }
  }, []);
  const [data, setData] = useState(null);
  const handleFetchAdminData = () => {
    axios({
      method: "GET",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/get-admin-data",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setData(res?.data?.Data);
        dispatch(setCoverImage(res?.data?.Data?.coverImage) || []);
        dispatch(setMusic(res?.data?.Data?.music) || []);
        dispatch(setScenery(res?.data?.Data?.scenery) || []);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    handleFetchAdminData();
  }, []);
  const navigate = useNavigate();
  const musicId = orderDetails?.music?.map((item) => item?.id);
  const musicNotes = orderDetails?.music?.map((item) => item?.notes);
  const sceneryId = orderDetails?.scenery?.map((item) => item?.id);
  const sceneryNotes = orderDetails?.scenery?.map((item) => item?.notes);
  const coverPhotosId = orderDetails?.coverPhotos?.map((item) => item?.id);
  const coverPhotoNotes = orderDetails?.coverPhotos?.map((item) => item?.notes);

  const pricing = useSelector((state) => state.pricingSlice);
  return (
    <div
      className={`w-full  ${
        deliveryPageOrderId !== undefined && deliveryPageOrderId !== null
          ? "p-[0px]"
          : "xl:px-[50px] px-[20px] py-[20px]"
      } h-screen overflow-y-scroll hide-scrollbar  flex flex-col  `}
    >
      {/* <Header /> */}
      {deliveryPageOrderId !== null &&
      deliveryPageOrderId !== undefined ? null : (
        <div className="flex justify-start">
          <Link
            to={`/admin/new-order/${orderId}`}
            className="px-[20px] py-[12px] rounded-[12px] bg-gray-300 text-gray-500"
          >
            Back
          </Link>
        </div>
      )}
      <div className="p-[20px] text-[14px] flex flex-col gap-[15px]">
        <h1 className="text-[25px] font-extrabold leading-[1.3em]">
          Order Detail
        </h1>
        <div className="w-full flex flex-col xl:flex-row gap-[20px]">
          <div className="w-full xl:w-[65%] flex flex-col gap-[20px]">
            <div className="w-full text-[12px] p-[15px] border border-gray-200 rounded-[7px] flex flex-col gap-[12px]">
              <div className="flex items-center flex-wrap gap-[15px] sm:flex-nowrap  justify-between w-full">
                <h3 className="font-semibold text-[16px]">Products</h3>
                <div className="flex flex-wrap sm:flex-nowrap items-center gap-[20px]">
                  <p className="text-[#585a68]">
                    Last Updated at {moment(orderDetails?.updatedAt).fromNow()}
                  </p>
                </div>
              </div>

              <div className="w-full flex flex-col sm:flex-row gap-[20px] justify-between sm:items-center">
                <div className="w-full sm:w-auto flex flex-col gap-[15px]">
                  <div className="w-full flex flex-col gap-[12px]">
                    <p>Order Details</p>
                    <div className="w-full flex flex-wrap items-center justify-start gap-[15px]">
                      <div className="flex flex-col gap-[4px]">
                        <h3 className="w-full sm:w-auto font-semibold text-[15px]">
                          Business Name
                        </h3>
                        <p>{orderDetails?.businessName || "Not provided"}</p>
                      </div>
                      <div className="flex flex-col gap-[4px]">
                        <h3 className="w-full sm:w-auto font-semibold text-[15px]">
                          Contact Name
                        </h3>
                        <p>{orderDetails?.contactName || "Not provided"}</p>
                      </div>
                      <div className="flex flex-col gap-[4px]">
                        <h3 className="w-full sm:w-auto font-semibold text-[15px]">
                          Date of Birth
                        </h3>
                        <p>{orderDetails?.dateOfBirth || "Not provided"}</p>
                      </div>
                      <div className="flex flex-col gap-[4px]">
                        <h3 className="w-full sm:w-auto font-semibold text-[15px]">
                          Date of Death
                        </h3>
                        <p>{orderDetails?.dateOfDeath || "Not provided"}</p>
                      </div>
                      <div className="flex flex-col gap-[4px]">
                        <h3 className="w-full sm:w-auto font-semibold text-[15px]">
                          Funeral Date
                        </h3>
                        <p>{orderDetails?.funeralDate || "Not provided"}</p>
                      </div>
                      <div className="flex flex-col gap-[4px]">
                        <h3 className="w-full sm:w-auto font-semibold text-[15px]">
                          Funeral Time
                        </h3>
                        <p>{orderDetails?.funeralTime || "Not provided"}</p>
                      </div>
                      <div className="flex flex-col gap-[4px]">
                        <h3 className="w-full sm:w-auto font-semibold text-[15px]">
                          Date of Death
                        </h3>
                        <p>{orderDetails?.dateOfDeath || "Not provided"}</p>
                      </div>
                      <div className="flex flex-col gap-[4px]">
                        <h3 className="w-full sm:w-auto font-semibold text-[15px]">
                          Style Option
                        </h3>
                        <p>{orderDetails?.styleOption || "Not provided"}</p>
                      </div>
                      <div className="flex flex-col gap-[4px]">
                        <h3 className="w-full sm:w-auto font-semibold text-[15px]">
                          Subject Name
                        </h3>
                        <p>{orderDetails?.subjectName || "Not provided"}</p>
                      </div>
                      <div className="flex flex-col gap-[4px]">
                        <h3 className="w-full sm:w-auto font-semibold text-[15px]">
                          Production Type
                        </h3>
                        <p>{orderDetails?.productionType || "Not provided"}</p>
                      </div>
                    </div>
                  </div>

                  {orderDetails?.specialNotes !== null ? (
                    <div className="w-full flex flex-col gap-[4px]">
                      <h2 className="font-semibold text-[15px]">
                        Special Notes*
                      </h2>
                      <p>{orderDetails?.specialNotes}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {data?.hidePricing === true ? (
              <div className="w-full text-[12px]  border border-gray-200 rounded-[7px] flex flex-col ">
                <div className="p-[15px] w-full text-[14px] font-semibold flex flex-col gap-[7px]">
                  <div className=" flex items-center justify-between w-full">
                    <h3 className="font-semibold text-[16px]">Payment</h3>

                    <FaAngleDown />
                  </div>
                  <p className="text-[12px] font-normal">
                    Use this personalized guide to get your store up and
                    running.
                  </p>
                  <div className="w-full text-[#5e606e] flex flex-col sm:flex-row gap-[7px] justify-between items-center">
                    <p>Music Pricing</p>
                    <div className="  flex items-center justify-between">
                      <p>$ {pricing?.musicPricing}</p>
                    </div>
                  </div>
                  <div className="w-full text-[#5e606e] flex flex-col sm:flex-row gap-[7px] justify-between items-center">
                    <p>Image Upload Pricing</p>
                    <div className="  flex items-center justify-between">
                      <p>$ {pricing?.uploadPricing}</p>
                    </div>
                  </div>

                  <div className="w-full text-[#5e606e] flex flex-col sm:flex-row gap-[7px] justify-between items-center">
                    <p>Cover Image Pricing</p>
                    <div className="  flex items-center justify-between">
                      <p>$ {pricing?.coverImgPricing}</p>
                    </div>
                  </div>

                  <div className="w-full text-[#5e606e] flex flex-col sm:flex-row gap-[7px] justify-between items-center">
                    <p>Scenery Pricing</p>
                    <div className="  flex items-center justify-between">
                      <p>$ {pricing?.sceneryPricing}</p>
                    </div>
                  </div>

                  <div className="w-full text-[#5e606e] flex flex-col sm:flex-row gap-[7px] justify-between items-center">
                    <p>Upload Video Pricing</p>
                    <div className="  flex items-center justify-between">
                      <p>$ {pricing?.uploadVideoPricing}</p>
                    </div>
                  </div>

                  <div className="w-full text-[#5e606e] flex flex-col sm:flex-row gap-[7px] justify-between items-center">
                    <p>Total</p>
                    <div className="  flex items-center justify-between">
                      <p>$ {pricing?.totalPricing}</p>
                    </div>
                  </div>
                </div>

                {orderDetails?.paid === true ? (
                  <div className="w-full flex text-[14px] flex-col gap-[7px] border-t border-gray-200 p-[15px]">
                    <div className="w-full text-[#5e606e] flex justify-between items-center">
                      <div className=" flex items-center gap-[10px] w-full">
                        <FaCircleCheck className="text-green-600" />
                        <h3 className="font-semibold text-[16px]">
                          Payment Done
                        </h3>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {coverImage !== 0 && coverImage !== null ? (
              <div className="w-full text-[12px]  border border-gray-200 rounded-[7px] flex flex-col ">
                <div className="p-[15px] w-full text-[14px] font-semibold flex flex-col gap-[7px]">
                  <div className=" flex items-center gap-[10px] w-full">
                    <FaImage className="text-green-600" />
                    <h3 className="font-semibold text-[16px]">Cover Photos</h3>
                  </div>
                  <div className="w-full flex flex-wrap items-center gap-[20px] justify-center">
                    {coverImage
                      ?.filter((item, index) => coverPhotosId?.includes(index))
                      ?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="w-full sm:w-[30%] flex flex-col items-center gap-[10px]"
                          >
                            <p className="mb-[10px] font-bold tracking-[2px]">
                              {item?.split("/")[item?.split("/").length - 1]}
                            </p>
                            <div className="w-full h-[200px]">
                              <img
                                loading="lazy"
                                src={item}
                                alt="item"
                                className="w-full h-full object-cover"
                              />
                            </div>
                            <p> {coverPhotoNotes[index]}</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            ) : null}

            {orderDetails?.music?.length !== 0 &&
            orderDetails?.music !== null ? (
              <div className="w-full text-[12px]  border border-gray-200 rounded-[7px] flex flex-col ">
                <div className="p-[15px] w-full text-[14px] font-semibold flex flex-col gap-[7px]">
                  <div className=" flex items-center gap-[10px] w-full">
                    <FaMusic className="text-green-600" />
                    <h3 className="font-semibold text-[16px]">Music</h3>
                  </div>
                  <div className="w-full flex flex-wrap items-center gap-[20px] justify-center">
                    {music
                      ?.filter((item, index) => musicId?.includes(index))
                      ?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="w-[30%] h-auto flex flex-col items-center gap-[7px]"
                          >
                            <p className="mb-[10px] font-bold tracking-[2px]">
                              {item?.split("/")[item?.split("/").length - 1]}
                            </p>
                            <div className="relative w-full h-[200px]">
                              <img
                                src={musicImg}
                                alt="music"
                                className="w-full h-full object-contain absolute top-0 left-0"
                              />
                              <audio
                                src={item}
                                controls
                                className="w-full h-full"
                              />
                            </div>
                            <p> {musicNotes[index]}</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            ) : null}

            {orderDetails?.scenery?.length !== 0 &&
            orderDetails?.scenery !== null ? (
              <div className="w-full text-[12px]  border border-gray-200 rounded-[7px] flex flex-col ">
                <div className="p-[15px] w-full text-[14px] font-semibold flex flex-col gap-[7px]">
                  <div className=" flex items-center gap-[10px] w-full">
                    <FaPaintBrush className="text-green-600" />
                    <h3 className="font-semibold text-[16px]">Scenery</h3>
                  </div>
                  <div className="w-full flex flex-wrap items-center gap-[20px] justify-center">
                    {scenery
                      ?.filter((item, index) => sceneryId?.includes(index))
                      ?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="w-full sm:w-[30%] flex flex-col items-center gap-[10px]"
                          >
                            <p className="mb-[10px] font-bold tracking-[2px]">
                              {item?.split("/")[item?.split("/").length - 1]}
                            </p>
                            <div className="w-full h-[200px]">
                              <video
                                src={item}
                                autoPlay
                                muted
                                loop
                                className="w-full h-full object-contain"
                              />
                            </div>
                            <p> {sceneryNotes[index]}</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            ) : null}

            {orderDetails?.video?.length !== 0 &&
            orderDetails?.video !== null ? (
              <div className="w-full text-[12px]  border border-gray-200 rounded-[7px] flex flex-col ">
                <div className="p-[15px] w-full text-[14px] font-semibold flex flex-col gap-[7px]">
                  <div className=" flex justify-between items-center gap-[10px] w-full">
                    <div className="flex items-center gap-[7px]">
                      <FaVideo className="text-green-600" />
                      <h3 className="font-semibold text-[16px]">
                        Uploaded Videos
                      </h3>
                    </div>
                    {/* <VideoDownloader videos={orderDetails?.video} /> */}
                  </div>
                  <div className="w-full flex flex-wrap items-center gap-[20px] justify-center">
                    {orderDetails?.video?.map((item, index) => {
                      const parts = item?.url?.split("/");
                      const fileName = parts[parts.length - 1]; // This will get 'large.mp4'
                      console.log(fileName);

                      return (
                        <div
                          key={index}
                          className="w-full sm:w-[30%] flex flex-col items-center gap-[10px]"
                        >
                          <p className="mb-[10px] font-bold tracking-[2px]">
                            {fileName}
                          </p>
                          <div className="w-full h-[200px]">
                            <video
                              src={item?.url}
                              autoPlay
                              muted
                              loop
                              className="w-full h-full object-contain"
                            />
                          </div>
                          <p>{item?.comment}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}

            {orderDetails?.photo?.length !== 0 &&
            orderDetails?.photo !== null ? (
              <div className="w-full text-[12px]  border border-gray-200 rounded-[7px] flex flex-col ">
                <div className="p-[15px] w-full text-[14px] font-semibold flex flex-col gap-[7px]">
                  <div className=" flex justify-between items-center gap-[10px] w-full">
                    <div className="flex items-center gap-[7px]">
                      <FaRegImages className="text-green-600" />
                      <h3 className="font-semibold text-[16px]">
                        Uploaded Photos
                      </h3>
                    </div>
                    {/* <ImageDownloader photos={orderDetails?.photo} /> */}
                  </div>
                  <div className="w-full flex flex-wrap items-center gap-[20px] justify-center">
                    {orderDetails?.photo?.map((item, index) => {
                      // const parts = item?.url?.split("/");
                      // const fileName = parts[parts.length - 1];
                      const parts = item?.url?.split("/");
                      const fileName = parts[parts.length - 1];

                      // Extract the file name and extension separately
                      const fileNameParts = fileName.split(".");
                      const nameWithoutExtension = fileNameParts
                        .slice(0, -1)
                        .join(".");
                      const extension = fileNameParts[fileNameParts.length - 1];

                      // Truncate the name if it's longer than 10 characters (excluding the extension)
                      const truncatedName =
                        nameWithoutExtension.length > 10
                          ? nameWithoutExtension.substring(0, 15) + "..."
                          : nameWithoutExtension;

                      // Combine truncated name with the extension
                      const displayFileName = `${truncatedName}.${extension}`;
                      console.log(item?.url);

                      return (
                        <div
                          key={index}
                          className="w-full sm:w-[30%] flex flex-col items-center gap-[10px]"
                        >
                          <p className="mb-[10px] font-bold tracking-[2px]">
                            {displayFileName}
                          </p>
                          <div className="w-full h-[200px]">
                            <img
                              loading="lazy"
                              src={item?.url}
                              alt="item"
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <p>{item?.comment}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}

            {orderDetails?.uploadMusic?.length !== 0 &&
            orderDetails?.uploadMusic !== null ? (
              <div className="w-full text-[12px]  border border-gray-200 rounded-[7px] flex flex-col ">
                <div className="p-[15px] w-full text-[14px] font-semibold flex flex-col gap-[7px]">
                  <div className=" flex justify-between items-center gap-[10px] w-full">
                    <div className="flex items-center gap-[7px]">
                      <FaRegImages className="text-green-600" />
                      <h3 className="font-semibold text-[16px]">
                        Uploaded Music
                      </h3>
                    </div>
                    {/* <ImageDownloader photos={orderDetails?.uploadMusic} /> */}
                  </div>
                  <div className="w-full flex flex-wrap items-center gap-[20px] justify-center">
                    {orderDetails?.uploadMusic?.map((item, index) => {
                      const parts = item?.url?.split("/");
                      const fileName = parts[parts.length - 1];
                      return (
                        <div
                          key={index}
                          className="w-full sm:w-[30%] flex flex-col items-center gap-[10px]"
                        >
                          <p className="mb-[10px] font-bold tracking-[2px]">
                            {fileName}
                          </p>
                          <div className="relative w-full h-[200px]">
                            <img
                              src={musicImg}
                              alt="music"
                              className="w-full h-full object-contain absolute top-0 left-0"
                            />
                            <audio
                              src={item?.url}
                              controls
                              className="w-full h-full"
                            />
                          </div>
                          <p> {musicNotes[index]}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="w-full flex justify-center ">
              {/* <MediaDownloader
                photos={orderDetails?.photo}
                videos={orderDetails?.video}
                audios={orderDetails?.uploadMusic}
              /> */}
            </div>

            <MediaDownloader
              selectedImg={coverImage?.filter((item, index) =>
                coverPhotosId?.includes(index)
              )}
              selectedVideo={scenery?.filter((item, index) =>
                sceneryId?.includes(index)
              )}
              musics={music?.filter((item, index) => musicId?.includes(index))}
              uploadPhoto={orderDetails?.photo}
              uploadVideo={orderDetails?.video}
              uploadAudio={orderDetails?.uploadMusic}
            />
          </div>
          <div className="w-full xl:w-[35%] text-[12px] flex flex-col gap-[20px]">
            <div className="p-[15px] flex flex-col gap-[7px] rounded-[4px] border border-gray-200">
              <div className="w-full flex justify-between items-center">
                <h3 className="font-semibold text-[16px]">Note</h3>
              </div>
              <div className="flex items-center gap-[4px]">
                <p className="font-semibold">Order Id:</p>
                <p>{orderDetails?.id}</p>
              </div>
              <div className="flex items-center gap-[4px]">
                <p className="font-semibold">Customer Name:</p>
                <p>{orderDetails?.contactName}</p>
              </div>
            </div>
            {orderDetails?.quote?.text !== null ? (
              <div className="p-[15px] flex flex-col gap-[7px] rounded-[4px] border border-gray-200">
                <div className="w-full flex justify-between items-center">
                  <h3 className="font-semibold text-[16px]">Quote:</h3>
                </div>
                <div className="flex items-center gap-[4px]">
                  <p>{orderDetails?.quote?.text}</p>
                </div>
              </div>
            ) : null}
            <div className="p-[15px] flex flex-col gap-[12px] rounded-[4px] border border-gray-200">
              <div className="w-full flex justify-between items-center">
                <h3 className="font-semibold text-[16px]">
                  Contact Information
                </h3>
              </div>

              <div className="w-full flex flex-col gap-[7px]">
                <div className="flex items-center gap-[12px]">
                  <FaRegUser />
                  <p>{orderDetails?.contactName}</p>
                </div>

                <div className="flex items-center gap-[12px]">
                  <FaRegMessage />
                  <p>{orderDetails?.email}</p>
                </div>

                <div className="flex items-center gap-[12px]">
                  <FaPhone />
                  <p>{orderDetails?.phone}</p>
                </div>
                <div className="flex items-center gap-[12px]">
                  <FaShoppingBag />
                  <p>No Orders</p>
                </div>
              </div>
            </div>
            <div className="p-[15px] flex flex-col gap-[12px] rounded-[4px] border border-gray-200">
              <div className="w-full flex justify-between items-center">
                <h3 className="font-semibold text-[16px]">Shipping Address</h3>
              </div>

              <div className="w-full flex flex-col gap-[7px]">
                <div className="flex items-center gap-[12px]">
                  <FaLocationPin />
                  <p>
                    {orderDetails?.city} {orderDetails?.address}{" "}
                    {orderDetails?.state} {orderDetails?.postalCode}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOrderDetail;
