import React, { useCallback, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Layout from "../layout/layout";
import { canvasPreview } from "../common/cropImage";
import { BsFillCloudDownloadFill } from "react-icons/bs";
import { AiOutlineRotateRight } from "react-icons/ai";
import { FaScissors } from "react-icons/fa6";

const CropImage = () => {
  const [rotation, setRotation] = useState(0);
  const imgRef = useRef(null);
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [crop, setCrop] = useState(null);
  const [completeCrop, setCompleteCrop] = useState(null);
  const [img, setImg] = useState(null);
  const [scale, setScale] = useState(1);
  const [output, setOutput] = useState(null);
  const imageUrl = img?.url;

  const handleImageChange = (e) => {
    const file = e?.target?.files[0];
    const url = URL?.createObjectURL(file);
    setImg({
      file: file,
      url: url,
    });
  };

  const onImageLoad = (e) => {
    setHeight(e?.currentTarget?.height);
    setWidth(e?.currentTarget?.width);
    setCompleteCrop({
      x: 0,
      y: 0,
      height: e?.currentTarget?.height,
      width: e?.currentTarget?.width,
      unit: "px",
    });
  };

  const onZoom = (e) => {
    setScale(parseFloat(e));
  };

  const rotateRight = () => {
    let newRotation = rotation + 90;
    if (newRotation >= 360) {
      newRotation = 0;
    }
    setRotation(newRotation);
  };

  const download = async () => {
    const croppedImageBase64 = await canvasPreview(
      imgRef.current,
      completeCrop,
      scale,
      rotation
    );
    const link = document.createElement("a");
    link.href = croppedImageBase64;
    link.download = "cropped-image.jpg";
    link.click();
  };

  const previewBlob = async () => {
    const blobUrl = await canvasPreview(
      imgRef.current,
      completeCrop,
      scale,
      rotation
    );
    setOutput(blobUrl);
  };
  const [blobFile, setBlobFile] = useState(null);
  const blobToFile = (blob, fileName) => {
    const file = new File([blob], fileName, {
      type: blob.type,
      lastModified: Date.now(),
    });

    setBlobFile(file);
  };
  return (
    <Layout>
      <div className="flex flex-col p-[20px]">
        <label htmlFor="img">Upload Image</label>
        <input type="file" name="img" id="img" onChange={handleImageChange} />

        <ReactCrop
          src={imageUrl}
          crop={crop}
          onChange={(_, percentCrop) => {
            setCrop(percentCrop);
          }}
          onComplete={(e) => {
            if (e.height === 0 || e.width === 0) {
              setCompleteCrop({
                x: 0,
                y: 0,
                height: height,
                width: width,
                unit: "px",
              });
            } else {
              setCompleteCrop(e);
            }
          }}
        >
          {imageUrl && (
            <img
              loading="lazy"
              ref={imgRef}
              crossorigin="anonymous"
              alt="image"
              src={imageUrl}
              style={{ transform: `scale(${scale}) rotate(${rotation}deg)` }}
              onLoad={onImageLoad}
            />
          )}
        </ReactCrop>
        {output ? (
          <div className="w-full h-full bg-black bg-opacity-[.5] p-[30px] absolute left-0 top-0 flex flex-col gap-[15px] justify-center items-center">
            <div className="w-auto h-[400px]">
              <img
                loading="lazy"
                src={output}
                alt="output"
                className="w-full h-full object-contain"
              />
            </div>
            <div className="w-full flex items-center justify-center gap-[12px]">
              <button
                onClick={() => blobToFile(output, "file")}
                className="px-[20px] py-[7px] rounded-[4px] bg-[#22d87c] text-white"
              >
                Submit
              </button>
              <button
                onClick={() => {
                  setOutput("");
                }}
                className="px-[20px] py-[7px] rounded-[4px] bg-red-600 text-white"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : null}
        {imageUrl && (
          <div className="w-full flex justify-between items-center gap-[12px]">
            <div className="flex items-center gap-[15px]">
              <input
                type="range"
                min={0.1}
                max={3}
                step={0.05}
                value={scale}
                onInput={(e) => {
                  onZoom(e.target.value);
                }}
                className={"cursor-pointer"}
              ></input>
              <span className={"text-[16px] font-semibold"}>Zoom In/Out</span>
            </div>
            <div className={"flex items-center gap-[12px]"}>
              <BsFillCloudDownloadFill
                className={"text-[25px] cursor-pointer"}
                onClick={download}
              />
              <AiOutlineRotateRight
                className={"text-[25px] cursor-pointer"}
                onClick={rotateRight}
              />
              <FaScissors
                className={"text-[25px] cursor-pointer"}
                onClick={previewBlob}
              />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CropImage;
