import { configureStore } from "@reduxjs/toolkit";
import sidebarSlice from "./reducer/sidebarSlice";
import pricingSlice from "./reducer/pricingSlice";
import imageUploadSlice from "./reducer/imageUploadSlice";
import newOrderCustomerSlice from "./reducer/newOrderCustomerSlice";
import newOrderAdminSlice from "./reducer/newOrderAdminSlice";
import videoUploadSlice from "./reducer/videoUploadSlice";
import customerInfoSlice from "./reducer/customerInfoSlice";
import adminInfoSlice from "./reducer/adminInfoSlice";
import newOrderEditorSlice from "./reducer/newOrderEditorSlice";
import editorInfoSlice from "./reducer/editorInfoSlice";
import adminUserDetails from "./reducer/adminUserDetails";
import folderSystemSlice from "./reducer/folderSystemSlice";
import editorFolderSystemSlice from "./reducer/editorFolderSystemSlice";
import musicUploadSlice from "./reducer/musicUploadSlice";

const rootReducer = {
  sidebarSlice: sidebarSlice,
  pricingSlice: pricingSlice,
  imageUploadSlice: imageUploadSlice,
  newOrderCustomerSlice: newOrderCustomerSlice,
  newOrderAdminSlice: newOrderAdminSlice,
  videoUploadSlice: videoUploadSlice,
  customerInfoSlice: customerInfoSlice,
  adminInfoSlice: adminInfoSlice,
  newOrderEditorSlice: newOrderEditorSlice,
  editorInfoSlice: editorInfoSlice,
  adminUserDetails: adminUserDetails,
  folderSystemSlice: folderSystemSlice,
  editorFolderSystemSlice: editorFolderSystemSlice,
  musicUploadSlice: musicUploadSlice
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
