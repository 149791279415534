import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editor: null,
};

const editorInfoSlice = createSlice({
  name: "editorInfoSlice",
  initialState,
  reducers: {
    setEditorInfo: (state, action) => {
      state.editor = action.payload;
    },
  },
});

export const { setEditorInfo } = editorInfoSlice.actions;
export default editorInfoSlice.reducer;
