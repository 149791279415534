import React, { useState } from "react";
import Layout from "../layout/layout";
import Upi from "../../../assets/customer/upi.png";
import Venmo from "../../../assets/customer/venmo.png";
import Paypal from "../../../assets/customer/paypal.png";
import Paytm from "../../../assets/customer/paytm.png";
import Line from "../../../assets/customer/Line 3.png";
import Vector from "../../../assets/customer/Vector 3.png";
import FilePayment from "../../../assets/customer/file-lines-solid 1.png";
const Payment = () => {
  const [paymentValues, setPaymentValues] = useState({
    name: "",
    cardNumber: "",
    ccv: "",
    expiryDate: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentValues({ ...paymentValues, [name]: value });
  };
  return (
    <Layout>
      <div className="w-full p-[30px] sm:p-[50px]  flex flex-col xl:flex-row gap-[50px] justify-between">
        <div className="w-full xl:w-[45%] flex flex-col gap-[25px]">
          <h1 className="font-extrabold text-[22px]">Payment Method</h1>
          <div className="w-full flex flex-wrap sm:flex-nowrap gap-[20px] items-center">
            <img loading="lazy" src={Upi} alt="upi" />
            <img loading="lazy" src={Line} alt="line" />
            <img loading="lazy" src={Venmo} alt="venmo" />
            <img loading="lazy" src={Paypal} alt="paypal" />
            <img loading="lazy" src={Paytm} alt="paytm" />
          </div>
          <div className="w-full flex flex-col gap-[12px]">
            <label htmlFor="name" className="text-[#253058] font-extrabold">
              Name*
            </label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={handleChange}
              value={paymentValues?.name}
              autoComplete="false"
              required
              className="w-full p-[7px] border border-[#E8EBF1] bg-[#F8F9FB] rounded-[7px] outline-none"
            />
          </div>
          <div className="w-full flex flex-col gap-[12px]">
            <label
              htmlFor="cardNumber"
              className="text-[#253058] font-extrabold"
            >
              Card Number*
            </label>
            <input
              type="text"
              name="cardNumber"
              id="cardNumber"
              onChange={handleChange}
              value={paymentValues?.cardNumber}
              autoComplete="cc-number"
              maxLength="16"
              className="w-full p-[7px] border border-[#E8EBF1] bg-[#F8F9FB] rounded-[7px] outline-none"
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              placeholder="1234 4567 8901 2158"
              required
            />
          </div>
          <div className="w-full flex justify-between items-center gap-[15px]">
            <div className="w-[30%] flex flex-col gap-[12px]">
              <label htmlFor="ccv" className="text-[#253058] font-extrabold">
                CCV*
              </label>
              <input
                type="text"
                name="ccv"
                id="ccv"
                onChange={handleChange}
                value={paymentValues?.ccv}
                autoComplete="false"
                maxLength="3"
                className="w-full p-[7px] border border-[#E8EBF1] bg-[#F8F9FB] rounded-[7px] outline-none"
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                placeholder="123"
                required
              />
            </div>
            <div className="w-[70%] flex flex-col gap-[12px]">
              <label
                htmlFor="expiryDate"
                className="text-[#253058] font-extrabold"
              >
                Expiry Date*
              </label>
              <input
                type="date"
                name="expiryDate"
                id="expiryDate"
                onChange={handleChange}
                value={paymentValues?.expiryDate}
                autoComplete="false"
                className="w-full p-[7px] border border-[#E8EBF1] bg-[#F8F9FB] rounded-[7px] outline-none"
                required
              />
            </div>
          </div>
          <button className="p-[10px] bg-[#22d87c] text-white font-extrabold rounded-[7px]">
            PAY
          </button>
        </div>
        <div className="w-full xl:w-[40%] h-[550px] bg-paymentBg bg-cover bg-no-repeat bg-top p-[25px] flex flex-col justify-between gap-[20px]">
          <div className="w-full flex flex-col gap-[20px]">
            <h2 className="text-[#253058] font-extrabold text-[22px]">
              Payment Summary
            </h2>
            <div className="w-full flex flex-col gap-[7px]">
              <div className="w-full xl:w-[75%] justify-between flex items-center gap-[30px]">
                <h3 className="text-[14px] text-[#7C87AA] font-extrabold">
                  Order Number
                </h3>
                <p className="text-[#253058] font-extrabold">11458523</p>
              </div>
              <div className="w-full xl:w-[75%] justify-between flex items-center gap-[30px]">
                <h3 className="text-[14px] text-[#7C87AA] font-extrabold">
                  GST
                </h3>
                <p className="text-[#253058] font-extrabold">%20</p>
              </div>
              <div className="w-full xl:w-[75%] justify-between flex items-center gap-[30px]">
                <h3 className="text-[14px] text-[#7C87AA] font-extrabold">
                  GST Amount
                </h3>
                <p className="text-[#253058] font-extrabold">$ 123,28</p>
              </div>
              <div className="w-full xl:w-[75%] justify-between flex items-center gap-[30px]">
                <h3 className="text-[14px] text-[#7C87AA] font-extrabold">
                  Order Amount
                </h3>
                <p className="text-[#253058] font-extrabold">$ 123,28</p>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-[20px]">
            <img loading="lazy" src={Vector} alt="vector" />
            <div className="w-full flex items-center gap-[20px] justify-between">
              <div className=" flex flex-col gap-[7px]">
                <h3 className="text-[15px] text-[#7C87AA] font-extrabold">
                  Amount to be paid
                </h3>
                <p className="text-[#253058] font-extrabold text-[25px]">
                  $ 576,32
                </p>
              </div>
              <img loading="lazy" src={FilePayment} alt="payment-receipt" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Payment;
