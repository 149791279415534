import React, { useEffect, useState } from "react";
import Img from "../../../assets/customer/new-order-page.png";
import { FaArrowLeft, FaPlus, FaX } from "react-icons/fa6";
import Layout from "../layout/layout";
import { useDispatch } from "react-redux";
import { setEditorSidebar } from "../../../redux/reducer/sidebarSlice";
import { showToastError, showToastSuccess } from "../../../utils/toastify";
import axios from "axios";
import UserProfileImg from "../../../assets/user-profile.jpg";
import ProfilePageSkeleton from "../layout/profilePageSkeleton";
const EditorSettings = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setEditorSidebar("settings"));
  }, []);
  const token = localStorage.getItem("editorToken");
  const [clientInformation, setClientInformation] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
  });
  const [languageValues, setLanguageValues] = useState("");
  const [languageData, setLanguageData] = useState([]);
  const handleLanguageChange = (e) => {
    setLanguageValues(e.target.value);
  };
  const handleAddLanguage = () => {
    const trimmedLanguage = languageValues?.trim();
    if (trimmedLanguage) {
      const data = [...languageData, trimmedLanguage];
      setLanguageData(data);
      setLanguageValues("");
    }
  };
  const handleDeleteLanguage = (index) => {
    const deletedItem = languageData?.filter((_, item) => item !== index);
    setLanguageData(deletedItem);
  };
  const [photo, setPhoto] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPhoto({
        file: file,
        url: url,
      });
    }
  };
  const handleClientInformation = (e) => {
    const { name, value } = e.target;
    setClientInformation({ ...clientInformation, [name]: value });
  };

  const [passwordDetails, setPasswordDetails] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordDetails({ ...passwordDetails, [name]: value });
  };
  const handleSubmitPassword = () => {
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/editor-change-password",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        oldPassword: passwordDetails?.currentPassword,
        newPassword: passwordDetails?.newPassword,
      },
    })
      .then((res) => {
        showToastSuccess(res?.data?.message);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };

  const [isUserDetailsFetched, setIsUserDetailsFetched] = useState(false);
  const handleFetchUserDetails = () => {
    axios({
      method: "GET",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/editor/get-editor-by-token",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setIsUserDetailsFetched(true);
        let data = res?.data?.Data;
        setClientInformation({
          name: data?.name,
          email: data?.email,
          phone: data?.phone,
          country: data?.country,
          language: data?.language,
          photo: data?.photo,
        });
        setLanguageData(data?.language);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    handleFetchUserDetails();
  }, []);

  const formData = new FormData();
  formData.append("name", clientInformation?.name);
  formData.append("phone", clientInformation?.phone);
  formData.append("country", clientInformation?.country);
  formData.append("language", languageData);
  formData.append("file", photo?.file);
  const handleUpdateUser = () => {
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/update-editor",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        name: clientInformation?.name,
        phone: clientInformation?.phone,
        country: clientInformation?.country,
        language: languageData,
      },
    })
      .then((res) => {})
      .catch((err) => {});
  };
  return (
    <Layout>
      <div className="w-full flex flex-col items-center gap-[20px] p-[20px] ">
        <div className="w-full justify-center flex flex-col gap-[7px]   items-center">
          <h1 className="font-extrabold text-[36px] leading-[1.3em] text-center">
            Client Information*
          </h1>
          {/* <p className="text-[18px] text-[#555555]">
            Would you like to be contacted when the order is complete
          </p> */}
        </div>
        <div className="w-full flex flex-col items-center gap-[20px]">
          <div className="hidden w-full  flex-col sm:flex-row justify-center gap-[20px] items-center">
            <div className="w-[150px] h-[150px]">
              {photo?.url ? (
                <img
                  loading="lazy"
                  src={photo?.url}
                  alt="userProfileImg"
                  className="w-full h-full rounded-[7px] object-contain"
                />
              ) : (
                <img
                  loading="lazy"
                  src={UserProfileImg}
                  alt="userProfileImg"
                  className="w-full h-full rounded-[7px] object-contain"
                />
              )}
            </div>
            <div className="w-full sm:w-[50%] flex flex-col items-center justify-center relative p-[30px] border border-gray-200 rounded-[20px] z-[3]">
              <input
                type="file"
                name="photo"
                id="photo"
                onChange={handleImageChange}
                className="w-full h-full absolute top-0 left-0 z-[5] opacity-0 cursor-pointer"
              />
              <label htmlFor="photo">Upload Image here</label>
            </div>
          </div>
          {isUserDetailsFetched === true ? (
            <div className="w-full text-[14px] flex justify-start flex-wrap items-center gap-[20px]">
              <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                <label htmlFor="name" className="font-semibold">
                  Name*
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={handleClientInformation}
                  value={clientInformation.name}
                  autoComplete="false"
                  className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                  onKeyPress={(e) => {
                    if (!/[a-z\s]/i.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>

              <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                <label htmlFor="email" className="font-semibold">
                  Email*
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleClientInformation}
                  value={clientInformation.email}
                  autoComplete="false"
                  className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                  disabled
                />
              </div>

              <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                <label htmlFor="phone" className="font-semibold">
                  Phone Number*
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  onChange={handleClientInformation}
                  value={clientInformation.phone}
                  autoComplete="false"
                  className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                  maxLength="10"
                  onKeyPress={(e) => {
                    if (!/^\d$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>

              <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                <label htmlFor="country" className="font-semibold">
                  Country*
                </label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  onChange={handleClientInformation}
                  value={clientInformation.country}
                  autoComplete="false"
                  className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                  onKeyPress={(e) => {
                    if (!/[a-z\s]/i.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                <label htmlFor="languageValues" className="font-semibold">
                  Language*
                </label>
                <div className="w-full flex items-center gap-[12px]">
                  <input
                    type="text"
                    name="languageValues"
                    id="languageValues"
                    onChange={handleLanguageChange}
                    value={languageValues}
                    autoComplete="false"
                    className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                    onKeyPress={(e) => {
                      if (!/[a-z\s]/i.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <FaPlus
                    onClick={handleAddLanguage}
                    className="cursor-pointer"
                  />
                </div>
              </div>
              <div className="w-full flex flex-wrap items-center justify-center gap-[15px]">
                {languageData?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="p-[10px] bg-[#f5f5f4] text-black border border-black rounded-[7px] flex items-center gap-[7px]"
                    >
                      <p>{item}</p>
                      <FaX
                        onClick={() => handleDeleteLanguage(index)}
                        className="cursor-pointer"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <ProfilePageSkeleton />
          )}

          <button
            onClick={handleUpdateUser}
            className="bg-[#22d87c] text-white px-[20px] py-[10px] rounded-[7px]"
          >
            SAVE
          </button>
        </div>

        <div className="w-full flex flex-col items-center gap-[20px]">
          <div className="w-full flex flex-col gap-[4px]">
            <h3 className="font-bold w-full">Password & Security*</h3>
            {/* <p>Would You like to be contacted when the order is complete:</p> */}
          </div>
          <div className="w-full text-[14px] flex justify-start flex-wrap items-center gap-[20px]">
            <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
              <label htmlFor="currentPassword" className="font-semibold">
                Current Password*
              </label>
              <input
                type="password"
                name="currentPassword"
                id="currentPassword"
                onChange={handlePasswordChange}
                value={passwordDetails?.currentPassword}
                autoComplete="false"
                className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
              />
            </div>
            <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
              <label htmlFor="newPassword" className="font-semibold">
                New Password*
              </label>
              <input
                type="password"
                name="newPassword"
                id="newPassword"
                onChange={handlePasswordChange}
                value={passwordDetails?.newPassword}
                autoComplete="false"
                className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
              />
            </div>
            <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
              <label htmlFor="confirmPassword" className="font-semibold">
                Confirm Password*
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                onChange={handlePasswordChange}
                value={passwordDetails?.confirmPassword}
                autoComplete="false"
                className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
              />
            </div>
          </div>
        </div>
        <button
          onClick={handleSubmitPassword}
          className="bg-[#22d87c] text-white px-[20px] py-[10px] rounded-[7px]"
        >
          SAVE
        </button>
        <img loading="lazy" src={Img} alt="img" className="" />
      </div>
    </Layout>
  );
};

export default EditorSettings;
