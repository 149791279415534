import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  clientInformation: {
    businessName: "",
    contactName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
  },
  orderDetails: {
    orderCreationDate: "",
    requestedDate: "",
    requestedTime: "",
    orderNumber: "",
    styleOption: "",
    videoType: "",
    productionType: "",
  },
  funeralDetails: {
    subjectName: "",
    dateOfBirth: "",
    dateOfDeath: "",
    funeralDate: "",
    funeralTime: "",
  },
  img: [],
  videos: [],
  selectedImg: [],
  timeLine: [],
  type: "",
  selectedMusic: JSON.parse(localStorage.getItem("selectedMusic")) || [],
  playingMusic: null,
  uploadPhotos: [],
  selectedVideo: [],
  notes: [],
  uploadVideo: [],
  coverImage: [],
  music: [],
  uploadAudio: [],

  scenery: [],
  quotes: {},
  privateNotesValue: "",
  privateNotesData: [],
  allotedEditors: null,
};

const newOrderAdminSlice = createSlice({
  name: "newOrderAdminSlice",
  initialState,
  reducers: {
    setClientInformation: (state, action) => {
      state.clientInformation = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setNotes: (state, action) => {
      state.notes = action.payload;
    },
    setPushedNotes: (state, action) => {
      state.notes = [...state.notes, action.payload];
    },
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    setFuneralDetails: (state, action) => {
      state.funeralDetails = action.payload;
    },
    setSelectedImg: (state, action) => {
      state.selectedImg = action.payload;
    },
    setTimeLine: (state, action) => {
      state.timeLine = action.payload;
    },
    updateImgNote: (state, action) => {
      const { id, notes } = action.payload;
      const image = state.selectedImg?.find((img) => img.id === id);
      if (image) {
        image.notes = notes;
      }
    },
    setSelectedMusic: (state, action) => {
      state.selectedMusic = action.payload;
    },
    updateMusicNote: (state, action) => {
      const { id, notes } = action.payload;
      const music = state.selectedMusic?.find((music) => music.id === id);
      if (music) {
        music.notes = notes;
      }
    },
    setSelectedVideo: (state, action) => {
      state.selectedVideo = action.payload;
    },
    updateVideoNote: (state, action) => {
      const { id, notes } = action.payload;
      const video = state.selectedVideo?.find((video) => video.id === id);
      if (video) {
        video.notes = notes;
      }
    },
    setPlayingMusic: (state, action) => {
      state.playingMusic = action.payload;
    },
    setImg: (state, action) => {
      state.img = action.payload;
    },
    setVideos: (state, action) => {
      state.videos = action.payload;
    },
    setUploadPhoto: (state, action) => {
      state.uploadPhotos = action.payload;
    },
    pushUploadPhoto: (state, action) => {
      state.uploadPhotos = [...state.uploadPhotos, ...action.payload];
    },
    updateFileUrl: (state, action) => {
      const { index, url } = action.payload;
      if (index >= 0 && index < state.uploadPhotos.length) {
        state.uploadPhotos[index].url = url;
      }
    },
    setUploadVideo: (state, action) => {
      state.uploadVideo = action.payload;
    },
    pushUploadVideo: (state, action) => {
      state.uploadVideo = [...state.uploadVideo, ...action.payload];
    },
    updateFileComment: (state, action) => {
      const { index, comment } = action.payload;
      if (state.uploadPhotos[index]) {
        state.uploadPhotos[index].comment = comment;
      }
    },

    updateVideoComment: (state, action) => {
      const { index, comment } = action.payload;
      if (state.uploadVideo[index]) {
        state.uploadVideo[index].comment = comment;
      }
    },
    setCoverImage: (state, action) => {
      state.coverImage = action.payload;
    },
    setMusic: (state, action) => {
      state.music = action.payload;
    },
    setScenery: (state, action) => {
      state.scenery = action.payload;
    },
    setQuotes: (state, action) => {
      state.quotes = action.payload;
    },
    setPrivateNotesValue: (state, action) => {
      state.privateNotesValue = action.payload;
    },
    pushPrivateNoteData: (state, action) => {
      state.privateNotesData = [...state.privateNotesData, action.payload];
    },
    setPrivateNotesData: (state, action) => {
      state.privateNotesData = action.payload;
    },
    setAllottedEditors: (state, action) => {
      state.allotedEditors = action.payload;
    },
    pushUploadAudio: (state, action) => {
      state.uploadAudio = [...state.uploadAudio, ...action.payload];
    },
    setUploadAudio: (state, action) => {
      state.uploadAudio = action.payload;
    },
    updateAudioComment: (state, action) => {
      const { index, comment } = action.payload;
      if (state.uploadAudio[index]) {
        state.uploadAudio[index].comment = comment;
      }
    },
  },
});

export const {
  setClientInformation,
  setOrderDetails,
  setFuneralDetails,
  setSelectedImg,
  setNotes,
  setPushedNotes,
  updateImgNote,
  setSelectedMusic,
  setType,
  updateMusicNote,
  setSelectedVideo,
  setPlayingMusic,
  updateVideoNote,
  setImg,
  setVideos,
  setUploadPhoto,
  updateFileUrl,
  pushUploadPhoto,
  setTimeLine,
  setUploadVideo,
  pushUploadVideo,
  updateFileComment,
  updateVideoComment,
  setCoverImage,
  setMusic,
  setScenery,
  setQuotes,
  setPrivateNotesData,
  pushPrivateNoteData,
  setPrivateNotesValue,
  setAllottedEditors,
  pushUploadAudio,
  setUploadAudio,
  updateAudioComment,
} = newOrderAdminSlice.actions;

export default newOrderAdminSlice.reducer;
