import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    customer: null
}

const customerInfoSlice = createSlice({
    name: "customerInfoSlice",
    initialState,
    reducers: {
        setCustomerInfo: (state, action) => {
            state.customer = action.payload;
        },
    },
});

export const { setCustomerInfo } = customerInfoSlice.actions;
export default customerInfoSlice.reducer;
