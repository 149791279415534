import React, { useEffect, useRef, useState } from "react";
import Layout from "../layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { setAdminSidebar } from "../../../redux/reducer/sidebarSlice";
import Icon1 from "../../../assets/admin/Icon.png";
import Icon2 from "../../../assets/admin/Icon (1).png";
import Icon3 from "../../../assets/admin/Icon2.png";
import Icon4 from "../../../assets/admin/Icon (2).png";
import { showToastError, showToastSuccess } from "../../../utils/toastify";
import DoughnutChart from "../graphs/doughnutGraph";
import axios from "axios";
import musicImg from "../../../assets/music.png";
import VerticalGraph from "../graphs/verticalGraph";
import { FaImage, FaMusic, FaTrash, FaVideo, FaX } from "react-icons/fa6";
import {
  setCoverImage,
  setMusic,
  setScenery,
} from "../../../redux/reducer/newOrderAdminSlice";
import Spinner from "../../../utils/spinner";
import DashboardSkeleton from "../layout/dashboardSkeleton";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAdminSidebar("dashboard"));
  }, [dispatch]);

  const [tabs, setTabs] = useState(false);
  const handleTabs = () => {
    setTabs(!tabs);
  };

  const adminDetails = useSelector(
    (state) => state.adminUserDetails.adminDetails
  );

  const [isFetched, setIsFetched] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [revenue, setRevenue] = useState(null);
  const [types, setTypes] = useState(null);
  const coverImage = useSelector(
    (state) => state.newOrderAdminSlice.coverImage
  );
  const videos = useSelector((state) => state.newOrderAdminSlice.scenery);
  const music = useSelector((state) => state.newOrderAdminSlice.music);
  const [dashboardData, setDashboardData] = useState();
  const token = localStorage.getItem("adminToken");
  const dispatchData = () => {
    axios({
      method: "GET",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/get-admin-data",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        let data = res?.data?.Data;
        console.log(data, "lavi");

        dispatch(setCoverImage(data?.coverImage));
        dispatch(setMusic(data?.music));
        dispatch(setScenery(data?.scenery));
        setPricingValues({
          pricePerPhoto: data?.pricePerPhoto,
          hidePricing: data?.hidePricing,
        });
        setIsFetched(true);
      })
      .catch((err) => {
        console.error(err);
        showToastError(err?.response?.data?.message);
      });
  };

  const getDashboardData = () => {
    axios({
      method: "GET",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/get-dashboard-data",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setRevenue(res?.data?.Data?.charts?.revenueByDay || 0);
        setTypes(res?.data?.Data?.charts?.types || 0);
        setDashboardData(res.data.Data);
      })
      .catch((err) => {
        console.error(err);
        showToastError(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getDashboardData();
    dispatchData();
  }, []);
  // upload image functions
  const [uploadImage, setUploadImage] = useState([]);
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file) => ({
      url: URL.createObjectURL(file),
      file: file,
    }));
    setUploadImage((prevImages) => [...prevImages, ...newImages]);
  };

  // upload Video functions
  const [uploadVideo, setUploadVideo] = useState([]);
  const handleVideoChange = (e) => {
    const files = Array.from(e.target.files);
    const newVideos = files.map((file) => ({
      url: URL.createObjectURL(file),
      file: file,
    }));
    setUploadVideo((prev) => [...prev, ...newVideos]);
  };

  // upload music functions
  const [uploadMusic, setUploadMusic] = useState([]);
  const handleMusicChange = (e) => {
    const files = Array.from(e.target.files);
    const newMusics = files.map((file) => ({
      file: file,
    }));
    setUploadMusic((prev) => [...prev, ...newMusics]);
  };

  const [showOptions, setShowOptions] = useState(false);
  const handleShowOptions = (index) => {
    setShowOptions(index === showOptions ? null : index);
  };

  // to post image
  const formData = new FormData();
  uploadImage.forEach((fileObj) => {
    formData.append(`files`, fileObj.file);
  });
  formData.append("folderPath", "/");

  const handleImageSubmit = () => {
    setIsFileUploading(true);

    axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/spaces/upload",

      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    })
      .then((res) => {
        const formattedArray = res?.data?.urls?.map((item) => {
          const cleanUrl = item.split("  /");
          const constructUrl = cleanUrl?.join("/");
          return constructUrl;
        });
        console.log(formattedArray, "formattedArray");
        handleUpdateImages(formattedArray);
        showToastSuccess(res?.data?.message);
        setUploadImage([]);
        setShowOptions(false);
        setIsFileUploading(false);
      })
      .catch((err) => {
        setIsFileUploading(false);
        showToastError(err?.response?.data?.message);
      });
  };

  // to update image array

  const handleUpdateImages = (link) => {
    setIsFileUploading(true);

    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/update-coverImage",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        coverImage: [...coverImage, ...link],
      },
    })
      .then((res) => {
        dispatch(setCoverImage(res?.data?.Data?.coverImage));
        showToastSuccess(res?.data?.message);
        setIsFileUploading(false);
      })
      .catch((err) => {
        setIsFileUploading(false);
        showToastError(err?.response?.data?.message);
      });
  };

  const handleDeleteCoverPhoto = (index) => {
    const deleteItem = coverImage?.filter((_, item) => item !== index);
    dispatch(setCoverImage(deleteItem));
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/update-coverImage",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        coverImage: deleteItem,
      },
    })
      .then((res) => {
        dispatch(setCoverImage(res?.data?.Data?.coverImage));
        showToastSuccess(res?.data?.message);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };

  // video upload
  const handleUpdateVideo = (link) => {
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/update-scenery",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        scenery: [...videos, ...link],
      },
    })
      .then((res) => {
        dispatch(setScenery(res?.data?.Data?.scenery));
        showToastSuccess(res?.data?.message);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };
  const handleVideoSubmit = () => {
    setIsFileUploading(true);

    const formData = new FormData();
    uploadVideo.forEach((fileObj) => {
      formData.append(`files`, fileObj.file);
    });
    formData.append("folderPath", "/");
    axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/spaces/upload",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    })
      .then((res) => {
        const formattedArray = res?.data?.urls?.map((item) => {
          const cleanUrl = item.split("  /");
          const constructUrl = cleanUrl?.join("/");
          return constructUrl;
        });
        handleUpdateVideo(formattedArray);
        setUploadVideo([]);
        handleShowOptions(false);
        setIsFileUploading(false);
        showToastSuccess(res?.data?.message);
      })
      .catch((err) => {
        setIsFileUploading(false);
        showToastError(err?.response?.data?.message);
      });
  };
  const handleDeleteVideos = (index) => {
    const deleteItem = videos?.filter((_, item) => item !== index);
    dispatch(setScenery(deleteItem));
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/update-scenery",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        scenery: deleteItem,
      },
    })
      .then((res) => {
        dispatch(setScenery(res?.data?.Data?.scenery));
        showToastSuccess(res?.data?.message);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };

  const handleMusicSubmit = () => {
    setIsFileUploading(true);

    const formData = new FormData();
    uploadMusic.forEach((fileObj) => {
      formData.append(`files`, fileObj.file);
    });
    formData.append("folderPath", "/");
    axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/spaces/upload",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    })
      .then((res) => {
        const formattedArray = res?.data?.urls?.map((item) => {
          const cleanUrl = item.split("  /");
          const constructUrl = cleanUrl?.join("/");
          return constructUrl;
        });
        handleUpdateMusic(formattedArray);
        setUploadMusic([]);
        handleShowOptions(false);
        setIsFileUploading(false);
        showToastSuccess(res?.data?.message);
      })
      .catch((err) => {
        setIsFileUploading(false);
        showToastError(err?.response?.data?.message);
      });
  };
  const handleUpdateMusic = (link) => {
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/update-music",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        music: [...music, ...link],
      },
    })
      .then((res) => {
        dispatch(setMusic(res?.data?.Data?.music));
        showToastSuccess(res?.data?.message);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };
  const handleDeleteMusic = (index) => {
    const deleteItem = music?.filter((_, item) => item !== index);
    dispatch(setMusic(deleteItem));
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/update-music",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        music: deleteItem,
      },
    })
      .then((res) => {
        dispatch(setMusic(res?.data?.Data?.music));
        showToastSuccess(res?.data?.message);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };

  const [pricingValues, setPricingValues] = useState({
    pricePerVideo: null,
    hidePricing: false,
    selectionPrice1: null,
    pricePerPhoto: null,
  });

  const handlePricingChange = (e) => {
    const { name, value } = e.target;
    setPricingValues({ ...pricingValues, [name]: value });
  };

  const handlePricingSubmit = () => {
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/update-priceUpdate",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        pricePerVideo: Number(pricingValues?.pricePerVideo),
        hidePricing: pricingValues?.hidePricing,
        selectionPrice1: Number(pricingValues?.selectionPrice1),
        pricePerPhoto: Number(pricingValues?.pricePerPhoto),
      },
    })
      .then((res) => {
        showToastSuccess(res?.data?.message);
        setTabs(false);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };
  const [addQuoteValues, setAddQuoteValues] = useState({
    quote_id: "",
    name: "",
    text: "",
  });
  const handleAddQuoteChange = (e) => {
    const { name, value } = e.target;
    setAddQuoteValues({ ...addQuoteValues, [name]: value });
  };

  const [quoteData, setQuoteData] = useState([
    {
      id: "1",
      quote_id: "VSANM52",
      name: "hey there",
      text: "hey there my name is Daniel.",
    },
  ]);

  // addQuote
  const handleAddQuoteSubmit = () => {
    axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/add-quote",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        quote_id: addQuoteValues?.quote_id,
        name: addQuoteValues?.name,
        text: addQuoteValues?.text,
      },
    })
      .then((res) => {
        showToastSuccess(res?.data?.message);
        fetchQuotes();
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };

  // delete Quote
  const handleDeleteQuote = (id) => {
    axios({
      method: "DELETE",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/delete-quote",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: id,
      },
    })
      .then((res) => {
        showToastSuccess(res?.data?.message);
        fetchQuotes();
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };

  // getQuote

  const fetchQuotes = () => {
    axios({
      method: "GET",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/get-quotes",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setQuoteData(res?.data?.Data || []);
      })
      .catch((err) => {
        showToastError(err?.response?.data);
      });
  };
  useEffect(() => {
    fetchQuotes();
  }, []);

  const videoRefs = useRef([]);
  const [hoveredVideo, setHoveredVideo] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredVideo(index);
    videoRefs.current[index].play();
  };

  const handleMouseLeave = (index) => {
    setHoveredVideo(null);
    videoRefs.current[index].pause();
  };

  const audioRefs = useRef([]);
  const playingMusic = useSelector(
    (state) => state.newOrderAdminSlice.playingMusic
  );
  useEffect(() => {
    audioRefs.current.forEach((ref, index) => {
      if (ref && index === playingMusic) {
        ref.play();
      } else if (ref) {
        ref.pause();
      }
    });
  }, [playingMusic]);
  const navigate = useNavigate();

  const [newAdminValues, setNewAdminValues] = useState({
    email: "",
    password: "",
  });
  const handleNewAdminChange = (e) => {
    const { name, value } = e.target;
    setNewAdminValues({ ...newAdminValues, [name]: value });
  };
  const handleNewAdminSubmit = async () => {
    await axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/register",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        email: newAdminValues?.email,
        password: newAdminValues?.password,
      },
    })
      .then((res) => {
        showToastSuccess("Admin Added Successfully!");
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };
  return (
    <div>
      {isFetched === true ? (
        <Layout>
          <div className="w-full flex text-[14px] flex-col gap-[40px] p-[20px] h-[20vh]">
            <div className="w-full flex items-center justify-between gap-[20px] flex-col sm:flex-row">
              <h2 className="font-extrabold text-[24px]">Dashboard</h2>
              <button
                onClick={() => {
                  setTabs(6);
                }}
                className=" px-[20px] rounded-[4px] py-[12px] bg-gray-300 border border-gray-400 text-gray-600 font-semibold"
              >
                Add a new Admin
              </button>
            </div>
            <div className="w-full flex justify-center flex-wrap xl:flex-nowrap gap-[20px] items-center">
              <div
                onClick={() => {
                  navigate("/admin/user-list");
                }}
                className="p-[20px] cursor-pointer w-full sm:w-[45%] xl:w-[25%] flex gap-[20px] items-center shadow-md rounded-[10px]"
              >
                <img loading="lazy" src={Icon1} alt="icon" />
                <div className="flex flex-col gap-[4px]">
                  <h3 className="text-[19px] font-bold">
                    {dashboardData?.totalCustomer}
                  </h3>
                  <p className="text-[15px]">Total Customers</p>
                </div>
              </div>
              <div
                onClick={() => {
                  navigate("/admin/order-list");
                }}
                className="p-[20px] cursor-pointer w-full sm:w-[45%] xl:w-[25%] flex gap-[20px] items-center shadow-md rounded-[10px]"
              >
                <img loading="lazy" src={Icon2} alt="icon" />
                <div className="flex flex-col gap-[4px]">
                  <h3 className="text-[19px] font-bold">
                    {dashboardData?.recentOrders}
                  </h3>
                  <p className="text-[15px]">New Orders</p>
                </div>
              </div>
              <div
                onClick={() => {
                  navigate("/admin/user-list");
                }}
                className="p-[20px] cursor-pointer w-full sm:w-[45%] xl:w-[25%] flex gap-[20px] items-center shadow-md rounded-[10px]"
              >
                <img loading="lazy" src={Icon3} alt="icon" />
                <div className="flex flex-col gap-[4px]">
                  <h3 className="text-[19px] font-bold">
                    {dashboardData?.recentUsers}
                  </h3>
                  <p className="text-[15px]">New Users</p>
                </div>
              </div>
              <div
                onClick={() => {
                  navigate("/admin/order-list");
                }}
                className="p-[20px] cursor-pointer w-full sm:w-[45%] xl:w-[25%] flex gap-[20px] items-center shadow-md rounded-[10px]"
              >
                <img loading="lazy" src={Icon4} alt="icon" />
                <div className="flex flex-col gap-[4px]">
                  <h3 className="text-[19px] font-bold">
                    {dashboardData?.CompleteOrders}
                  </h3>
                  <p className="text-[15px]">Completed Orders</p>
                </div>
              </div>
            </div>
            <div className="mt-[30px] flex-col xl:flex-row  w-full flex gap-[50px]">
              <div className="w-full xl:w-[50%] h-[300px]  flex flex-col gap-[12px]">
                <div className="w-full flex items-center justify-between gap-[20px]">
                  <p>Revenue</p>
                </div>
                <VerticalGraph revenue={revenue} />
              </div>
              <div className="w-full mb-[50px] xl:mb-0 xl:w-[50%] h-[300px] flex flex-col items-center gap-[12px]">
                <div className="w-full flex items-center justify-between gap-[20px]">
                  <p>Order Time</p>
                </div>
                <DoughnutChart types={types} />
              </div>
            </div>
            <div className="w-full mt-[50px] flex flex-col sm:flex-row items-center p-[12px] bg-gray-200 rounded-[10px] gap-[12px] justify-center">
              <button
                onClick={() => setTabs(1)}
                className="w-full sm:w-auto px-[20px] py-[12px] rounded-[4px] bg-gray-300 border border-gray-400 text-gray-600 font-semibold"
              >
                View Cover Images
              </button>
              <button
                onClick={() => setTabs(2)}
                className="w-full sm:w-auto px-[20px] py-[12px] rounded-[4px] bg-gray-300 border border-gray-400 text-gray-600 font-semibold"
              >
                View Sceneries
              </button>
              <button
                onClick={() => setTabs(3)}
                className="w-full sm:w-auto px-[20px] rounded-[4px] py-[12px] bg-gray-300 border border-gray-400 text-gray-600 font-semibold"
              >
                View Music
              </button>
              <button
                onClick={() => setTabs(4)}
                className="w-full sm:w-auto px-[20px] rounded-[4px] py-[12px] bg-gray-300 border border-gray-400 text-gray-600 font-semibold"
              >
                Set Pricing
              </button>

              <button
                onClick={() => setTabs(5)}
                className="w-full sm:w-auto px-[20px] rounded-[4px] py-[12px] bg-gray-300 border border-gray-400 text-gray-600 font-semibold"
              >
                Add Quote
              </button>
            </div>
          </div>

          {tabs === 1 ? (
            <div className="w-full h-full absolute z-[5] bg-black bg-opacity-[.54] top-0 left-0 p-[30px] flex flex-col items-center justify-center">
              <div className="w-full xl:w-[80%] h-[90%] relative z-[4] bg-white overflow-y-scroll flex flex-col gap-[20px] p-[30px] sm:p-[50px] hide-scrollbar shadow-xl shadow-gray-500  rounded-[20px]">
                <div className="w-full flex justify-end items-center gap-[20px]">
                  <button onClick={() => setTabs(false)}>
                    <FaX />
                  </button>
                </div>
                <div className=" justify-center flex-col sm:flex-row flex items-center gap-[20px]">
                  <h2 className="text-[25px] font-extrabold text-center">
                    Cover Image
                  </h2>
                  <button
                    onClick={() => {
                      handleShowOptions(1);
                    }}
                    className="px-[20px] py-[10px] bg-gray-200 text-gray-400 font-medium rounded-[7px]"
                  >
                    Add Cover Image
                  </button>
                </div>
                <div className="w-full flex justify-center flex-wrap gap-[20px]">
                  {coverImage?.map((item, index) => {
                    return (
                      // <div
                      //   key={index}
                      //   className="w-full relative sm:w-[45%] xl:w-[30%] h-[250px] flex flex-col"
                      // >
                      //   <img
                      //     loading="lazy"
                      //     src={item}
                      //     alt="img"
                      //     className="w-full h-full object-cover rounded-t-[7px]"
                      //   />
                      //   <p>{item?.split("/")[3]}</p>
                      //   <div
                      //     onClick={() => handleDeleteCoverPhoto(index)}
                      //     className="w-[30px] h-[30px] flex items-center justify-center bg-gray-400 rounded-[30px] absolute top-[5px] right-[5px] cursor-pointer"
                      //   >
                      //     <FaTrash className="  text-white" />
                      //   </div>
                      // </div>
                      <div
                        key={index}
                        className="w-full transition-all ease-in bg-gray-200 p-[15px] sm:w-[45%] rounded-[7px] xl:w-[30%] h-auto flex flex-col gap-[7px] relative"
                      >
                        <p className="mb-[10px] font-bold tracking-[2px]">
                          {
                            item
                              ?.split("/")
                              [item?.split("/").length - 1]?.split(".")[0]
                          }
                        </p>
                        <img
                          loading="lazy"
                          src={item}
                          alt="img"
                          className="w-full h-[250px]  object-contain rounded-[7px]"
                        />
                        <div
                          onClick={() => handleDeleteCoverPhoto(index)}
                          className="w-[30px] h-[30px] flex items-center justify-center bg-gray-400 rounded-[30px] absolute top-[5px] right-[5px] cursor-pointer z-[5]"
                        >
                          <FaTrash className="  text-white" />
                        </div>
                      </div>
                    );
                  })}
                </div>

                {showOptions === 1 ? (
                  <div className="w-full fixed sm:absolute h-full top-0 left-0 bg-white justify-start  items-center  flex flex-col gap-[30px] p-[30px] z-[5]">
                    <div className="w-full flex justify-end">
                      <button onClick={() => setShowOptions(false)}>
                        <FaX />
                      </button>
                    </div>
                    <div className="w-full flex flex-col items-center gap-[15px]">
                      <label htmlFor="uploadImage" className="font-medium">
                        Upload Image
                      </label>
                      <div className="w-full sm:w-[60%] p-[20px] flex flex-col justify-center items-center relative h-[100px] border border-dashed border-black rounded-[12px]">
                        <div className="flex items-center gap-[12px] justify-center w-full">
                          <FaImage />
                          Upload Here
                        </div>
                        <input
                          type="file"
                          name="uploadImage"
                          id="uploadImage"
                          onChange={handleImageChange}
                          multiple
                          className="w-full h-full opacity-0 cursor-pointer z-[4] absolute top-0 left-0"
                        />
                      </div>
                      {uploadImage ? (
                        <div className="w-full flex flex-wrap items-center gap-[15px] justify-center">
                          {uploadImage?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="w-full sm:w-[150px] sm:h-[150px]"
                              >
                                <img
                                  loading="lazy"
                                  src={item?.url}
                                  alt="img"
                                  className="w-full h-full object-contain rounded-[7px]"
                                />
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex items-center justify-center gap-[20px]">
                      <button
                        onClick={handleImageSubmit}
                        className="px-[20px] py-[12px] rounded-[7px] bg-gray-300 border border-gray-200"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : tabs === 2 ? (
            <div className="w-full h-full absolute z-[5] bg-black bg-opacity-[.54] top-0 left-0 p-[30px] flex flex-col items-center justify-center">
              <div className="w-full xl:w-[80%] h-[90%] relative bg-white overflow-y-scroll flex flex-col gap-[20px] p-[30px] sm:p-[50px] hide-scrollbar shadow-xl shadow-gray-500  rounded-[20px]">
                <div className="w-full flex justify-end items-center gap-[20px]">
                  <button onClick={() => setTabs(false)}>
                    <FaX />
                  </button>
                </div>
                <div className=" justify-center flex-col sm:flex-row flex items-center gap-[20px]">
                  <h2 className="text-[25px] font-extrabold text-center">
                    Scenery
                  </h2>
                  <button
                    onClick={() => handleShowOptions(2)}
                    className="px-[20px] py-[10px] bg-gray-200 text-gray-400 font-medium rounded-[7px]"
                  >
                    Add Scenery
                  </button>
                </div>
                <div className="w-full flex items-center justify-center flex-wrap gap-[20px]">
                  {videos.map((item, index) => {
                    return (
                      // <div
                      //   key={index}
                      //   className="w-full relative sm:w-[45%] xl:w-[30%] h-[300px] flex flex-col"
                      // >
                      //   <video
                      //     src={item}
                      //     alt="img"
                      //     className="w-full h-full object-contain rounded-t-[7px]"
                      //     autoPlay
                      //     loop
                      //     muted
                      //   />
                      //   <div
                      //     onClick={() => handleDeleteVideos(index)}
                      //     className="w-[30px] h-[30px] flex items-center justify-center bg-gray-400 rounded-[30px] absolute top-[5px] right-[5px] cursor-pointer"
                      //   >
                      //     <FaTrash className="  text-white" />
                      //   </div>
                      // </div>
                      <div
                        key={index}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                        className="w-full bg-gray-200 p-[15px] sm:w-[45%] rounded-[7px] xl:w-[30%] h-auto flex flex-col gap-[7px] relative"
                      >
                        <p className="mb-[10px] font-bold tracking-[2px]">
                          {
                            item
                              ?.split("/")
                              [item?.split("/").length - 1]?.split(".")[0]
                          }
                        </p>
                        <video
                          ref={(el) => (videoRefs.current[index] = el)}
                          src={item}
                          alt="video"
                          className="w-full h-[250px] object-contain rounded-[7px]"
                          loop
                          muted
                        />
                        <div
                          onClick={() => handleDeleteVideos(index)}
                          className="w-[30px] h-[30px] flex items-center justify-center bg-gray-400 rounded-[30px] absolute top-[5px] right-[5px] cursor-pointer z-[5]"
                        >
                          <FaTrash className="  text-white" />
                        </div>
                      </div>
                    );
                  })}
                </div>

                {showOptions === 2 ? (
                  <div className="w-full fixed sm:absolute h-full top-0 left-0 bg-white justify-start items-center  flex flex-col gap-[30px] p-[30px] z-[99]">
                    <div className="w-full flex justify-end">
                      <button onClick={() => setShowOptions(false)}>
                        <FaX />
                      </button>
                    </div>
                    <div className="w-full flex flex-col items-center gap-[15px]">
                      <label htmlFor="uploadVideo" className="font-medium">
                        Upload Video
                      </label>
                      <div className="w-full sm:w-[60%] p-[20px] flex flex-col justify-center items-center relative h-[100px] border border-dashed border-black rounded-[12px]">
                        <div className="flex items-center gap-[12px] justify-center w-full">
                          <FaVideo />
                          Upload Here
                        </div>
                        <input
                          type="file"
                          name="uploadVideo"
                          id="uploadVideo"
                          accept="video/mp4, video/avi, video/mov"
                          multiple
                          onChange={handleVideoChange}
                          className="w-full h-full opacity-0 cursor-pointer z-[4] absolute top-0 left-0"
                        />
                      </div>
                      {uploadVideo ? (
                        <div className="w-full flex flex-wrap items-center gap-[15px] justify-center">
                          {uploadVideo?.map((item, index) => {
                            return (
                              <div key={index} className="w-[150px] h-[150px]">
                                <video
                                  src={item?.url}
                                  alt="img"
                                  className="w-full h-full object-contain rounded-[7px]"
                                  loop
                                  muted
                                  autoPlay
                                />
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full flex items-center gap-[20px] justify-center">
                      <button
                        onClick={handleVideoSubmit}
                        className="px-[20px] py-[12px] rounded-[7px] bg-gray-300 border border-gray-200"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : tabs === 3 ? (
            <div className="w-full h-full absolute z-[5] bg-black bg-opacity-[.54] top-0 left-0 p-[30px] flex flex-col items-center justify-center">
              <div className="w-full xl:w-[80%] h-[90%] relative bg-white overflow-y-scroll flex flex-col gap-[20px] p-[30px] sm:p-[50px] hide-scrollbar shadow-xl shadow-gray-500  rounded-[20px]">
                <div className="w-full flex justify-end items-center gap-[20px]">
                  <button onClick={() => setTabs(false)}>
                    <FaX />
                  </button>
                </div>
                <div className=" justify-center flex-col sm:flex-row flex items-center gap-[20px]">
                  <h2 className="text-[25px] font-extrabold text-center">
                    Music
                  </h2>
                  <button
                    onClick={() => handleShowOptions(3)}
                    className="px-[20px] py-[10px] bg-gray-200 text-gray-400 font-medium rounded-[7px]"
                  >
                    Add Music
                  </button>
                </div>
                <div className="w-full flex items-center justify-center flex-wrap gap-[20px]">
                  {music.map((item, index) => {
                    return (
                      // <div
                      //   key={index}
                      //   className="w-full relative sm:w-[45%] xl:w-[30%] h-auto flex flex-col"
                      // >
                      //   <div className="w-full h-[250px]">
                      //     <img
                      //       loading="lazy"
                      //       src={musicImg}
                      //       alt="music"
                      //       className="w-full h-full object-contain  "
                      //     />
                      //   </div>
                      //   <audio src={item} controls className=" z-[2]" />
                      //   <div
                      //     onClick={() => handleDeleteMusic(index)}
                      //     className="w-[30px] h-[30px] flex items-center justify-center bg-gray-400 rounded-[30px] absolute top-[5px] right-[5px] cursor-pointer"
                      //   >
                      //     <FaTrash className="  text-white" />
                      //   </div>
                      // </div>

                      <div
                        key={index}
                        className="w-full bg-gray-200 p-[15px] sm:w-[45%] rounded-[7px] xl:w-[30%] h-auto flex flex-col gap-[7px] relative"
                      >
                        <p className="mb-[10px] z-[3] font-bold tracking-[2px]">
                          {
                            item
                              ?.split("/")
                              [item?.split("/").length - 1]?.split(".")[0]
                          }
                        </p>

                        <img
                          loading="lazy"
                          src={musicImg}
                          alt="music"
                          className="w-full h-[250px] object-contain absolute top-0 left-0 z-[1]"
                        />
                        <audio
                          ref={(el) => (audioRefs.current[index] = el)}
                          src={item}
                          controls
                          className="w-full h-[250px] object-contain rounded-[7px] relative z-[2]"
                        />
                        <div
                          onClick={() => handleDeleteMusic(index)}
                          className="w-[30px] h-[30px] flex items-center justify-center bg-gray-400 rounded-[30px] absolute top-[5px] right-[5px] cursor-pointer z-[5]"
                        >
                          <FaTrash className="  text-white" />
                        </div>
                      </div>
                    );
                  })}
                </div>

                {showOptions === 3 ? (
                  <div className="w-full fixed sm:absolute z-[99] h-full top-0 left-0 bg-white justify-start items-center  flex flex-col gap-[30px] p-[30px]">
                    <div className="w-full flex justify-end">
                      <button onClick={() => setShowOptions(false)}>
                        <FaX />
                      </button>
                    </div>
                    <div className="w-full flex flex-col items-center gap-[15px]">
                      <label htmlFor="uploadMusic" className="font-medium">
                        Upload Music
                      </label>
                      <div className="w-full sm:w-[60%] p-[20px] flex flex-col justify-center items-center relative h-[100px] border border-dashed border-black rounded-[12px]">
                        <div className="flex items-center gap-[12px] justify-center w-full">
                          <FaMusic />
                          Upload Here
                        </div>
                        <input
                          type="file"
                          name="uploadMusic"
                          id="uploadMusic"
                          accept="audio/mp3"
                          multiple
                          onChange={handleMusicChange}
                          className="w-full h-full opacity-0 cursor-pointer z-[4] absolute top-0 left-0"
                        />
                      </div>
                      {uploadMusic ? (
                        <div className="w-full flex flex-wrap items-center gap-[15px] justify-center">
                          {uploadMusic?.map((item, index) => {
                            return (
                              <audio
                                key={index}
                                src={item}
                                controls
                                className=" z-[2]"
                              />
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full justify-center flex items-center gap-[20px]">
                      <button
                        onClick={handleMusicSubmit}
                        className="px-[20px] py-[12px] rounded-[7px] bg-gray-300 border border-gray-200"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : tabs === 4 ? (
            <div className="w-full h-full absolute z-[5] bg-black bg-opacity-[.54] top-0 left-0 p-[30px] flex flex-col items-center justify-center">
              <div className="w-full xl:w-[80%] h-[90%] relative bg-white overflow-y-scroll flex flex-col gap-[20px] p-[30px] sm:p-[50px] hide-scrollbar items-center justify-start shadow-xl shadow-gray-500  rounded-[20px]">
                <div className="w-full flex justify-end">
                  <button
                    onClick={() => {
                      setTabs(false);
                      setPricingValues({
                        pricePerVideo: null,
                        hidePricing: null,
                        selectionPrice1: null,
                        pricePerPhoto: null,
                      });
                    }}
                  >
                    <FaX />
                  </button>
                </div>
                <h3 className="font-semibold text-[24px]">Pricing</h3>
                <div className="w-[70%] flex flex-col gap-[10px]">
                  <label htmlFor="pricePerPhoto">Price</label>
                  <input
                    type="number"
                    name="pricePerPhoto"
                    id="pricePerPhoto"
                    onChange={handlePricingChange}
                    value={pricingValues?.pricePerPhoto}
                    className="w-full p-[7px] border-2 border-[#22d87c] rounded-[7px] outline-none"
                  />
                </div>
                <div className="w-[70%] flex flex-col gap-[10px]">
                  <label htmlFor="hidePricing">Hide Pricing</label>
                  <select
                    name="hidePricing"
                    id="hidePricing"
                    onChange={handlePricingChange}
                    value={pricingValues?.hidePricing}
                    className="w-full p-[7px] border-2 border-[#22d87c] rounded-[7px] outline-none"
                  >
                    <option value="Select type"></option>
                    <option value="false">False</option>
                    <option value="true">True</option>
                  </select>
                </div>
                <div className="flex items-center gap-[12px]">
                  <button
                    onClick={handlePricingSubmit}
                    className="px-[20px] py-[12px] bg-green-500 rounded-[7px] text-white"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          ) : tabs === 5 ? (
            <div className="w-full h-full absolute z-[5] bg-black bg-opacity-[.54] top-0 left-0 p-[30px] flex flex-col items-center justify-center">
              <div className="w-full xl:w-[80%] h-[90%] relative bg-white overflow-y-scroll flex flex-col gap-[20px] p-[30px] sm:p-[50px] hide-scrollbar items-center justify-start shadow-xl shadow-gray-500  rounded-[20px]">
                <div className="w-full flex justify-end">
                  <button
                    onClick={() => {
                      setTabs(false);
                      // setPricingValues({
                      //   pricePerVideo: null,
                      //   hidePricing: null,
                      //   selectionPrice1: null,
                      //   pricePerPhoto: null,
                      // });
                    }}
                  >
                    <FaX />
                  </button>
                </div>
                <h3 className="font-semibold text-[24px]">Add Quote</h3>
                <div className="w-[70%] flex flex-col gap-[15px]">
                  <div className="w-full flex items-center gap-[15px]">
                    <div className="w-[50%] flex flex-col gap-[7px]">
                      <label htmlFor="quote_id" className="font-semibold">
                        Quote ID
                      </label>
                      <input
                        type="text"
                        name="quote_id"
                        id="quote_id"
                        onChange={handleAddQuoteChange}
                        value={addQuoteValues?.quote_id}
                        className="w-full p-[7px] border-2 border-[#22d87c] rounded-[7px] outline-none"
                      />
                    </div>
                    <div className="w-[50%] flex flex-col gap-[7px]">
                      <label htmlFor="name" className="font-semibold">
                        Quote Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        onChange={handleAddQuoteChange}
                        value={addQuoteValues?.name}
                        className="w-full p-[7px] border-2 border-[#22d87c] rounded-[7px] outline-none"
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-col gap-[7px]">
                    <label htmlFor="text" className="font-semibold">
                      Quote Text
                    </label>
                    <textarea
                      type="text"
                      name="text"
                      id="text"
                      onChange={handleAddQuoteChange}
                      value={addQuoteValues?.text}
                      className="w-full h-[80px] p-[7px] border-2 border-[#22d87c] rounded-[7px] outline-none"
                    />
                  </div>
                </div>
                <div className="flex items-center gap-[12px]">
                  <button
                    onClick={handleAddQuoteSubmit}
                    className="px-[20px] py-[12px] bg-green-500 rounded-[7px] text-white"
                  >
                    Add
                  </button>
                </div>
                <div className="container mx-auto p-4">
                  <table className="min-w-full text-left text-[12px] bg-white border border-gray-200">
                    <thead>
                      <tr className="text-[14px]">
                        <th className="py-2 px-4 border-b">Quote Id</th>
                        <th className="py-2 px-4 border-b">Quote Name</th>
                        <th className="py-2 px-4 border-b">Quote Text</th>
                        <th className="py-2 px-4 border-b">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {quoteData.map((item) => (
                        <tr key={item.key}>
                          <td className="py-2 px-4 border-b">
                            {item?.quote_id}
                          </td>
                          <td className="py-2 px-4 border-b">{item?.name}</td>
                          <td className="py-2 px-4 border-b">{item?.text}</td>
                          <td className="py-2 px-4 border-b">
                            <FaTrash
                              className="cursor-pointer"
                              onClick={() => {
                                handleDeleteQuote(item?.id);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : tabs === 6 ? (
            <div className="w-full h-full absolute z-[5] bg-black bg-opacity-[.54] top-0 left-0 p-[30px] flex flex-col items-center justify-center">
              <div className="w-full xl:w-[80%] h-[90%] relative bg-white overflow-y-scroll flex flex-col gap-[20px] p-[30px] sm:p-[50px] hide-scrollbar items-center justify-start shadow-xl shadow-gray-500  rounded-[20px]">
                <div className="w-full flex justify-end">
                  <button
                    onClick={() => {
                      setTabs(false);
                    }}
                  >
                    <FaX />
                  </button>
                </div>
                <h3 className="font-semibold text-[24px]">Add Admin</h3>
                <div className="w-[70%] flex flex-col gap-[15px]">
                  <div className="w-full flex items-center gap-[15px]">
                    <div className="w-full flex flex-col gap-[7px]">
                      <label htmlFor="email" className="font-semibold">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        onChange={handleNewAdminChange}
                        value={newAdminValues?.email}
                        className="w-full p-[7px] border-2 border-[#22d87c] rounded-[7px] outline-none"
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-col gap-[7px]">
                    <label htmlFor="password" className="font-semibold">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      onChange={handleNewAdminChange}
                      value={newAdminValues?.password}
                      className="w-full p-[7px] border-2 border-[#22d87c] rounded-[7px] outline-none"
                    />
                  </div>
                </div>
                <div className="flex items-center gap-[12px]">
                  <button
                    onClick={handleNewAdminSubmit}
                    className="px-[20px] py-[12px] bg-green-500 rounded-[7px] text-white"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {isFileUploading === true ? <Spinner /> : null}
        </Layout>
      ) : (
        <DashboardSkeleton />
      )}
    </div>
  );
};

export default AdminDashboard;
