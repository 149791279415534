import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { FaBars, FaX } from "react-icons/fa6";
import { showToastError, showToastSuccess } from "../../../utils/toastify";
import TableSkeleton from "../layout/tableSkeleton";
import {
  setCurrentPath,
  setFolderUrl,
} from "../../../redux/reducer/editorFolderSystemSlice";
import { useDispatch } from "react-redux";

const EditorOrderList = () => {
  const token = localStorage.getItem("editorToken");
  const [data, setData] = useState(null);

  const fetchData = () => {
    axios({
      method: "GET",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/editor/get-all-orders`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const data = res?.data?.Data || [];
        setData(data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [showOptions, setShowOptions] = useState(false);
  const handleShowOptions = (index) => {
    setShowOptions(index === showOptions ? null : index);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleViewFiles = (item) => {
    const folderPath = `${moment(item?.createdAt).year()}/${moment(
      item?.createdAt
    ).format("MMMM")}/${item?.id}`;
    // const length = folderPath.split("/").length - 2;
    // const splitValue = folderPath.split("/")[length];
    const splitValue = folderPath.split("/");
    dispatch(setFolderUrl(folderPath));
    dispatch(setCurrentPath(splitValue));
    navigate("/editor/file-explorer");
  };
  return (
    <div>
      {data ? (
        <Layout>
          <div className="w-full flex h-screen flex-col gap-[15px] p-[50px]">
            <h2 className="text-black font-bold text-[25px]">All Orders</h2>
            <div className="w-full overflow-x-auto hide-scrollbar bg-white h-[600px]">
              <table className="w-full whitespace-nowrap">
                <thead>
                  <tr className="border text-[12px] border-gray-200 rounded-t-[20px] bg-[#fcfdfd]">
                    <th className="sm:p-[12px] px-[30px] text-center py-[12px] sm:text-left">
                      ORDER ID
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      NAME
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      ADDRESS
                    </th>
                    {/* <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      FUNERAL DATE / TIME
                    </th> */}
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      TYPE
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      PAID
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      REQUESTED DATE
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      PREVIEW
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full border relative border-gray-200">
                  {data.map((item, index) => (
                    <tr
                      key={index}
                      className={`${
                        index % 2 === 1
                          ? "bg-[#fcfdfd] text-[12px]"
                          : "bg-white text-[12px]"
                      } relative`}
                    >
                      <td className="p-[12px] text-left">{item?.id}</td>
                      <td className="p-[12px] text-left">
                        {item?.contactName}
                      </td>
                      <td className="p-[12px] text-left">{item?.address}</td>
                      {/* <td className="p-[12px] text-left">
                        {item?.funeralDate || item?.funeralTime ? (
                          <div>
                            {item?.funeralDate !== null
                              ? moment(item?.funeralDate).format("YYYY-MM-DD")
                              : ""}{" "}
                            / {item?.funeralTime}
                          </div>
                        ) : null}
                      </td> */}
                      <td className="p-[12px] text-left">{item?.type}</td>
                      <td className="p-[12px] text-left">
                        {item?.paid === true
                          ? "Paid"
                          : item?.paid === false
                          ? "Not Paid"
                          : null}
                      </td>
                      <td className="p-[12px] text-left">
                        {item?.requestedDate || "Not Provided"}
                      </td>
                      <td className="">
                        <div className="flex items-center justify-center">
                          {showOptions === index ? (
                            <FaX
                              onClick={() => handleShowOptions(index)}
                              className="cursor-pointer"
                            />
                          ) : (
                            <FaBars
                              onClick={() => handleShowOptions(index)}
                              className="cursor-pointer"
                            />
                          )}
                        </div>
                        {showOptions === index ? (
                          <div className="flex w-[20%] bg-[#f5f5f5] z-[5] rounded-[12px] shadow-lg flex-col absolute right-[0px] top-[50px] items-center gap-[7px]">
                            <button
                              onClick={() =>
                                navigate(`/editor/order-detail/${item?.id}`)
                              }
                              className="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-sm leading-none text-gray-600 py-3 px-5  focus:outline-none w-full border-b border-gray-200 "
                            >
                              Preview
                            </button>

                            <button
                              onClick={() =>
                                navigate(`/editor/delivery/${item?.id}`)
                              }
                              className="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-sm leading-none text-gray-600 py-3 px-5  focus:outline-none w-full border-b border-gray-200 "
                            >
                              View Status
                            </button>
                            <button
                              onClick={() => {
                                handleViewFiles(item);
                              }}
                              className="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-[12px] font-medium leading-none text-gray-600 py-3 px-5  focus:outline-none w-full border-b border-gray-200 "
                            >
                              View Files
                            </button>
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Layout>
      ) : (
        <TableSkeleton />
      )}
    </div>
  );
};

export default EditorOrderList;
