import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import { FaEye, FaPlus, FaTrash, FaX } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import Img from "../../../assets/customer/new-order-page.png";
import { useDispatch } from "react-redux";
import { setAdminSidebar } from "../../../redux/reducer/sidebarSlice";
import axios from "axios";
import TableSkeleton from "../layout/tableSkeleton";
import ProfilePageSkeleton from "../layout/profilePageSkeleton";
const EditorUserLists = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAdminSidebar("user-info"));
  }, []);
  const token = localStorage.getItem("editorToken");
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState("");
  const fetchAllCustomers = () => {
    axios({
      method: "GET",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/editor/get-all-customers",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setData(res?.data?.Data?.rows);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    fetchAllCustomers();
  }, []);

  const [clientInformation, setClientInformation] = useState({
    businessName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    country: "",
    password: "",
  });

  const [languageValues, setLanguageValues] = useState("");
  const handleLanguageValues = (e) => {
    setLanguageValues(e.target.value);
  };
  const [languageData, setLanguageData] = useState([]);
  const handleAddLanguage = () => {
    const trimmedLanguage = languageValues?.trim();
    if (trimmedLanguage) {
      const data = [...languageData, trimmedLanguage];
      setLanguageData(data);
      setLanguageValues("");
    }
  };
  const handleDeleteLanguage = (index) => {
    const deletedItem = languageData?.filter((_, item) => item !== index);
    setLanguageData(deletedItem);
  };
  const handleClientInformation = (e) => {
    const { name, value } = e.target;
    setClientInformation({ ...clientInformation, [name]: value });
  };

  const [isUserDetailsFetched, setIsUserDetailsFetched] = useState(false);

  const getUserDetailsById = (id) => {
    axios({
      method: "GET",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/editor/get-customer-by-id/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setIsUserDetailsFetched(true);

        let data = res?.data?.Data;
        setClientInformation({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data?.email,
          country: data?.country,
          address: data?.address,
          phone: data?.phone,
          businessName: data?.bussinessName,
        });
        setLanguageData(data?.language || []);
      })
      .catch((err) => {});
  };

  const [isViewBtnPressed, setIsViewBtnPressed] = useState(false);

  return (
    <div>
      {data?.length !== 0 ? (
        <Layout>
          <div className="w-full flex flex-col text-[14px] items-center gap-[20px] p-[20px] ">
            <div className="w-full justify-center flex flex-col gap-[7px]   items-center">
              <h1 className="font-extrabold text-[36px] leading-[1.3em] text-center">
                User List*
              </h1>
              {/* <p className="text-[18px] text-[#555555]">
                Would you like to be contacted when the order is complete
              </p> */}
            </div>
            <div className="w-full overflow-x-auto hide-scrollbar  bg-white   h-[400px]   ">
              <table className="w-full">
                <thead>
                  <tr className="border text-[12px] border-gray-200 rounded-t-[20px] bg-[#fcfdfd]">
                    <th className="sm:p-[12px] px-[30px] text-center py-[12px]  sm:text-left">
                      USER NAME
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                      BUSINESS NAME
                    </th>

                    <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                      EMAIL
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                      PHONE
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full border border-gray-200  ">
                  {data.map((item, index) => (
                    <tr
                      key={index}
                      className={`
                  ${
                    index % 2 === 1
                      ? "bg-[#fcfdfd]  text-[12px]"
                      : "bg-white text-[12px]"
                  } relative
                   `}
                    >
                      <td className="p-[12px] text-left">
                        {item?.firstName} {item?.lastName}
                      </td>
                      <td className="p-[12px] text-left">
                        {item?.bussinessName}
                      </td>
                      <td className="p-[12px] text-left">{item?.email}</td>
                      <td className="p-[12px] text-left">{item?.phone}</td>
                      <td className="p-[12px] text-left flex  gap-[7px] items-center">
                        <FaEye
                          onClick={() => {
                            getUserDetailsById(item?.id);
                            setIsViewBtnPressed(true);
                          }}
                          className="cursor-pointer"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <img loading="lazy" src={Img} alt="img" className="" />
          </div>
          {isViewBtnPressed === true ? (
            <div className="w-full h-full  backdrop-filter backdrop-blur-sm bg-white/30 justify-center  absolute left-0 top-0 flex flex-col items-center gap-[20px] p-[20px] ">
              <div className="w-full sm:w-[80%] h-[90%] overflow-y-scroll rounded-[20px] hide-scrollbar bg-white shadow-xl p-[30px] flex flex-col gap-[20px]">
                <div className="w-full flex justify-end gap-[20px] items-center">
                  <button
                    onClick={() => {
                      setIsViewBtnPressed(false);
                      setClientInformation({
                        businessName: "",
                        firstName: "",
                        lastName: "",
                        email: "",
                        phone: "",
                        address: "",
                        country: "",
                      });
                      setLanguageValues("");
                    }}
                  >
                    <FaX />
                  </button>
                </div>
                <div className="w-full justify-center flex flex-col gap-[7px]   items-center">
                  <h1 className="font-extrabold text-[36px] leading-[1.3em] text-center">
                    View User
                  </h1>
                  {/* <p className="text-[18px] text-[#555555]">
                    Would you like to be contacted when the order is complete
                  </p> */}
                </div>
                {isUserDetailsFetched === true ? (
                  <div className="w-full flex flex-col items-center gap-[20px]">
                    <div className="w-full text-[14px] flex justify-start flex-wrap items-center gap-[20px]">
                      <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                        <label htmlFor="businessName" className="font-semibold">
                          Business Name*
                        </label>
                        <input
                          type="text"
                          name="businessName"
                          id="businessName"
                          onChange={handleClientInformation}
                          value={clientInformation.businessName}
                          autoComplete="false"
                          className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                          onKeyPress={(e) => {
                            if (!/[a-z\s]/i.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          readOnly
                        />
                      </div>
                      <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                        <label htmlFor="firstName" className="font-semibold">
                          First Name*
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          onChange={handleClientInformation}
                          value={clientInformation.firstName}
                          autoComplete="false"
                          className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                          onKeyPress={(e) => {
                            if (!/[a-z\s]/i.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          readOnly
                        />
                      </div>
                      <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                        <label htmlFor="lastName" className="font-semibold">
                          Last Name*
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          onChange={handleClientInformation}
                          value={clientInformation.lastName}
                          autoComplete="false"
                          className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                          onKeyPress={(e) => {
                            if (!/[a-z\s]/i.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          readOnly
                        />
                      </div>
                      <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                        <label htmlFor="email" className="font-semibold">
                          Email*
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          onChange={handleClientInformation}
                          value={clientInformation.email}
                          autoComplete="false"
                          className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                          readOnly
                        />
                      </div>

                      <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                        <label htmlFor="phone" className="font-semibold">
                          Phone Number*
                        </label>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          onChange={handleClientInformation}
                          value={clientInformation.phone}
                          autoComplete="false"
                          className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                          maxLength="10"
                          onKeyPress={(e) => {
                            if (!/^\d$/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          readOnly
                        />
                      </div>

                      <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                        <label htmlFor="address" className="font-semibold">
                          Address*
                        </label>
                        <input
                          type="text"
                          name="address"
                          id="address"
                          onChange={handleClientInformation}
                          value={clientInformation.address}
                          autoComplete="false"
                          className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                          onKeyPress={(e) => {
                            if (!/[a-z\s]/i.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          readOnly
                        />
                      </div>

                      <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
                        <label htmlFor="country" className="font-semibold">
                          Country*
                        </label>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          onChange={handleClientInformation}
                          value={clientInformation.country}
                          autoComplete="false"
                          className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                          onKeyPress={(e) => {
                            if (!/[a-z\s]/i.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="w-full flex flex-col gap-[7px]">
                      <h3 className="font-semibold">Languages*</h3>
                      <div className="w-full flex flex-wrap items-center justify-center gap-[15px]">
                        {languageData?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="p-[10px] bg-[#f5f5f4] text-black border border-black rounded-[7px] flex items-center gap-[7px]"
                            >
                              <p>{item}</p>
                              <FaX
                                onClick={() => handleDeleteLanguage(index)}
                                className="cursor-pointer"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <ProfilePageSkeleton />
                )}
              </div>
            </div>
          ) : null}
        </Layout>
      ) : (
        <TableSkeleton />
      )}
    </div>
  );
};

export default EditorUserLists;
