import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPath,
  setFolderUrl,
} from "../../../redux/reducer/editorFolderSystemSlice";
import { FaDownload, FaFolder, FaImage, FaVideo, FaX } from "react-icons/fa6";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Spinner from "../../../utils/spinner";
import { showToastSuccess } from "../../../utils/toastify";
import { toast, ToastContainer } from "react-toastify";

const EditorFileExplorer = () => {
  const token = localStorage.getItem("editorToken");
  const folderUrl = useSelector(
    (state) => state.editorFolderSystemSlice.folderUrl
  );
  const currentPath = useSelector(
    (state) => state.editorFolderSystemSlice.currentPath
  );
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [items, setItems] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const dispatch = useDispatch();
  const [imgData, setImgData] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const handleFolderDoubleClick = (folderName) => {
    const length = folderName?.split("/").length - 2;
    const splitFolderName = folderName?.split("/")[length];

    const newPath = [...currentPath, splitFolderName];
    dispatch(setCurrentPath(newPath));
    setCurrentFolder(folderName);
    handleFetchFolder(folderName);
    handleGetFiles(folderName);
  };

  const handleBackClick = () => {
    const newPath = currentPath.slice(0, -1);
    dispatch(setCurrentPath(newPath));
    setCurrentFolder(newPath[newPath.length - 1] || null);

    setImgData([]);
    handleBack(newPath);
  };
  const handleBack = (path) => {
    setIsDataFetched(false);
    axios({
      method: "POST",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/editor/get-folder`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        folderPath: path.join("/"),
      },
    })
      .then((res) => {
        setIsDataFetched(true);

        setItems(res?.data?.Data || []);
      })
      .catch((err) => {
        setIsDataFetched(false);
      });
  };

  const handleFetchFolder = (path) => {
    setIsDataFetched(false);
    const split = path?.split("/");

    const filterData = split?.filter((item) => {
      return item !== "";
    });
    axios({
      method: "POST",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/editor/get-folder`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        folderPath: filterData?.join("/") || folderUrl || "",
      },
    })
      .then((res) => {
        if (res.data.folders) {
          handleGetFiles(filterData?.join("/") || folderUrl || "");
        }
        setIsDataFetched(true);
        setItems(res?.data?.Data || []);
      })
      .catch((err) => {
        setIsDataFetched(false);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("editor-folder-path")) {
      dispatch(
        setCurrentPath(JSON.parse(localStorage.getItem("editor-folder-path")))
      );
      const jointPath = JSON.parse(
        localStorage.getItem("editor-folder-path")
      )?.join("/");
      handleFetchFolder(jointPath);
    }
  }, []);

  const handleGetFiles = (path) => {
    setIsDataFetched(false);

    const splitPath = path.split("/");
    if (!splitPath.includes("assets")) {
      return;
    } else {
      axios({
        method: "POST",
        url: "https://funeral-api.hitoritech.co.uk/api/v1/editor/get-files",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          folderPath: path,
        },
      })
        .then((res) => {
          setImgData(res?.data?.Data);
          setIsDataFetched(true);
          setItems([]);
        })
        .catch((err) => {
          setIsDataFetched(false);
        });
    }
  };

  const handleShowOptions = (index) => {
    setShowOptions(index === showOptions ? null : index);
  };

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = `https://project-p1.nyc3.digitaloceanspaces.com/${url}`;
    link.download = url.split("/").pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleLength = (item) => {
    const name = item?.split("/");
    const length = item?.split("/").length - 2;
    return name[length];
  };

  const [isZipFileFetched, setIsZipFileFetched] = useState(true);

  async function downloadFiles() {
    setIsZipFileFetched(false);
    const toastId = toast.loading("Hold On A Second!");
    try {
      const response = await axios(
        "https://funeral-api.hitoritech.co.uk/api/v1/editor/download-files",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data: { folderPath: currentPath?.join("/") },
        }
      );

      if (response) {
        const blob = await response.data;
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "downloaded-files.zip";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        toast.update(toastId, {
          render: "Fetched Successfully",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        setIsZipFileFetched(true);
      } else {
        const result = await response.data.json();
      }
    } catch (error) {
      console.error("Error downloading files:", error);
      toast.update(toastId, {
        render: "Error in fetching the data.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  }
  return (
    <div>
      <ToastContainer />
      {isDataFetched === true ? (
        <Layout>
          <div className="w-full flex flex-col gap-[20px] p-[30px] xl:p-[50px]">
            <div className="w-full flex items-center gap-[20px] justify-center">
              {currentPath.length > 0 && (
                <button
                  onClick={() => {
                    handleBackClick();
                  }}
                  className="p-[10px] bg-gray-400 rounded"
                >
                  Back
                </button>
              )}
              <div className="text-lg font-semibold">
                {currentPath.length > 0 ? (
                  currentPath?.join(" > ")
                ) : (
                  <span>Root</span>
                )}
              </div>
              <button
                onClick={() => downloadFiles()}
                disabled={isZipFileFetched === false}
                className="px-[20px] py-[12px] bg-green-400 text-white rounded-[7px]"
              >
                Download Current Folder
              </button>
            </div>
            <div className="w-full flex flex-wrap items-center gap-[30px] xl:gap-[50px] justify-start">
              {items?.map((item, index) => {
                const split = item?.split("/").length - 1;
                const items = item.split("/")[split];
                return (
                  <div
                    key={index}
                    className="w-full sm:w-[45%] xl:w-[30%] font-medium cursor-pointer flex items-center gap-[15px] p-[30px] rounded-[7px] bg-[#e8e8e8]"
                    onDoubleClick={() => {
                      handleFolderDoubleClick(item);
                    }}
                  >
                    <FaFolder className="text-[20px]" />
                    {handleLength(item)}
                  </div>
                );
              })}
            </div>
            <div className="w-full flex flex-wrap items-center gap-[30px] xl:gap-[50px] justify-center">
              {imgData?.map((item, index) => (
                <div
                  key={index}
                  className="flex rounded-[12px] relative bg-[#f5f5f5] hover:bg-[#e8e8e8] flex-col sm:w-[45%] xl:w-[30%]"
                >
                  <div className="p-[15px] text-[14px] justify-between flex items-center gap-[12px]">
                    <div className="flex items-center gap-[12px]">
                      {item?.split(".")[1] !== "mp4" ? (
                        <FaImage className="text-[18px] text-green-400" />
                      ) : (
                        <FaVideo className="text-[18px] text-green-400" />
                      )}
                      {item?.split("/")[4]?.slice(0, 20) + "...."}
                    </div>
                    {showOptions === index ? (
                      <FaX
                        onClick={() => handleShowOptions(index)}
                        className="cursor-pointer"
                      />
                    ) : (
                      <HiOutlineDotsVertical
                        onClick={() => {
                          handleShowOptions(index);
                        }}
                        className="cursor-pointer text-[16px]"
                      />
                    )}
                  </div>
                  <div className="px-[10px] py-[7px] w-full">
                    {item?.split(".")[1] !== "mp4" ? (
                      <Zoom>
                        <img
                          loading="lazy"
                          className="w-full object-contain  cursor-pointer rounded-[7px] h-[200px] bg-[#b2b7bb]"
                          src={`https://project-p1.nyc3.digitaloceanspaces.com/${item}`}
                          alt="Zoomed"
                        />
                      </Zoom>
                    ) : (
                      <video
                        src={`https://project-p1.nyc3.digitaloceanspaces.com/${item}`}
                        className="w-full object-contain  cursor-pointer rounded-[7px] h-[200px] bg-[#b2b7bb]"
                        controls
                      />
                    )}
                  </div>
                  {showOptions === index ? (
                    <div className="absolute top-[40px] right-[-30px] w-[50%] bg-gray-200 text-gray-500 shadow-lg rounded-[12px] flex flex-col">
                      <div
                        className="p-[10px] cursor-pointer w-full flex items-center gap-[10px] justify-center"
                        onClick={() => handleDownload(item)}
                      >
                        <FaDownload />
                        Download
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </Layout>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default EditorFileExplorer;
