import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  admin: null,
};

const adminInfoSlice = createSlice({
  name: "adminInfoSlice",
  initialState,
  reducers: {
    setAdminInfo: (state, action) => {
      state.admin = action.payload;
    },
  },
});

export const { setAdminInfo } = adminInfoSlice.actions;
export default adminInfoSlice.reducer;
