import React, { useEffect, useState } from "react";
import Img from "../../../assets/customer/new-order-page.png";
import { FaArrowLeft } from "react-icons/fa6";
import Layout from "../layout/layout";
import { useDispatch } from "react-redux";
import { setAdminSidebar } from "../../../redux/reducer/sidebarSlice";
import { showToastError, showToastSuccess } from "../../../utils/toastify";
import axios from "axios";
const AdminSettings = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAdminSidebar("settings"));
  }, []);
  const [clientInformation, setClientInformation] = useState({
    businessName: "",
    contactName: "",
    email: "",
    phone: "",
    address: "",
    country: "",
  });
  const handleClientInformation = (e) => {
    const { name, value } = e.target;
    setClientInformation({ ...clientInformation, [name]: value });
  };

  const [passwordDetails, setPasswordDetails] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const token = localStorage.getItem("adminToken");

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordDetails({ ...passwordDetails, [name]: value });
  };
  const handleSubmitPassword = () => {
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/change-password",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        oldPassword: passwordDetails?.currentPassword,
        newPassword: passwordDetails?.newPassword,
      },
    })
      .then((res) => {
        showToastSuccess(res?.data?.message);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };
  return (
    <Layout>
      <div className="w-full flex flex-col items-center gap-[20px] p-[20px] ">
        {/* <div className="w-full justify-center flex flex-col gap-[7px]   items-center">
          <h1 className="font-extrabold text-[36px] leading-[1.3em] text-center">
            Client Information*
          </h1>
          <p className="text-[18px] text-[#555555]">
            Would you like to be contacted when the order is complete
          </p>
        </div> */}
        {/* <div className="w-full flex flex-col items-center gap-[20px]">
          <div className="w-full text-[14px] flex justify-start flex-wrap items-center gap-[20px]">
            <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
              <label htmlFor="businessName" className="font-semibold">
                Business Name*
              </label>
              <input
                type="text"
                name="businessName"
                id="businessName"
                onChange={handleClientInformation}
                value={clientInformation.businessName}
                autoComplete="false"
                className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                onKeyPress={(e) => {
                  if (!/[a-z\s]/i.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
              <label htmlFor="contactName" className="font-semibold">
                Contact Name*
              </label>
              <input
                type="text"
                name="contactName"
                id="contactName"
                onChange={handleClientInformation}
                value={clientInformation.contactName}
                autoComplete="false"
                className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                onKeyPress={(e) => {
                  if (!/[a-z\s]/i.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
              <label htmlFor="email" className="font-semibold">
                Email*
              </label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={handleClientInformation}
                value={clientInformation.email}
                autoComplete="false"
                className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                disabled
              />
            </div>

            <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
              <label htmlFor="phone" className="font-semibold">
                Phone Number*
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                onChange={handleClientInformation}
                value={clientInformation.phone}
                autoComplete="false"
                className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                maxLength="10"
                onKeyPress={(e) => {
                  if (!/^\d$/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>

            <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
              <label htmlFor="address" className="font-semibold">
                Address*
              </label>
              <input
                type="text"
                name="address"
                id="address"
                onChange={handleClientInformation}
                value={clientInformation.address}
                autoComplete="false"
                className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                onKeyPress={(e) => {
                  if (!/[a-z\s]/i.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>

            <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
              <label htmlFor="country" className="font-semibold">
                Country*
              </label>
              <input
                type="text"
                name="country"
                id="country"
                onChange={handleClientInformation}
                value={clientInformation.country}
                autoComplete="false"
                className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
                onKeyPress={(e) => {
                  if (!/[a-z\s]/i.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </div>
          <button className="bg-[#22d87c] text-white px-[20px] py-[10px] rounded-[7px]">
            SAVE
          </button>
        </div> */}

        <div className="w-full flex flex-col items-center gap-[20px]">
          <div className="w-full flex flex-col gap-[4px]">
            <h3 className="font-bold w-full">Password & Security*</h3>
            {/* <p>Would You like to be contacted when the order is complete:</p> */}
          </div>
          <div className="w-full text-[14px] flex justify-start flex-wrap items-center gap-[20px]">
            <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
              <label htmlFor="currentPassword" className="font-semibold">
                Current Password*
              </label>
              <input
                type="password"
                name="currentPassword"
                id="currentPassword"
                onChange={handlePasswordChange}
                value={passwordDetails?.currentPassword}
                autoComplete="false"
                className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
              />
            </div>
            <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
              <label htmlFor="newPassword" className="font-semibold">
                New Password*
              </label>
              <input
                type="password"
                name="newPassword"
                id="newPassword"
                onChange={handlePasswordChange}
                value={passwordDetails?.newPassword}
                autoComplete="false"
                className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
              />
            </div>
            <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
              <label htmlFor="confirmPassword" className="font-semibold">
                Confirm Password*
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                onChange={handlePasswordChange}
                value={passwordDetails?.confirmPassword}
                autoComplete="false"
                className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
              />
            </div>
          </div>
        </div>
        <button
          onClick={handleSubmitPassword}
          className="bg-[#22d87c] text-white px-[20px] py-[10px] rounded-[7px]"
        >
          SAVE
        </button>
        <img loading="lazy" src={Img} alt="img" className="" />
      </div>
    </Layout>
  );
};

export default AdminSettings;
