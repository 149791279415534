import React from "react";

const Spinner = () => {
  return (
    <div className="absolute flex flex-col items-center gap-[7px] justify-center z-[99] w-full h-full top-0 left-0 bg-black bg-opacity-[.7]">
      <div className="flex flex-col items-center w-full gap-[7px]">
        <h1 className="text-[30px] sm:text-[36px] font-bold text-white">
          Please Wait ..!
        </h1>
        <div className="animate-spin border-t-4 border-white border-solid rounded-full h-12 w-12"></div>
      </div>
    </div>
  );
};

export default Spinner;
