import "./App.css";
import CustomerLogin from "./pages/customer-dashboard/dashboard/login";
import { Route, Routes, useNavigate } from "react-router-dom";
import CustomerRegister from "./pages/customer-dashboard/dashboard/register";
import NewOrder from "./pages/customer-dashboard/dashboard/new-order";
import OrderStatus from "./pages/customer-dashboard/dashboard/order-status";
import Settings from "./pages/customer-dashboard/dashboard/settings";
import Payment from "./pages/customer-dashboard/dashboard/payment";
import CropImage from "./pages/customer-dashboard/dashboard/cropImage";
import AdminLogin from "./pages/admin-dashboard/dashboard/login";
import AdminNewOrder from "./pages/admin-dashboard/dashboard/new-order";
import AdminOrderStatus from "./pages/admin-dashboard/dashboard/order-status";
import AdminSettings from "./pages/admin-dashboard/dashboard/settings";
import AdminVideoUpload from "./pages/admin-dashboard/dashboard/videoUpload";
import AdminDashboard from "./pages/admin-dashboard/dashboard/dashboard";
import AdminUserLists from "./pages/admin-dashboard/dashboard/user-info";
import AdminCustomerList from "./pages/admin-dashboard/dashboard/editor-info";
import EditorLogin from "./pages/editor-dashboard/dashboard/login";
import EditorSettings from "./pages/editor-dashboard/dashboard/settings";
import EditorOrderStatus from "./pages/editor-dashboard/dashboard/order-status";
import AdminOrderDetail from "./pages/admin-dashboard/dashboard/order-detail";
import EditorOrderDetail from "./pages/editor-dashboard/dashboard/order-detail";
import AdminDeliveryPage from "./pages/admin-dashboard/dashboard/delivery-page";
import CustomerDeliveryPage from "./pages/customer-dashboard/dashboard/delivery-page";
import AdminRegister from "./pages/admin-dashboard/dashboard/register";
import {
  AdminPrivateRoute,
  CustomerPrivateRoute,
  EditorPrivateRoute,
} from "./utils/privateRoutes";
import CustomerForgotPassword from "./pages/customer-dashboard/dashboard/forgot-password";
import EditorForgotPassword from "./pages/editor-dashboard/dashboard/forgot-password";
import CustomerEmailVerification from "./pages/customer-dashboard/dashboard/verify-email";
import EditorEmailVerification from "./pages/editor-dashboard/dashboard/verify-email";
import CustomerResetPassword from "./pages/customer-dashboard/dashboard/reset-password";
import EditorResetPassword from "./pages/editor-dashboard/dashboard/reset-password";
import CustomerForgotEmailVerification from "./pages/customer-dashboard/dashboard/verify-forgot-email";
import EditorForgotEmailVerification from "./pages/editor-dashboard/dashboard/verify-forgot-email";
import OrderList from "./pages/customer-dashboard/dashboard/order-list";
import AdminOrderList from "./pages/admin-dashboard/dashboard/order-list";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setCustomerInfo } from "./redux/reducer/customerInfoSlice";
import { useEffect } from "react";
import PaymentSuccess from "./pages/customer-dashboard/dashboard/paymentSuccess";
import PaymentFailed from "./pages/customer-dashboard/dashboard/paymentFailed";
import EditorUserLists from "./pages/editor-dashboard/dashboard/user-info";
import EditorOrderList from "./pages/editor-dashboard/dashboard/order-list";
import CustomerOrderDetail from "./pages/customer-dashboard/dashboard/order-detail";
import EditorDeliveryPage from "./pages/editor-dashboard/dashboard/delivery-page";
import { setEditorInfo } from "./redux/reducer/editorInfoSlice";
import { setAdminDetails } from "./redux/reducer/adminUserDetails";
import FileExplorer from "./pages/admin-dashboard/dashboard/file-explorer";
import EditorFileExplorer from "./pages/editor-dashboard/dashboard/file-explorer";
import EditorRegister from "./pages/editor-dashboard/dashboard/register";
import { showToastError } from "./utils/toastify";
import AdminList from "./pages/admin-dashboard/dashboard/admin-list";
function App() {
  const dispatch = useDispatch();
  const customerToken = localStorage.getItem("customerToken");
  const adminToken = localStorage.getItem("adminToken");
  const editorToken = localStorage.getItem("editorToken");
  const navigate = useNavigate();
  const getCustomerInfo = async () => {
    await axios({
      method: "GET",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/get-customer-token",
      headers: {
        Authorization: "Bearer " + customerToken,
      },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 403) {
          localStorage.clear();
        }
        dispatch(setCustomerInfo(res?.data.Data));
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 403) {
          localStorage.clear();
          navigate("/customer/login");
        }
      });
  };

  const getAdminInfo = async () => {
    await axios({
      method: "GET",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/get-admin-token",
      headers: {
        Authorization: "Bearer " + adminToken,
      },
    })
      .then((res) => {
        if (res.status === 403) {
          localStorage.clear();
        }
        dispatch(setAdminDetails(res?.data.Data));
      })
      .catch((err) => {
        if (err.response.status === 403) {
          localStorage.clear();
          navigate("/admin/login");
        }
      });
  };

  const getEditorInfo = async () => {
    await axios({
      method: "GET",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/editor/get-editor-by-token",
      headers: {
        Authorization: "Bearer " + editorToken,
      },
    })
      .then((res) => {
        dispatch(setEditorInfo(res?.data.Data));
      })
      .catch((err) => {
        if (err.response.status === 403) {
          localStorage.clear();
          navigate("/admin/login");
        }
      });
  };

  useEffect(() => {
    if (customerToken) {
      getCustomerInfo();
    }
    if (adminToken) {
      getAdminInfo();
    }
    if (editorToken) {
      getEditorInfo();
    }
  }, [dispatch, customerToken, adminToken, editorToken]);

  return (
    <Routes>
      <Route path="/order-detail/:orderId" element={<AdminOrderDetail />} />

      <Route path="/" element={<CustomerLogin />} />
      <Route path="customer">
        <Route path="login" element={<CustomerLogin />} />
        <Route path="register" element={<CustomerRegister />} />
        <Route path="forgot-password" element={<CustomerForgotPassword />} />
        <Route path="order-list" element={<OrderList />} />
        <Route
          path="verify-email/:email/:token"
          element={<CustomerEmailVerification />}
        />
        <Route
          path="verify-forgot-email/:email/:token"
          element={<CustomerForgotEmailVerification />}
        />
        <Route
          path="reset-password/:token"
          element={<CustomerResetPassword />}
        />
        <Route path="order-detail/:orderId" element={<CustomerOrderDetail />} />

        <Route
          path="new-order/:orderId"
          element={
            <CustomerPrivateRoute>
              <NewOrder />
            </CustomerPrivateRoute>
          }
        />
        <Route
          path=""
          element={
            <CustomerPrivateRoute>
              <OrderList />
            </CustomerPrivateRoute>
          }
        />
        <Route
          path="order-status/:orderStatus"
          element={
            <CustomerPrivateRoute>
              <OrderStatus />
            </CustomerPrivateRoute>
          }
        />
        <Route
          path="settings"
          element={
            <CustomerPrivateRoute>
              <Settings />
            </CustomerPrivateRoute>
          }
        />
        <Route
          path="payment"
          element={
            <CustomerPrivateRoute>
              <Payment />
            </CustomerPrivateRoute>
          }
        />
        <Route
          path="crop-image"
          element={
            <CustomerPrivateRoute>
              <CropImage />
            </CustomerPrivateRoute>
          }
        />
        <Route
          path="payment-success"
          element={
            // <CustomerPrivateRoute>
            <PaymentSuccess />
            // </CustomerPrivateRoute>
          }
        />
        <Route
          path="payment-failed"
          element={
            // <CustomerPrivateRoute>
            <PaymentFailed />
            // </CustomerPrivateRoute>
          }
        />
        <Route
          path="delivery/:id"
          element={
            <CustomerPrivateRoute>
              <CustomerDeliveryPage />
            </CustomerPrivateRoute>
          }
        />
      </Route>
      <Route path="admin">
        <Route path="login" element={<AdminLogin />} />
        <Route path="register" element={<AdminRegister />} />

        <Route
          path="new-order/:orderId"
          element={
            <AdminPrivateRoute>
              <AdminNewOrder />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="order-status"
          element={
            <AdminPrivateRoute>
              <AdminOrderStatus />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="settings"
          element={
            <AdminPrivateRoute>
              <AdminSettings />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="video-upload"
          element={
            <AdminPrivateRoute>
              <AdminVideoUpload />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="dashboard"
          element={
            <AdminPrivateRoute>
              <AdminDashboard />
            </AdminPrivateRoute>
          }
        />
        <Route
          path=""
          element={
            <AdminPrivateRoute>
              <AdminDashboard />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="order-list"
          element={
            <AdminPrivateRoute>
              <AdminOrderList />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="user-list"
          element={
            <AdminPrivateRoute>
              <AdminUserLists />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="editor-list"
          element={
            <AdminPrivateRoute>
              <AdminCustomerList />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="admin-list"
          element={
            <AdminPrivateRoute>
              <AdminList />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="delivery/:id"
          element={
            <AdminPrivateRoute>
              <AdminDeliveryPage />
            </AdminPrivateRoute>
          }
        />

        <Route
          path="file-explorer"
          element={
            <AdminPrivateRoute>
              <FileExplorer />
            </AdminPrivateRoute>
          }
        />

        <Route path="order-detail/:orderId" element={<AdminOrderDetail />} />
      </Route>
      <Route path="editor">
        <Route path="login" element={<EditorLogin />} />
        <Route path="register" element={<EditorRegister />} />
        <Route path="forgot-password" element={<EditorForgotPassword />} />
        <Route
          path="verify-email/:email/:token"
          element={<EditorEmailVerification />}
        />
        <Route
          path="verify-forgot-email/:email/:token"
          element={<EditorForgotEmailVerification />}
        />
        <Route path="reset-password/:token" element={<EditorResetPassword />} />
        <Route
          path="settings"
          element={
            <EditorPrivateRoute>
              <EditorSettings />
            </EditorPrivateRoute>
          }
        />
        <Route
          path="file-explorer"
          element={
            <EditorPrivateRoute>
              <EditorFileExplorer />
            </EditorPrivateRoute>
          }
        />
        <Route
          path="order-status"
          element={
            <EditorPrivateRoute>
              <EditorOrderStatus />
            </EditorPrivateRoute>
          }
        />
        <Route
          path=""
          element={
            <EditorPrivateRoute>
              <EditorOrderList />
            </EditorPrivateRoute>
          }
        />
        <Route
          path="user-list"
          element={
            <EditorPrivateRoute>
              <EditorUserLists />
            </EditorPrivateRoute>
          }
        />

        <Route
          path="order-list"
          element={
            <EditorPrivateRoute>
              <EditorOrderList />
            </EditorPrivateRoute>
          }
        />
        <Route
          path="delivery/:id"
          element={
            <EditorPrivateRoute>
              <EditorDeliveryPage />
            </EditorPrivateRoute>
          }
        />
        <Route path="order-detail/:orderId" element={<EditorOrderDetail />} />
      </Route>
    </Routes>
  );
}

export default App;
