import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminDetails: null,
};

const adminUserDetails = createSlice({
  name: "adminUserDetails",
  initialState,
  reducers: {
    setAdminDetails: (state, action) => {
      state.adminDetails = action.payload;
    },
  },
});

export const { setAdminDetails } = adminUserDetails.actions;
export default adminUserDetails.reducer;
